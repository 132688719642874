import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from '@angular/common/http';

import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { IS_TOKEN_NEEDED } from '@core/auth/interceptors';
import { AuthService } from '@core/auth/services';


@Injectable()
export class ApiService {

    private static readonly TYPE_JSON_RPC: string = 'application/json-rpc';

    private apiUrl: string;

    private _tokenizedContext: HttpContext = new HttpContext().set(IS_TOKEN_NEEDED, true);

    constructor(public http: HttpClient, protected authService: AuthService) {
        this.apiUrl = environment.apiUrl;
    }

    public post(method: string, params: any[] | object): Observable<any> {

        const rpcRequest = { method, jsonrpc: '2.0', params };

        return this.http
            .post(`${this.apiUrl}`, rpcRequest, { headers: this.getHeaders(), observe: 'response', context: this._tokenizedContext })
            .pipe(
                switchMap((response: HttpResponse<any>) => {
                    const responseRpc = typeof response.body === 'object' ? { ...response.body } : response.body;

                    if (!responseRpc.error) {
                        return of(responseRpc.result || null);
                    }

                    if (responseRpc.error['code'] === -32001) {
                        this.authService.startAuthentication();
                    }

                    return observableThrowError({ ...responseRpc.error, concurrent: responseRpc.error.code === -32005 });
                }),
            );
    }

    public postBatch(requestParams) {
        let requestId = 1;

        requestParams.forEach(element => {
            element.id = requestId++;
            element.jsonrpc = '2.0';
        });

        return this.http
            .post(`${this.apiUrl}`, requestParams, { headers: this.getHeaders(), observe: 'response', context: this._tokenizedContext })
            .pipe(map(response => this.extractBatchData(response)));
    }

    private getHeaders(): HttpHeaders {
        return new HttpHeaders().set('Content-Type', ApiService.TYPE_JSON_RPC);
    }

    private extractBatchData(response: any): any {
        if (response && response.error && !response.body) {
            throw response.error;
        }

        const results = [];
        response.body.forEach(subResponseRpc => {
            if (subResponseRpc.error || !subResponseRpc.result) {
                throw observableThrowError(response);
            } else if (subResponseRpc.result) {
                results.push(subResponseRpc.result);
            }
        });
        return results;
    }

    public uploadAvatar(file: File) {
        const formData = new FormData();
        const user = this.authService.getCurrentUser();
        formData.append('customerId', user.organization.id);
        formData.append('userId', user.id);
        formData.append('type', 'avatar');
        formData.append('file', file, file.name);

        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.getAuthToken());

        return this.http.post(environment.uploadAvatarUrl, formData, { headers }).pipe(
            map((response: { id: string }) => response.id),
        );
    }
}
