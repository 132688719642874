import { Injectable } from '@angular/core';
import { Observer, Observable } from 'rxjs';

@Injectable()
export class WebsocketsService {
    private capturingWS: WebSocket;

    public connect(captureUrl: string): Observable<any> {
        this.capturingWS = new WebSocket(`${captureUrl}`);

        return new Observable((observer: Observer<MessageEvent>) => {
            this.capturingWS.onmessage = observer.next.bind(observer);
            this.capturingWS.onerror = observer.error.bind(observer);
            this.capturingWS.onclose = observer.complete.bind(observer);
            return this.capturingWS.close.bind(this.capturingWS);
        });
    }

    public close(): void {
        if (this.capturingWS) {
            this.capturingWS.close();
        }
    }
}
