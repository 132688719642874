import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ActionSourceType, ActionClauseType, MatchOperator } from '@domain/actions';


@Pipe({ name: 'sourceType' })
export class ActionSourceTypePipe implements PipeTransform {

    constructor(protected translate: TranslateService) {
    }

    public transform(value: ActionSourceType, lang: string): string {
        switch (value) {
            case ActionSourceType.person:
                return this.translate.instant('ACTION_SOURCE_TYPE.PERSON');
            case ActionSourceType.count:
                return this.translate.instant('ACTION_SOURCE_TYPE.COUNTER');
            case ActionSourceType.zone:
                return this.translate.instant('ACTION_SOURCE_TYPE.ZONE');
            case ActionSourceType.weather:
                return this.translate.instant('ACTION_SOURCE_TYPE.WEATHER');
            case ActionSourceType.occupancy:
                return this.translate.instant('ACTION_SOURCE_TYPE.OCCUPANCY');
            default:
                return null;
        }
    }
}

@Pipe({ name: 'clauseType' })
export class ActionClauseTypePipe implements PipeTransform {

    constructor(protected translate: TranslateService) {
    }

    public transform(value: ActionClauseType, lang: string): string {
        switch (value) {
            case ActionClauseType.age:
                return this.translate.instant('ACTION_CLAUSE_TYPE.AGE');
            case ActionClauseType.gender:
                return this.translate.instant('ACTION_CLAUSE_TYPE.GENDER');
            case ActionClauseType.in:
                return this.translate.instant('ACTION_CLAUSE_TYPE.IN');
            case ActionClauseType.out:
                return this.translate.instant('ACTION_CLAUSE_TYPE.OUT');
            case ActionClauseType.total:
                return this.translate.instant('ACTION_CLAUSE_TYPE.TOTAL');
            case ActionClauseType.delay:
                return this.translate.instant('ACTION_CLAUSE_TYPE.DELAY');
            case ActionClauseType.icon:
                return this.translate.instant('ACTION_CLAUSE_TYPE.ICON');
            case ActionClauseType.temp:
                return this.translate.instant('ACTION_CLAUSE_TYPE.TEMP');
            case ActionClauseType.male:
                return this.translate.instant('ACTION_CLAUSE_TYPE.MALE');
            case ActionClauseType.female:
                return this.translate.instant('ACTION_CLAUSE_TYPE.FEMALE');
            default:
                return null;
        }
    }
}


@Pipe({ name: 'matchOperator' })
export class MatchOperatorPipe implements PipeTransform {

    constructor(protected translate: TranslateService) {
    }

    public transform(value: MatchOperator | string, lang: string): string {
        switch (value) {
            case MatchOperator.eq:
                return this.translate.instant('MATCH_OPERATOR.EQ');
            case MatchOperator.every:
                return this.translate.instant('MATCH_OPERATOR.EVERY');
            case MatchOperator.from:
                return this.translate.instant('MATCH_OPERATOR.FROM');
            case MatchOperator.ge:
                return this.translate.instant('MATCH_OPERATOR.GE');
            case MatchOperator.gt:
                return this.translate.instant('MATCH_OPERATOR.GT');
            case MatchOperator.le:
                return this.translate.instant('MATCH_OPERATOR.LE');
            case MatchOperator.lt:
                return this.translate.instant('MATCH_OPERATOR.LT');
            case MatchOperator.to:
                return this.translate.instant('MATCH_OPERATOR.TO');
            case `${MatchOperator.from},${MatchOperator.to}`:
                return this.translate.instant('MATCH_OPERATOR.FROM_TO');
            default:
                return null;
        }
    }
}
