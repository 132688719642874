import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CampaignType, CampaignFilterOption, CampaignTargetType } from '@domain/campaigns';


@Pipe({ name: 'campaignType' })
export class CampaignTypePipe implements PipeTransform {

    constructor(protected translate: TranslateService) {
    }

    public transform(value: CampaignType, lang: string): string {
        switch (value) {
            case CampaignType.ds:
                return this.translate.instant('CAMPAIGN.DIGITAL_SIGNAGE.TITLE');
            case CampaignType.audio:
                return this.translate.instant('CAMPAIGN.AUDIO.TITLE');
            default:
                return this.translate.instant('CAMPAIGN.WIFI.TITLE');
        }
    }
}

@Pipe({ name: 'campaignTargetType' })
export class CampaignTargetTypePipe implements PipeTransform {

    constructor(protected translate: TranslateService) {
    }

    public transform(value: CampaignTargetType, lang: string): string {
        switch (value) {
            case CampaignTargetType.groups:
                return this.translate.instant('CAMPAIGN_TARGET_TYPE.GROUPS');
            case CampaignTargetType.locations:
                return this.translate.instant('CAMPAIGN_TARGET_TYPE.LOCATIONS');
            case CampaignTargetType.tags:
                return this.translate.instant('CAMPAIGN_TARGET_TYPE.TAGS');
            default:
                return null;
        }
    }
}
