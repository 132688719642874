import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

import { FileExtensionService, FileLoaderService } from '../../services';
import { ImageFormats } from '../../enums';

@Component({
    selector: 'image-upload-canvas',
    templateUrl: 'image-upload-canvas.component.html',
    styleUrls: ['image-upload-canvas.component.scss'],
})
export class ImageUploadCanvasComponent implements AfterViewInit {
    @ViewChild('canvas') canvasRef: ElementRef;

    private _zoom: number;
    @Input('zoom')
    set zoom(zoom: number) {
        this._zoom = zoom;
        if (this.ctx !== null && !this.noImage) {
            const newZoom = this._zoom - this.previousZoom;
            this.zoomCtx(newZoom);
            this.previousZoom = this._zoom;
        }
    }
    get zoom() {
        return this._zoom;
    }

    @Input() base64: string;

    @Input() path: string;

    @Input() width: number;

    @Input() height: number;

    @Output() decrease = new EventEmitter<boolean>();

    @Output() increase = new EventEmitter<boolean>();

    initialBase64: string;

    dragStart: any;

    noImage = true;

    private canvas: HTMLCanvasElement;

    private ctx: any = null;

    private source = new Image();

    private centerX: number;

    private centerY: number;

    private previousZoom: number;

    constructor(
        private cdr: ChangeDetectorRef,
        private fileService: FileLoaderService,
        private extensionService: FileExtensionService,
    ) {}

    ngAfterViewInit(): void {
        this.canvas = this.canvasRef.nativeElement;
        this.canvas.width = this.width * 2;
        this.canvas.height = this.height * 2;
        this.ctx = this.canvas.getContext('2d');
        this.centerX = this.width;
        this.centerY = this.height;
        this.trackTransforms(this.ctx);
        this.previousZoom = this.zoom;
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(evt: any): void {
        if (this.noImage) {
            return;
        }

        document.body.style.userSelect = document.body.style.webkitUserSelect = 'none';
        const x = evt.offsetX || evt.pageX - this.canvas.offsetLeft;
        const y = evt.offsetY || evt.pageY - this.canvas.offsetTop;
        this.dragStart = this.ctx.transformedPoint(x, y);
    }

    @HostListener('touchstart', ['$event'])
    onTouchStart(evt: any): void {
        if (this.noImage) {
            return;
        }

        document.body.style.userSelect = document.body.style.webkitUserSelect = 'none';
        const x = evt.touches[0].offsetX || evt.touches[0].pageX - this.canvas.offsetLeft;
        const y = evt.touches[0].offsetY || evt.touches[0].pageY - this.canvas.offsetTop;
        this.dragStart = this.ctx.transformedPoint(x, y);
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove(evt: any): void {
        if (this.noImage || !this.dragStart) {
            return;
        }

        const x = evt.offsetX || evt.pageX - this.canvas.offsetLeft;
        const y = evt.offsetY || evt.pageY - this.canvas.offsetTop;
        const pt = this.ctx.transformedPoint(x, y);

        this.ctx.translate(pt.x - this.dragStart.x, pt.y - this.dragStart.y);
        this.redraw();
    }

    @HostListener('touchmove', ['$event'])
    onTouchMove(evt: any): void {
        if (this.noImage || !this.dragStart) {
            return;
        }

        evt.preventDefault();
        const x = evt.touches[0].offsetX || evt.touches[0].pageX - this.canvas.offsetLeft;
        const y = evt.touches[0].offsetY || evt.touches[0].pageY - this.canvas.offsetTop;
        const pt = this.ctx.transformedPoint(x, y);

        this.ctx.translate(pt.x - this.dragStart.x, pt.y - this.dragStart.y);
        this.redraw();
    }

    @HostListener('mousewheel', ['$event'])
    onMouseWheel(evt: any): boolean {
        if (this.noImage) {
            return;
        }

        if (evt.deltaY < 0 && this._zoom < 50) {
            this.increase.emit();
        } else if (evt.deltaY > 0 && this._zoom > 0) {
            this.decrease.emit();
        }

        return evt.preventDefault() && false;
    }

    @HostListener('document:touchend', ['$event'])
    onTouchEnd(): void {
        if (this.noImage) {
            return;
        }

        document.body.style.userSelect = document.body.style.webkitUserSelect = 'auto';
        this.dragStart = null;
    }

    @HostListener('document:mouseup', ['$event'])
    onMouseUp(): void {
        if (this.noImage) {
            return;
        }

        document.body.style.userSelect = document.body.style.webkitUserSelect = 'auto';
        this.dragStart = null;
    }

    rotateLeft(): void {
        const pt = this.ctx.transformedPoint(this.centerX, this.centerY);
        this.ctx.translate(pt.x, pt.y);
        this.ctx.rotate(-1.5708);
        this.ctx.translate(-pt.x, -pt.y);
        this.redraw();
    }

    rotateRight(): void {
        const pt = this.ctx.transformedPoint(this.centerX, this.centerY);
        this.ctx.translate(pt.x, pt.y);
        this.ctx.rotate(1.5708);
        this.ctx.translate(-pt.x, -pt.y);
        this.redraw();
    }

    getCroppedImage(): string {
        if (this.canvas) {
            return this.canvas.toDataURL();
        }
    }

    imageChanged(): boolean {
        return this.getCroppedImage() !== this.initialBase64 || !this.path;
    }

    loadImage(): void {
        this.fileService.loadFile(this.path || this.base64).then(result => this.processLoadImage(result));
    }

    private processLoadImage(result: string): void {
        this.previousZoom = 0;
        this.cleanCanvas();

        this.source.onload = () => {
            this.redraw();

            this.noImage = false;
            this.initialBase64 = this.getCroppedImage();
            this.cdr.detectChanges();
        };

        this.source.onerror = () => {
            this.noImage = true;
        };

        this.source.setAttribute('crossOrigin', 'anonymous');
        this.source.setAttribute('src', result);
    }

    public cleanCanvas(): void {
        if (!this.ctx) {
            return;
        }

        const p1 = this.ctx.transformedPoint(0, 0);
        const p2 = this.ctx.transformedPoint(this.canvas.width, this.canvas.height);
        this.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);

        this.ctx.save();
        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    private redraw(): void {
        this.cleanCanvas();
        this.ctx.restore();

        const srcImgWidth = this.source.width;
        const srcImgHeight = this.source.height;
        let aspectRatio = 0;
        let cnsWidth = 0;
        let cnsHeight = 0;
        let imgX = 0;
        let imgY = 0;

        if (srcImgWidth > srcImgHeight) {
            cnsWidth = this.canvas.width;
            aspectRatio = srcImgWidth / srcImgHeight;
            cnsHeight = cnsWidth / aspectRatio;
            imgY = (this.canvas.height - cnsHeight) / 2;
        } else {
            cnsHeight = this.canvas.height;
            aspectRatio = srcImgHeight / srcImgWidth;
            cnsWidth = cnsHeight / aspectRatio;
            imgX = (this.canvas.width - cnsWidth) / 2;
        }

        const extention = this.extensionService.getExtensionByBase64(this.source.src);

        if (extention === ImageFormats.Svg) {
            this.ctx.drawImage(this.source, 0, 0, cnsWidth, cnsHeight);
        } else {
            this.ctx.drawImage(this.source, 0, 0, this.source.width, this.source.height, imgX, imgY, cnsWidth, cnsHeight);
        }
    }

    private zoomCtx(zoomValue: any): void {
        const scaleFactor = 1.1;
        const pt = this.ctx.transformedPoint(this.centerX, this.centerY);
        const factor = Math.pow(scaleFactor, zoomValue);
        this.ctx.translate(pt.x, pt.y);
        this.ctx.scale(factor, factor);
        this.ctx.translate(-pt.x, -pt.y);
        this.redraw();
    }

    private trackTransforms(ctx: any): void {
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        let xForm: any = svg.createSVGMatrix();

        ctx.getTransform = () => xForm;

        const savedTransforms = [];
        const save = ctx.save;

        ctx.save = () => {
            savedTransforms.push(xForm.translate(0, 0));
            return save.call(ctx);
        };

        const restore = ctx.restore;
        ctx.restore = () => {
            xForm = savedTransforms.pop();
            return restore.call(ctx);
        };

        const scale = ctx.scale;
        ctx.scale = (sx, sy) => {
            xForm = xForm.scaleNonUniform(sx, sy);
            return scale.call(ctx, sx, sy);
        };

        const rotate = ctx.rotate;
        ctx.rotate = radians => {
            xForm = xForm.rotate(radians * 180 / Math.PI);
            return rotate.call(ctx, radians);
        };

        const translate = ctx.translate;
        ctx.translate = (dx, dy) => {
            xForm = xForm.translate(dx, dy);
            return translate.call(ctx, dx, dy);
        };

        const transform = ctx.transform;

        ctx.transform = (a, b, c, d, e, f) => {
            const m2 = svg.createSVGMatrix();
            m2.a = a;
            m2.b = b;
            m2.c = c;
            m2.d = d;
            m2.e = e;
            m2.f = f;
            xForm = xForm.multiply(m2);
            return transform.call(ctx, a, b, c, d, e, f);
        };

        const setTransform = ctx.setTransform;
        ctx.setTransform = (a, b, c, d, e, f) => {
            xForm.a = a;
            xForm.b = b;
            xForm.c = c;
            xForm.d = d;
            xForm.e = e;
            xForm.f = f;
            return setTransform.call(ctx, a, b, c, d, e, f);
        };

        const pt = svg.createSVGPoint();
        ctx.transformedPoint = (x, y) => {
            pt.x = x;
            pt.y = y;
            return pt.matrixTransform(xForm.inverse());
        };
    }
}
