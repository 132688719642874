import { BaseEntry } from '../base';
import { CampaignType } from './campaign-type.enum';
import { CampaignTargetEntry } from './campaign-target-entry.model';

export enum CampaignFilterOption {
    enabledOnly = 'enabledOnly',
    disabledOnly = 'disabledOnly',
    onair = 'onair',
    offlineOnly = 'offlineOnly',
    onlineOnly = 'onlineOnly'
}

export class CampaignFilter extends BaseEntry<CampaignFilter> {
    public search: string;
    public groups: string[];
    public colors: string[];
    public limit: number;
    public skip: number;
    public field: string;
    public desc: boolean;
    public targets: CampaignTargetEntry[];
    public type: CampaignType;
    public enabledOnly: boolean;
    public disabledOnly: boolean;
    public onair: boolean;
    public offlineOnly: boolean;
    public onlineOnly: boolean;
    public fullPlaylist: boolean;

    public static Default = (params: Partial<CampaignFilter> = {}) =>
        new CampaignFilter({
            search: null,
            skip: 0,
            limit: 50,
            groups: [],
            colors: [],
            field: 'name',
            desc: true,
            targets: [],
            type: CampaignType.ds,
            enabledOnly: false,
            disabledOnly: false,
            onair: false,
            onlineOnly: false,
            offlineOnly: false,
            fullPlaylist: false,
            ...params
        })
}
