import { Pipe, PipeTransform } from '@angular/core';
import { Helpers } from '@domain/utils/helpers';

export type ByteUnit = 'B' | 'kB' | 'KB' | 'MB' | 'GB' | 'TB';

const formats: { [key: string]: { max: number; prev?: ByteUnit } } = {
    B: { max: 1024 },
    kB: { max: Math.pow(1024, 2), prev: 'B' },
    KB: { max: Math.pow(1024, 2), prev: 'B' },
    MB: { max: Math.pow(1024, 3), prev: 'kB' },
    GB: { max: Math.pow(1024, 4), prev: 'MB' },
    TB: { max: Number.MAX_SAFE_INTEGER, prev: 'GB' },
};

@Pipe({
    name: 'bytes',
})
export class BytesPipe implements PipeTransform {
    transform(input: any, decimal: number = 0, from: ByteUnit = 'B', to?: ByteUnit): any {
        if (
            !(
                Helpers.isNumberFinite(input) &&
                Helpers.isNumberFinite(decimal) &&
                Helpers.isInteger(decimal) &&
                Helpers.isPositive(decimal)
            )
        ) {
            return input;
        }

        let bytes = input;
        let unit = from;
        while (unit !== 'B') {
            bytes *= 1024;
            unit = formats[unit].prev;
        }

        if (to) {
            const format = formats[to];
            const result = this.toDecimal(this.calculateResult(format, bytes), decimal);
            return this.formatResult(result, to);
        }

        for (const key of Object.keys(formats)) {
            const format = formats[key];
            if (bytes < formats[key].max) {
                const result = this.toDecimal(this.calculateResult(format, bytes), decimal);
                return this.formatResult(result, key);
            }
        }
    }

    private formatResult(result: number, unit: string): string {
        return `${result} ${unit}`;
    }

    private calculateResult(format: { max: number; prev?: ByteUnit }, bytes: number) {
        const prev = format.prev ? formats[format.prev] : undefined;
        return prev ? bytes / prev.max : bytes;
    }

    private toDecimal(value: number, decimal: number): number {
        return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
    }
}
