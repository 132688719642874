class Store {

    constructor(public prefix = 'UPLOAD-V3.0-') { }

    public set(key: string, value: string) {
        localStorage.setItem(this.prefix + key, value);
    }

    public get(key: string): string | null | false {
        return localStorage.getItem(this.prefix + key);
    }

    public delete(key: string) {
        localStorage.removeItem(this.prefix + key);
    }
}

export const store = 'localStorage' in window ? new Store() : new Map<string, string>();
