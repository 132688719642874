import { BaseEntry } from '../base/base-entry.model';

export class MatchOperation extends BaseEntry<MatchOperation> {
    public eq: string;
    public every: number;
    public from: number;
    public ge: number;
    public gt: number;
    public le: number;
    public lt: number;
    public to: number;
    public in: string[];
}
