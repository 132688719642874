import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ContentManagerService {
    openedNavBar = new BehaviorSubject<boolean>(null);

    public toggleNavBar(flag: boolean): void {
        this.openedNavBar.next(flag);
    }
}
