import { BaseEntry } from '@domain/base';

export class ResourceFilter extends BaseEntry<ResourceFilter> {
    public skip: number;
    public folderId: string;
    public limit: number;
    public desc: boolean;
    public field: string;
    public search: string;
    public items: { type: string, subtype: string }[];
    public initial: { type?: string, subtype?: string }[];
    public exclude: { type?: string[], subtype?: string[] }[];
    public include: { type?: string[], subtype?: string[] }[];
    public global: boolean;
    public tags: string[];

    public static DefaultQuery = () => new ResourceFilter({ limit: 50, desc: false, global: false, items: [], tags: [], field: 'name', exclude: [], initial: [], include: [] });
}
