import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'page-actions',
    templateUrl: './page-actions.component.html',
    styleUrls: ['./page-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageActionsComponent {
    @Input() showCustomButton: boolean = false;

    @Input() disabledCustomButton: boolean = false;

    @Output() addItem: EventEmitter<null> = new EventEmitter();
}
