/**
 * Reduce array items into dictionary.
 * @param existing Array<T> which will convert into the dictionary.
 * @param keyBy The mapper function to get key from T.
 * @param valueBy The mapper function to get value from T.
 * 
 * Example of usage:
 *  toDictionary<Action>(actions, x => x.id, x => x.name);
 */
export function toDictionary<T>(existing: Readonly<T[]>, keyBy: (value?: T) => string, valueBy: (value?: T) => any): Record<string, any> {

    return existing.reduce((map, state) => {

        map[keyBy(state)] = valueBy(state);

        return map;

    }, {});
}

export function addToDictionary<T>(items: T[], keyBy: (value?: T) => string, valueBy: (value?: T) => any): Record<string, any> {

    return (existing: Readonly<Record<string, any>>) => ({ ...existing, ...toDictionary(items, keyBy, valueBy) });
}
