import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { ResourcesCreator, PlayersCreator, LocationsCreator, CampaignsCreator, CustomersCreator, SchedulesCreator } from '../creators';

@Injectable()
export class FormsCreator {

    public resources: ResourcesCreator;

    public players: PlayersCreator;

    public locations: LocationsCreator;

    public campaigns: CampaignsCreator;

    public customers: CustomersCreator;

    public schedules: SchedulesCreator;

    public constructor(fb: UntypedFormBuilder) {
        this.resources = new ResourcesCreator(fb);
        this.players = new PlayersCreator(fb);
        this.locations = new LocationsCreator(fb);
        this.campaigns = new CampaignsCreator(fb);
        this.customers = new CustomersCreator(fb);
        this.schedules = new SchedulesCreator(fb);
    }

}
