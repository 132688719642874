<mat-sidenav-container *ngIf="{ 
    customerId: customerId$ | async,
    userGroups: userGroups$ | async,
    roles: roles$ | async,
    profile: profile$ | async,
    customersTree: customersTree$ | async,
    customer: customer$ | async,
    uiConfig: uiConfig$ | async
} as data" class="primary-sidenav-container">
	<mat-sidenav [disableClose]="!isSmall" class="primary-sidenav" #primarySidenav [mode]="isSmall ? 'over' : 'side'" [opened]="!isSmall" [ngClass]="{ 'sidenav-collapsed': collapsedNav && !isSmall }">
		<main-sidenav
            [uiConfig]="data.uiConfig"
            [collapsed]="collapsedNav && !isSmall"
            (toggleMenu)="onToggleMenu()">
        </main-sidenav>
	</mat-sidenav>

	<mat-sidenav-content class="primary-sidenav-content" [ngClass]="{ 'sidenav-collapsed': collapsedNav && !isSmall, 'sidenav-over' : isSmall }">
          <main-toolbar
            [showMenuToggler]="isSmall"
            [user]="data.profile"
            [customer]="data.customer"
            [customerId]="data.customerId"
            [customersTree]="data.customersTree"
            (openProfile)="onOpenProfile()"
            (toggleMenu)="onToggleMenu()"
            (changeCustomer)="onChangeCustomer($event)">
            <page-breadcrumbs [breadcrumbs]="breadcrumbs$ | async"></page-breadcrumbs>
        </main-toolbar>
        <div class="primary-content">
            <router-outlet class="primary-router"></router-outlet>
        </div>
	</mat-sidenav-content>

    <mat-sidenav class="secondary-sidenav" #secondarySidenav position="end" [(opened)]="profileSidenavOpened">
        <profile-info-panel *ngIf="profileSidenavOpened"
            [customerId]="data.customerId"
            [roles]="data.roles"
            [groups]="data.userGroups"
            (back)="secondarySidenav.close()">
        </profile-info-panel>
    </mat-sidenav>

</mat-sidenav-container>

<system-notifier></system-notifier>