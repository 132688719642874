import { AbstractControl } from '@angular/forms';

export function notEmptyArrayRequired(control: AbstractControl): { [key: string]: any } {
    if (!control.value) {
        return null;
    }

    if (!control.value.length) {
        return { required: true };
    }
}
