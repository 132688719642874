import { Injectable } from '@angular/core';


import { AuthService } from '../services/auth.service';


@Injectable()
export class AuthGuard  {
    constructor(private authService: AuthService) { }

    public canActivate(): boolean {
        if (this.authService.isLoggedIn()) {
            return true;
        }

        this.authService.startAuthentication();
        return false;
    }
}
