export enum CampaignTargetType {
    locations = 'locations',
    groups = 'groups',
    tags = 'tags',
}

export const campaignTargetTypes = [
    CampaignTargetType.locations,
    CampaignTargetType.groups,
    CampaignTargetType.tags
];
