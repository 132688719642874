import { BaseEntry } from '../base/base-entry.model';
import { PlayerScheduleEntry } from './player-schedule-entry.model';

export class PlayerSchedule extends BaseEntry<PlayerSchedule> {
    public id: string;    
    public customerId: string;
    public name: string;
    public schedules: PlayerScheduleEntry[];
    public version: number;
}
