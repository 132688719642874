import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { ApiUrls } from '@config/api-urls';
import { ApiService } from '@core/data/services';
import { AuthService } from '@core/auth';
import {
    Calendar2Request,
    Campaign,
    CampaignPlaylistEntry,
    CampaignsUpdatePlaylistRequest,
    CampaignsFindRequest,
    CampaignEntry,
    CampaignsFindByReferenceRequest,
    CampaignsUpdateTargetsRequest,
    TargetsTreeRequest,
    CampaignFilter,
    CampaignSchedule,
    AddScheduleRequest,
    CampaignSchedule2Entry
} from '@domain/campaigns';
import { DataQuery, DataFilter, DataSlice, DataSort } from '@domain/query';
import { EnableRequest, UpdateReferenceRequest } from '@domain/models';
import { Player, TargetExtGroup } from '@domain/players';
import { map, switchMap } from 'rxjs/operators';


export class CampaignsApi extends ApiService {

    constructor(public http: HttpClient, protected auth: AuthService) {
        super(http, auth);
    }

    /**
     * Read campaign by id
     * @param id Campaign id
     */
    public campaignsRead(id: string): Observable<Campaign> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsRead, { customerId, id });
    }

    /**
     * Read campaign with nested refs by campaign id
     * @param id Campaign id
     */
    public campaignsReadWithRefs(id: string): Observable<Campaign> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsRead, { customerId, id })
            .pipe(
                switchMap((campaign: Campaign) => {
                    const scheduleIds = (campaign.schedules2 || []).filter(x => !!x.scheduleId)
                        .map(x => x.scheduleId);
                    const resourceIds = (campaign.playlist || []).filter(x => x.subtype !== 'folder' && !!x.resourceId)
                        .map(x => x.resourceId);
                    const foldersIds = (campaign.playlist || []).filter(x => x.subtype === 'folder' && !!x.resourceId)
                        .map(x => x.resourceId);

                    const query = new DataQuery({ filters: [new DataFilter({ field: 'id', filter: { type: 'id', in: scheduleIds } })] });

                    return this.postBatch([
                        { id: 1, method: ApiUrls.resourcesReadList, params: { customerId, resourceIds } },
                        { id: 2, method: ApiUrls.campaignsSchedulesFind, params: { customerId, query } },
                        { id: 3, method: ApiUrls.metaTargetsTree, params: { sourceType: campaign.type, targetType: campaign.targetType, customerId } },
                        { id: 4, method: ApiUrls.resourcesReadTree, params: { customerId } },
                    ]).pipe(
                        map(x => {
                            return x;
                        })
                    );
                })
            );
    }

    /**
     * Get campaigns groups
     */
    public campaignsGroups(): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsGroups, { customerId });
    }

    /**
     * Create a new campaign entry
     * @param campaign Campaign to create
     */
    public campaignsCreate(campaign: Campaign): Observable<Campaign> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsCreate, { customerId, ...campaign });
    }

    /**
     * Update a campaign entry
     * @param campaign Campaign to update
     */
    public campaignsUpdate(campaign: Campaign): Observable<Campaign> {
        return this.post(ApiUrls.campaignsUpdate, campaign);
    }

    /**
     * Delete campaigns by ids
     * @param ids Campaigns ids to remove
     */
    public campaignsDelete(ids: string[]): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsDelete, { customerId, ids });
    }

    /**
     * Enable or disable target campaigns
     * @param request 
     */
    public campaignsEnable(request: EnableRequest): Observable<string[]> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsEnable, request);
    }

    public campaignsUpdateName(params: { id: string, name: string }): Observable<string> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsUpdateName, { customerId, ...params });
    }

    public campaignsUpdateGroup(params: { ids: string[], group: string }): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsUpdateGroup, { customerId, ...params });
    }

    public campaignsBuildPlaylist(id: string): Observable<CampaignPlaylistEntry[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsBuildPlaylist, { customerId, id });
    }

    public campaignsCalendar2(request: Calendar2Request): Observable<Campaign[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsCalendar2, { customerId, ...request });
    }

    public campaignsCalendarWithGlobal(request: Calendar2Request): Observable<Campaign[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsCalendar2, { customerId, ...request })
            .pipe(
                switchMap((campaigns: Campaign[]) => {
                    const idsMap = campaigns.map(x => (x.schedules2 || []).filter(s => !!s.scheduleId).map(s => s.scheduleId));
                    const scheduleIds = [].concat(...idsMap).filter(x => !!x);
                    const ids = scheduleIds.filter((value, index, array) => array.indexOf(value) === index);
                    return (ids.length ? this.campaignsSchedulesFindByIds(ids) : of([]))
                        .pipe(
                            map((campaignSchedules: CampaignSchedule[]) => campaigns.map((campaign: Campaign) => {
                                campaign.schedules2 = campaign.schedules2.map(schedule => {
                                    if (!schedule.scheduleId) {
                                        return schedule;
                                    }

                                    const campaignSchedule = campaignSchedules.find(x => x.id === schedule.scheduleId);
                                    return campaignSchedule ? new CampaignSchedule2Entry({ ...campaignSchedule.schedules2[0], scheduleId: schedule.scheduleId }) : null;

                                }).filter(x => !!x);

                                return campaign;
                            }))
                        );
                })
            );
    }

    public campaignsFind(request: CampaignsFindRequest): Observable<CampaignEntry[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsFind, { customerId, ...request });
    }

    public campaignsFindByFilter(filter: CampaignFilter): Observable<CampaignEntry[]> {
        const customerId = this.authService.getCustomerId();
        const query = new DataQuery({ filters: [], include: [] });

        if (filter.skip || filter.limit) {
            query.slice = new DataSlice({ skip: filter.skip, limit: filter.limit });
        }

        if (filter.field) {
            query.sort = new DataSort({ field: filter.field, desc: filter.desc });
        }

        if (filter.search) {
            query.filters.push(new DataFilter({ field: null, filter: { type: 'text', search: filter.search } }));
        }

        if (filter.groups?.length) {
            query.filters.push(new DataFilter({ field: 'group', filter: { type: 'string', in: filter.groups } }));
        }

        if (filter.colors?.length) {
            query.filters.push(new DataFilter({ field: 'color', filter: { type: 'string', in: filter.colors } }));
        }

        if (filter.onair) {
            query.filters.push(new DataFilter({ field: 'status', filter: { type: 'string', is: 'online' } }));
        }

        const { type, enabledOnly, disabledOnly, onlineOnly, offlineOnly, targets, fullPlaylist } = filter;

        return this.post(ApiUrls.campaignsFind, new CampaignsFindRequest({ query, type, customerId, targets, enabledOnly, disabledOnly, onlineOnly, offlineOnly, fullPlaylist }));
    }

    public campaignsFindByIds(ids: string[], type: string = null, fullPlaylist: boolean = false): Observable<CampaignEntry[]> {
        const customerId = this.authService.getCustomerId();
        const query = new DataQuery({
            filters: [new DataFilter({ field: 'id', filter: { type: 'id', in: ids } })]
        });
        return this.post(ApiUrls.campaignsFind, { customerId, query, type, fullPlaylist });
    }

    public campaignsFindByReference(request: CampaignsFindByReferenceRequest): Observable<CampaignEntry[]> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsFindByReference, request);
    }

    public campaignsUpdatePlaylistAdd(request: CampaignsUpdatePlaylistRequest): Observable<any> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsUpdatePlaylistAdd, request);
    }

    public campaignsUpdatePlaylistRemove(request: CampaignsUpdatePlaylistRequest): Observable<any> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsUpdatePlaylistRemove, request);
    }

    public campaignsUpdateTargetsAdd(request: CampaignsUpdateTargetsRequest): Observable<any> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsUpdateTargetsAdd, request);
    }

    public campaignsUpdateTargetsRemove(request: CampaignsUpdateTargetsRequest): Observable<any> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsUpdateTargetsRemove, request);
    }

    public campaignsForPlayer(player: Player): Observable<CampaignEntry[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsForPlayer, { ...player, customerId });
    }

    public metaTargetsTree(request: TargetsTreeRequest): Observable<TargetExtGroup[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.metaTargetsTree, { ...request, customerId });
    }

    public campaignsUpdatePlaylistReplace(request: UpdateReferenceRequest): Observable<string> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsUpdatePlaylistReplace, { ...request, customerId });
    }

    public campaignsScheduleAdd(request: AddScheduleRequest): Observable<CampaignEntry[]> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsScheduleAdd, request);
    }

    public campaignsScheduleRemove(request: AddScheduleRequest): Observable<CampaignEntry[]> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsScheduleRemove, request);
    }

    public campaignsSchedulesCount(query: DataQuery): Observable<number> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsSchedulesCount, { customerId, query });
    }

    public campaignsSchedulesCreate(schedule: CampaignSchedule): Observable<CampaignSchedule> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsSchedulesCreate, { customerId, ...schedule });
    }

    public campaignsSchedulesDelete(ids: string[]): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsSchedulesDelete, { customerId, ids });
    }

    public campaignsScheduleDeleteWithRefs(scheduleId: string, campaignIds: string[]): Observable<{ scheduleIds: string[], campaignIds: string[] }> {
        const customerId = this.authService.getCustomerId();

        const requestParams = [{ id: 1, method: ApiUrls.campaignsSchedulesDelete, params: { customerId, ids: [scheduleId] } }];

        if (campaignIds?.length) {
            requestParams.push({
                id: 2,
                method: ApiUrls.campaignsScheduleRemove,
                params: new AddScheduleRequest({ ids: campaignIds, customerId, schedule: new CampaignSchedule2Entry({ scheduleId }) })
            });
        }

        return this.postBatch(requestParams)
            .pipe(map((response) => ({ scheduleIds: response[0] || [], campaignIds: response[1] || [] })));
    }

    public campaignsSchedulesFind(query: DataQuery): Observable<CampaignSchedule[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsSchedulesFind, { customerId, query });
    }

    public campaignsSchedulesFindByIds(ids: string[]): Observable<CampaignSchedule[]> {
        const customerId = this.authService.getCustomerId();
        const query = new DataQuery({
            filters: [new DataFilter({ field: 'id', filter: { type: 'id', in: ids } })]
        });
        return this.post(ApiUrls.campaignsSchedulesFind, { customerId, query });
    }

    public campaignsSchedulesRead(id: string): Observable<CampaignSchedule> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsSchedulesRead, { customerId, id });
    }

    public campaignsSchedulesUpdate(schedule: CampaignSchedule): Observable<CampaignSchedule> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsSchedulesUpdate, { customerId, ...schedule });
    }

    public campaignsGroupRename(params: { oldGroup: string, newGroup: string }): Observable<any> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.campaignsGroupRename, { customerId, ...params });
    }
}
