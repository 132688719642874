<mat-expansion-panel *ngIf="{ total: total$ | async } as data" [expanded]="expanded">
    <mat-expansion-panel-header collapsedHeight="52px" expandedHeight="52px">
        <mat-panel-title>
            <mat-icon>cloud_upload</mat-icon> Upload Files
        </mat-panel-title>

        <mat-panel-description>
            <mat-progress-bar color="accent" mode="determinate" [value]="data.total.progress"></mat-progress-bar>
            <span class="count-uploaded">{{ data.total.uploaded }} of {{ data.total.count }}</span>
        </mat-panel-description>

    </mat-expansion-panel-header>

    <upload-file-state *ngFor="let fileState of files$ | async" 
        [fileState]="fileState" 
        (play)="onPlay(fileState)"
        (pause)="onPause(fileState)" 
        (cancel)="onCancel(fileState)">
    </upload-file-state>

</mat-expansion-panel>
