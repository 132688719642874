import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatTreeModule } from '@angular/material/tree';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

import { MainToolbarComponent } from './main-toolbar/main-toolbar.component';
import { MainSidenavComponent } from './main-sidenav/main-sidenav.component';


@NgModule({
    declarations: [
        MainToolbarComponent,
        MainSidenavComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatToolbarModule,
        MatListModule,
        MatTreeModule,
        CdkTreeModule,
        MatDividerModule,
        MatRippleModule,
        MatExpansionModule,
    ],
    exports: [
        MainToolbarComponent,
        MainSidenavComponent
    ],
})
export class MainLayoutModule { }
