import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

abstract class SecurityPipe implements PipeTransform {
    protected constructor(protected domSanitizer: DomSanitizer) {}

    transform(value) {
        return value && this.trust('' + value);
    }

    abstract trust(value);
}

@Pipe({ name: 'trustAsUrl' })
export class SecurityTrustAsUrlPipe extends SecurityPipe {
    constructor(domSanitizer: DomSanitizer) {
        super(domSanitizer);
    }

    public trust(value) {
        return this.domSanitizer.bypassSecurityTrustUrl(value);
    }
}

@Pipe({ name: 'trustAsResource' })
export class SecurityTrustAsResourcePipe extends SecurityPipe {
    constructor(domSanitizer: DomSanitizer) {
        super(domSanitizer);
    }

    public trust(value) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
    }
}

@Pipe({ name: 'trustAsHtml' })
export class SecurityTrustAsHtmlPipe extends SecurityPipe {
    constructor(domSanitizer: DomSanitizer) {
        super(domSanitizer);
    }

    public trust(value) {
        return this.domSanitizer.bypassSecurityTrustHtml(value);
    }
}
