import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'wifiStrength' })
export class WifiStrengthPipe implements PipeTransform {

    transform(value: number): string {
        if (!value) {
            return '00db / 00%';
        }

        return `${value} / ${this.toPercentage(value)}`;
    }

    private toPercentage(value: number): string {
        switch (true) {
            case (value >= -50):
                return '100%';
            case (value < -50 && value >= -55):
                return '90%';
            case (value < -56 && value >= -62):
                return '80%';
            case (value < -63 && value >= -65):
                return '75%';
            case (value < -66 && value >= -68):
                return '70%';
            case (value < -69 && value >= -74):
                return '60%';
            case (value < -75 && value >= -79):
                return '50%';
            case (value < -80 && value >= -83):
                return '30%';
            default:
                return '20%';
        }
    }
}
