import { BaseEntry } from '@domain/base';
import { CampaignTargetType } from '@domain/campaigns';

export class PlayerFilter extends BaseEntry<PlayerFilter> {
    public locationId: string;
    public search: string;
    public group: string;
    public targets: string[];
    public targetType: CampaignTargetType;
    public field: string;
    public desc: boolean;
    public limit: number;
    public skip: number;

    public static Default = () =>
        new PlayerFilter({ search: null, group: null, field: 'name', desc: false, targets: [], targetType: CampaignTargetType.locations })
}
