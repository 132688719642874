<button type="button" mat-icon-button color="primary" aria-label="Filter Options" (click)="onToggleOptions()" 
    cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <mat-icon class="trigger-icon">tune</mat-icon>
</button>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger" 
    [cdkConnectedOverlayOpen]="enabled"
    (overlayOutsideClick)="onClose($event)">

    <section class="page-filter-wrapper">
        <ng-content></ng-content>
    </section>

</ng-template>
