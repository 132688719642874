import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiUrls } from '@config/api-urls';
import { AuthService } from '@core/auth';
import { ApiService } from '@core/data/services';
import { User, UserEntry, UserCreateRequest, PasswordUpdateRequest } from '@domain/users';
import { GetConfigsRequest, PutConfigsRequest } from '@domain/models';
import { DataQuery } from '@domain/query';


export class UsersApi extends ApiService {

    constructor(public http: HttpClient, protected authService: AuthService) {
        super(http, authService);
    }

    /**
     * Read caller’s user information.
     */
    public usersMe(): Observable<User> {
        return this.post(ApiUrls.usersMe, {});
    }

    /**
     * List users for specified customer.
     */
    public usersFind(query: DataQuery): Observable<UserEntry[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.usersFind, { customerId, query });
    }

    /**
     * Create new user
     * @param user User object
     */
    public usersCreate(request: UserCreateRequest): Observable<User> {
        request.user.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.usersCreate, request);
    }

    /**
     * Load user information by id
     * @param id User Id
     */
    public usersRead(id: string): Observable<User> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.usersRead, { customerId, id });
    }

    /**
     * Update user information
     * @param user User object
     */
    public usersUpdate(user: User): Observable<User> {
        return this.post(ApiUrls.usersUpdate, user);
    }

    /**
     * Delete users
     * @param ids User ids to remove
     */
    public usersDelete(ids: string[]): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.usersDelete, { customerId, ids });
    }

    /**
     * List of user groups by specified customer.
     */
    public usersGroups(): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.usersGroups, { customerId });
    }

    /**
     * Update user password
     * @param params 
     */
    public usersUpdatePassword(request: PasswordUpdateRequest): Observable<User> {
        return this.post(ApiUrls.usersUpdatePassword, request);
    }

    /**
     * Get user config
     * @param params 
     */
    public usersGetConfigs(request: GetConfigsRequest): Observable<Record<string, any>> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.usersGetConfigs, request);
    }

    /**
     * Put user config
     * @param params
     */
    public usersPutConfigs(request: PutConfigsRequest): Observable<any> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.usersPutConfigs, request);
    }
}
