import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { animate, AnimationBuilder, style } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class SplashScreenService {

    constructor(
        private _animationBuilder: AnimationBuilder,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router,
    ) {
    }

    public hideOnInitialLoad(id: string): void {
        const hideOnLoad = this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                setTimeout(() => {
                    this.hide(id);
                    hideOnLoad.unsubscribe();
                }, 0);
            }
        });
    }

    public hide(id: string): void {
        const player = this._animationBuilder
            .build([
                style({ opacity: '1' }),
                animate('400ms ease', style({ opacity: '0', zIndex: '-10' }))
            ])
            .create(this._document.body.querySelector(`#${id}`));

        setTimeout(() => { player.play(); }, 0);
    }

    public show(id: string): void {
        const player = this._animationBuilder
            .build([
                style({ opacity: '0', zIndex: '99999' }),
                animate('400ms ease', style({ opacity: '1' })),
            ])
            .create(this._document.body.querySelector(`#${id}`));

        setTimeout(() => { player.play(); }, 0);
    }
}
