import { BaseEntry } from '@domain/base';

export class UploadTotal extends BaseEntry<UploadTotal> {

    public count: number;

    public progress: number;

    public size: number;

    public uploaded: number;
}
