import { Injectable } from '@angular/core';

import { Action, State, StateContext, Selector, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import produce from 'immer';
import { switchMap, tap, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { environment } from '@environments/environment';
import { Config } from 'config';
import { AuthService } from '@core/auth';
import { UIConfig, GetConfigsRequest, PutConfigsRequest } from '@domain/models';
import { Network } from '@core/network';
import { ApiUrls } from '@config/api-urls';
import { DataStateModel } from './data-state.model';
import { ApiService } from '../services/api.service';
import { DataActions } from './data.actions';
import { RoleRef } from '@domain/roles';
import { User } from '@domain/users';
import {
    DefaultCampaignsColumnsConfig, DefaultLocationsColumnsConfig, DefaultPlayersColumnsConfig, DefaultSensorsColumnsConfig,
    DefaultWapsColumnsConfig, TableConfig, ColumnConfig, Breakpoint, ColumnsConfigKeys, DefaultActionsColumnsConfig, GroupsKey, TagsKey
} from '../configs';
import { toDictionary, safeSet } from '@domain/operators';
import { LocationEntry } from '@domain/locations';
import { CampaignType, CampaignTargetType, TargetsTreeRequest } from '@domain/campaigns';
import { Folder } from '@domain/resources';
import { sortFoldersByName } from '@domain/utils';

@State<DataStateModel>({
    name: 'data',
    defaults: {
        previousUrl: null,
        profile: null,
        uiConfig: new UIConfig({}),
        roles: [],
        customer: null,
        avatarUrl: null,
        menuOpen: false,
        mediaTypes: [],
        resourcesQuota: null,
        customersTree: [],
        mobile: false,
        customerConfig: {
            presetColors: [],
        },
        columnConfigs: {},
        breakpoint: 'web',

        groups: {
            locations: [],
            players: [],
            sensors: [],
            waps: [],
            campaigns: [],
            users: [],
        },
        tags: {
            locations: [],
            players: [],
            sensors: [],
            waps: [],
            resources: [],
        },
        locations: [],
        targetTrees: {
            [CampaignType.ds]: {},
            [CampaignType.audio]: {},
            [CampaignType.wifi]: {},
        },
        breadcrumbs: [],
        folders: [],
    },
})
@Injectable()
export class DataState {

    @Selector()
    static uiConfig(state: DataStateModel) {
        return state.uiConfig;
    }

    @Selector()
    static breadcrumbs(state: DataStateModel) {
        return state.breadcrumbs;
    }

    @Selector()
    static roles(state: DataStateModel) {
        return state.roles;
    }

    @Selector()
    static customersTree(state: DataStateModel) {
        return state.customersTree;
    }

    @Selector()
    static previousUrl(state: DataStateModel) {
        return state.previousUrl;
    }

    @Selector()
    static playersVisible(state: DataStateModel) {
        return state.uiConfig.playersVisible;
    }

    @Selector()
    static playersAvailable(state: DataStateModel) {
        return state.uiConfig.playersAvailable;
    }

    @Selector()
    static sensorsVisible(state: DataStateModel) {
        return state.uiConfig.sensorsVisible;
    }

    @Selector()
    static sensorsAvailable(state: DataStateModel) {
        return state.uiConfig.sensorsAvailable;
    }

    @Selector()
    static wapsVisible(state: DataStateModel) {
        return state.uiConfig.wapsVisible;
    }

    @Selector()
    static wapsAvailable(state: DataStateModel) {
        return state.uiConfig.wapsAvailable;
    }

    @Selector()
    static locationsVisible(state: DataStateModel) {
        return state.uiConfig.locationsVisible;
    }

    @Selector()
    static campaignsModifiable(state: DataStateModel) {
        return state.uiConfig.campaignsModifiable;
    }

    @Selector()
    static playersModifiable(state: DataStateModel) {
        return state.uiConfig.playersModifiable;
    }

    @Selector()
    static profile(state: DataStateModel) {
        return state.profile;
    }

    @Selector()
    static avatarUrl(state: DataStateModel) {
        return state.avatarUrl;
    }

    @Selector()
    static organization(state: DataStateModel) {
        return state.customer;
    }

    @Selector()
    static customerId(state: DataStateModel) {
        return state.customer ? state.customer.id : null;
    }

    @Selector()
    static menuOpen(state: DataStateModel) {
        return state.menuOpen;
    }

    @Selector()
    static mediaTypes(state: DataStateModel) {
        return state.mediaTypes;
    }

    @Selector()
    static presetColors(state: DataStateModel) {
        return Array.isArray(state.customerConfig.presetColors)
            ? state.customerConfig.presetColors : [];
    }

    @Selector()
    static resourcesQuota(state: DataStateModel) {
        return state.resourcesQuota;
    }

    @Selector()
    static mobile(state: DataStateModel) {
        return state.mobile;
    }

    @Selector()
    static breakpoint(state: DataStateModel) {
        return state.breakpoint;
    }

    @Selector()
    static allLocations(state: DataStateModel) {
        return state.locations;
    }

    @Selector()
    static folders(state: DataStateModel) {
        return state.folders;
    }

    static uiConfigKey(key: keyof UIConfig) {
        return createSelector([DataState], (state: DataStateModel) => state.uiConfig[key]);
    }

    static columns(key: ColumnsConfigKeys) {
        return createSelector([DataState], (state: DataStateModel) =>
            state.columnConfigs[TableConfig.columnsKey(key, state.breakpoint)]);
    }

    static columnsWidth(key: ColumnsConfigKeys) {
        return createSelector([DataState], (state: DataStateModel) =>
            toDictionary(state.columnConfigs[TableConfig.columnsKey(key, state.breakpoint)], x => x.name, x => x.width));
    }

    static displayedColumns(key: ColumnsConfigKeys) {
        return createSelector([DataState], (state: DataStateModel) => {
            const columns = state.columnConfigs[TableConfig.columnsKey(key, state.breakpoint)].filter(c => !!c.visible).map(c => c.name);
            return ['select', ...columns, 'more'];
        });
    }

    static groups(key: GroupsKey) {
        return createSelector([DataState], (state: DataStateModel) => {
            return state.groups[key];
        });
    }

    static tags(key: TagsKey) {
        return createSelector([DataState], (state: DataStateModel) => {
            return state.tags[key];
        });
    }

    static targetsTree(type: CampaignType, targetType: CampaignTargetType) {
        return createSelector([DataState], (state: DataStateModel) => {
            return state.tags[type][targetType];
        });
    }

    constructor(private network: Network, private authService: AuthService, private apiService: ApiService) { }

    @Action(DataActions.LoadGroups)
    onLoadGroups(ctx: StateContext<DataStateModel>, { payload }: DataActions.LoadGroups) {
        return this.getGroups(payload)
            .pipe(tap((groups: string[]) => {
                ctx.setState(patch({ groups: patch<Record<GroupsKey, string[]>>({ [payload]: groups || [] }) }));
            }));
    }

    @Action(DataActions.LoadResourceFolders)
    onLoadResourceFolders(ctx: StateContext<DataStateModel>) {
        return this.network.resourcesApi.resourcesReadTree()
            .pipe(tap((folders: Folder[]) => {
                ctx.setState(patch({ folders: sortFoldersByName(folders || []) }));
            }));
    }

    @Action(DataActions.SetGroups)
    onSetGroups(ctx: StateContext<DataStateModel>, { groupsKey, groups }: DataActions.SetGroups) {
        ctx.setState(patch({ groups: patch<Record<GroupsKey, string[]>>({ [groupsKey]: groups || [] }) }));
    }

    private getGroups(key: GroupsKey): Observable<string[]> {
        switch (key) {
            case 'locations': return this.network.locationsApi.locationsGroups();
            case 'players': return this.network.playersApi.playersGroups();
            case 'sensors': return this.network.sensorsApi.sensorsGroups();
            case 'waps': return this.network.wapsApi.wapsGroups();
            case 'campaigns': return this.network.campaignsApi.campaignsGroups();
            case 'users': return this.network.usersApi.usersGroups();
            default: return of([]);
        }
    }

    @Action(DataActions.LoadTags)
    onLoadTags(ctx: StateContext<DataStateModel>, { payload }: DataActions.LoadTags) {
        return this.getTags(payload)
            .pipe(tap((tags: string[]) => {
                ctx.setState(patch({ tags: patch<Record<TagsKey, string[]>>({ [payload]: tags || [] }) }));
            }));
    }

    private getTags(key: TagsKey): Observable<string[]> {
        switch (key) {
            case 'locations': return this.network.locationsApi.locationsTags();
            case 'players': return this.network.playersApi.playersTags();
            case 'sensors': return this.network.sensorsApi.sensorsTags();
            case 'waps': return this.network.wapsApi.wapsTags();
            case 'resources': return this.network.resourcesApi.resourcesTags();
            default: return of([]);
        }
    }

    @Action(DataActions.LoadLocations)
    onLoadLocations({ setState }: StateContext<DataStateModel>) {
        return this.network.locationsApi.locationsAll()
            .pipe(tap((locations: LocationEntry[]) => setState(patch({ locations: safeSet(locations) }))));
    }

    @Action(DataActions.SetLocations)
    onSetLocations({ setState }: StateContext<DataStateModel>, { locations }: DataActions.SetLocations) {
        setState(patch({ locations: safeSet(locations) }));
    }

    @Action(DataActions.LoadUploadsMediaTypes)
    onLoadUploadsMediaTypes(ctx: StateContext<DataStateModel>) {
        if (ctx.getState().mediaTypes.length) {
            return of(true);
        }

        return this.network.uploadsApi.uploadsMediaTypes()
            .pipe(tap(mediaTypes => ctx.patchState({ mediaTypes })));
    }

    @Action(DataActions.LoadResourcesQuota)
    onLoadResourcesQuota(ctx: StateContext<DataStateModel>) {
        return this.network.resourcesApi.resourcesQuota()
            .pipe(tap(resourcesQuota => ctx.patchState({ resourcesQuota })));
    }

    private buildAvatarUrl(user: User): string {
        return user.avatarId
            ? `${environment.uploadAvatarUrl}/${user.customerId}/${user.avatarId}?access_token=${this.authService.getAuthToken()}`
            : Config.assets.IMG_AVATAR;
    }

    @Action(DataActions.LoadCustomerDetails)
    onLoadCustomerDetails(ctx: StateContext<DataStateModel>) {
        const requestParams = [
            { id: 1, method: ApiUrls.usersMe, },
            { id: 2, method: ApiUrls.usersUiConfig },
            { id: 3, method: ApiUrls.customersMe }
        ];

        return this.apiService.postBatch(requestParams).pipe(
            switchMap((results: any) => {
                const { childCustomersModifiable, childCustomersVisible } = results[1];

                if (childCustomersModifiable || childCustomersVisible) {
                    return this.apiService.post(ApiUrls.customersTree, {})
                        .pipe(map(result => [...results, result]));
                }

                return of(results);
            }),
            tap((results: any[]) => {
                const profile = results[0];
                const uiConfig = results[1];
                const customer = results[2];
                const customersTree = results[3] || null;
                const avatarUrl = this.buildAvatarUrl(profile);

                ctx.patchState({ profile, uiConfig, customer, customersTree, avatarUrl });

                const myProfile = { ...profile };
                myProfile.uiConfig = uiConfig;
                myProfile.organization = customer;
                sessionStorage.setItem(environment.currentUser, JSON.stringify(myProfile));
            }),
        );
    }

    @Action(DataActions.ToggleMainMenu)
    onToggleMainMenu(ctx: StateContext<DataStateModel>) {
        ctx.setState(
            produce(ctx.getState(), draft => { draft.menuOpen = !draft.menuOpen; }),
        );
    }

    @Action(DataActions.SetPreviousUrl)
    onSetPreviousUrl(ctx: StateContext<DataStateModel>, { payload }: DataActions.SetPreviousUrl) {
        ctx.patchState({ previousUrl: payload });
    }

    @Action(DataActions.SetMobile)
    onSetMobile(ctx: StateContext<DataStateModel>, { payload }: DataActions.SetMobile) {
        ctx.patchState({ mobile: payload });
    }

    @Action(DataActions.SetBreakpoint)
    onSetBreakpoint(ctx: StateContext<DataStateModel>, { payload }: DataActions.SetBreakpoint) {
        ctx.patchState({ breakpoint: payload });
    }

    @Action(DataActions.SetBreadcrumbs)
    onSetBreadcrumbs(ctx: StateContext<DataStateModel>, { breadcrumbs }: DataActions.SetBreadcrumbs) {
        ctx.patchState({ breadcrumbs });
    }

    @Action(DataActions.LoadRoles)
    onLoadRoles(ctx: StateContext<DataStateModel>) {
        return this.network.rolesApi.rolesFind().pipe(
            tap((roles: RoleRef[]) => ctx.patchState({ roles }))
        );
    }

    @Action(DataActions.UpdateProfile)
    onUpdateProfile(ctx: StateContext<DataStateModel>, { payload }: DataActions.UpdateProfile) {
        return this.network.usersApi.usersUpdate(payload).pipe(
            tap((profile: User) => {
                ctx.patchState({ profile, avatarUrl: this.buildAvatarUrl(profile) });
            }),
        );
    }

    @Action(DataActions.ChangePassword)
    onChangePassword(ctx: StateContext<DataStateModel>, { payload }: DataActions.ChangePassword) {
        return this.network.usersApi.usersUpdatePassword(payload).pipe(
            tap((profile: any) => { console.log(profile); }),
        );
    }

    @Action(DataActions.GetColumnsConfig)
    onGetColumnsConfig(ctx: StateContext<DataStateModel>, { payload }: DataActions.GetColumnsConfig) {

        const keys = TableConfig.columns(payload);
        return this.network.usersApi.usersGetConfigs(new GetConfigsRequest({ names: keys.map(x => x.name) })).pipe(
            tap((data: Record<string, any>) => {

                const columnConfigs = {};

                keys.forEach(key => {
                    const defaultColumns = this.getDefaultColumnsConfig(payload, key.breakpoint);
                    const savedColumns = data[key.name] && Array.isArray(data[key.name]) ? data[key.name] : [];
                    const columns = TableConfig.mergeColumns(defaultColumns, savedColumns);
                    columnConfigs[key.name] = columns;
                });

                ctx.patchState({ columnConfigs: { ...ctx.getState().columnConfigs, ...columnConfigs } });
            }),
        );
    }

    @Action(DataActions.SetDefaultColumnsConfig)
    onSetDefaultColumnsConfig(ctx: StateContext<DataStateModel>, { payload }: DataActions.SetDefaultColumnsConfig) {
        const { breakpoint } = ctx.getState();
        const columns = this.getDefaultColumnsConfig(payload, breakpoint);
        columns && ctx.dispatch(new DataActions.ChangeColumnsConfig({ key: payload, columns }));
    }

    @Action(DataActions.ResizeColumns)
    onResizeColumns(ctx: StateContext<DataStateModel>, { payload }: DataActions.ResizeColumns) {
        const { columnConfigs, breakpoint } = ctx.getState();

        const name = TableConfig.columnsKey(payload.key, breakpoint);

        const columns = columnConfigs[name].map((column: ColumnConfig) => {
            const changes = payload.columns.find(x => x.name === column.name);
            return new ColumnConfig({ ...column, width: changes?.width || column.width });
        });

        return ctx.dispatch(new DataActions.ChangeColumnsConfig({ key: payload.key, columns }));
    }

    @Action(DataActions.ChangeColumnsConfig)
    onChangeColumnsConfig(ctx: StateContext<DataStateModel>, { payload }: DataActions.ChangeColumnsConfig) {

        const name = TableConfig.columnsKey(payload.key, ctx.getState().breakpoint);
        ctx.setState(patch({ columnConfigs: patch<Record<string, ColumnConfig[]>>({ [name]: payload.columns }) }));

        const request = new PutConfigsRequest({ configs: { [name]: payload.columns } });

        return this.network.usersApi.usersPutConfigs(request);
    }

    @Action(DataActions.GetCustomerConfig)
    onGetCustomerConfig(ctx: StateContext<DataStateModel>, { payload }: DataActions.GetCustomerConfig) {
        return this.network.customersApi.customersGetConfigs(new GetConfigsRequest({ names: [payload] })).pipe(
            tap((config: any) => {
                ctx.setState(patch({ customerConfig: patch({ [payload]: config[payload] }) }));
            }),
        );
    }

    @Action(DataActions.SetCustomerConfig)
    onSetCustomerConfig(ctx: StateContext<DataStateModel>, { payload }: DataActions.SetCustomerConfig<any>) {
        return this.network.customersApi.customersPutConfigs(new PutConfigsRequest({ configs: { [payload.key]: payload.value } })).pipe(
            tap((config: any) => {
                ctx.setState(patch({ customerConfig: patch({ [payload.key]: payload.value }) }));
            }),
        );
    }

    private getDefaultColumnsConfig(key: ColumnsConfigKeys, breakpoint: Breakpoint): ColumnConfig[] {
        switch (key) {
            case 'ActionsColumns': {
                return DefaultActionsColumnsConfig(breakpoint);
            }
            case 'CampaignsColumns': {
                return DefaultCampaignsColumnsConfig(breakpoint);
            }
            case 'PlayersColumns': {
                return DefaultPlayersColumnsConfig(breakpoint);
            }
            case 'WapsColumns': {
                return DefaultWapsColumnsConfig(breakpoint);
            }
            case 'LocationsColumns': {
                return DefaultLocationsColumnsConfig(breakpoint);
            }
            case 'SensorsColumns': {
                return DefaultSensorsColumnsConfig(breakpoint);
            }
            default: {
                return null;
            }
        }
    }

    @Action(DataActions.LoadTargets)
    onLoadTargets(ctx: StateContext<DataStateModel>, { sourceType, targetType }: DataActions.LoadTargets) {
        return this.network.campaignsApi.metaTargetsTree(new TargetsTreeRequest({ sourceType, targetType }))
            .pipe(
                tap((targets) => {
                    ctx.setState(patch({ targetTrees: patch({ [sourceType]: patch({ [targetType]: targets }) }) }));
                })
            );
    }
}
