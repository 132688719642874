import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'page-groups',
    templateUrl: './page-groups.component.html',
    styleUrls: ['./page-groups.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageGroupsComponent {

    @Input() groups: string[];

    @Input() selectedGroup: string;

    @Output() selectGroup = new EventEmitter<string>();
}
