import { Input, Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
    selector: '[toolbarItemDef]'
})
export class ToolbarItemDefDirective implements AfterViewInit {

    @Input('toolbarItemDef') key: string;

    @Input() visible: boolean = false;

    @Input() marginLeft: number = 0;

    public width: number = 0;

    public type: 'icon-button' | 'flat-button' | 'stroked-button' | 'base-button' = 'base-button';

    public constructor(public el: ElementRef) { }

    public ngAfterViewInit(): void {
        this.width = this.el.nativeElement.clientWidth;
        this.type = this.getType();
    }

    public setMarginLeft(marginLeft: number): void {
        this.marginLeft = marginLeft || 0;
        this.el.nativeElement.style.marginLeft = `${this.marginLeft}px`;
    }

    private getType(): 'icon-button' | 'flat-button' | 'stroked-button' | 'base-button' {
        if (this.el.nativeElement.attributes['mat-icon-button']) {
            return 'icon-button';
        }

        if (this.el.nativeElement.attributes['mat-stroked-button']) {
            return 'stroked-button';
        }

        if (this.el.nativeElement.attributes['mat-flat-button']) {
            return 'flat-button';
        }

        return 'base-button';
    }
}
