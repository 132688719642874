import { SensorVendor } from '@domain/sensors';

export const Sensors: { vendorList: SensorVendor[], defaultType: string, allValue: string } = {
    vendorList: [
        new SensorVendor({ value: 'Axis', addressRequired: true, zone: 'multiple', zoneType: 'counter', generateHardwareId: false }),
        new SensorVendor({ value: 'Crosscan', addressRequired: true, zone: 'single', zoneType: 'counter', generateHardwareId: true }),
        new SensorVendor({ value: 'Empalis', addressRequired: false, zone: 'single', zoneType: 'person', generateHardwareId: false }),
        new SensorVendor({ value: 'Pyramics', addressRequired: true, zone: 'single', zoneType: 'person', generateHardwareId: false }),
        new SensorVendor({ value: 'Xovis', addressRequired: false, zone: 'multiple', zoneType: 'counter', generateHardwareId: false }),
    ],
    defaultType: 'Axis',
    allValue: 'all',
};
