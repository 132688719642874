import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBarRef, MatSnackBar } from '@angular/material/snack-bar';

import { map, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { UploadFilesManagerComponent } from './upload-files-manager/upload-files-manager.component';
import { UploadService } from './upload.service';


@Injectable()
export class UploadWatcher implements OnDestroy {

    public snackBarRef: MatSnackBarRef<UploadFilesManagerComponent>;

    protected subscriptions: Subscription[] = [];

    constructor(public upload: UploadService, public snackBar: MatSnackBar) {
        this.subscribeOnUploadTotalToOpenManager();
        this.subscribeOnUploadTotalToCloseManager();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s && s.unsubscribe());
    }

    private openUploadManager(): void {
        this.snackBarRef = this.snackBar.openFromComponent(UploadFilesManagerComponent, {
            panelClass: 'upload-snackbar',
            horizontalPosition: 'start',
            verticalPosition: 'bottom',
        });
    }

    private subscribeOnUploadTotalToCloseManager(): void {
        const subscription = this.upload.uploadTotal
            .pipe(
                map(total => total.uploaded === total.count),
                distinctUntilChanged()
            )
            .subscribe(allUploaded => {
                if (this.snackBarRef && allUploaded) {
                    this.closeUploadManager();
                    this.upload.disconnect();
                    this.snackBarRef = null;
                }
            });

        this.subscriptions.push(subscription);
    }

    private subscribeOnUploadTotalToOpenManager(): void {
        const subscription = this.upload.uploadTotal
            .pipe(
                map(total => total.uploaded !== total.count),
                distinctUntilChanged()
            )
            .subscribe(has => {
                if (!this.snackBarRef && has) {
                    this.openUploadManager();
                }
            });

        this.subscriptions.push(subscription);
    }

    private closeUploadManager(): void {
        this.snackBarRef && this.snackBarRef.dismiss();
    }
}
