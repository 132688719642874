import { Directive, Input, OnInit, ElementRef, HostListener } from '@angular/core';

const _formatToRegExp = {
    '0': /[0-9]/, 
    'a': /[a-z]/, 
    'A': /[A-Z]/, 
    'B': /[a-zA-Z]/, 
    'C': /[0-9a-fA-F]/,
};

const _allFormatsStr = '(' +
    Object.keys(_formatToRegExp)
        .map(key => _formatToRegExp[key].toString())
        .map(regexStr => regexStr.substr(1, regexStr.length - 2))
        .join('|')
    + ')';

const _allFormatsGlobal = getAllFormatRegexp('g');


@Directive({
    selector: 'input[mask]',
})
export class MaskDirective implements OnInit {

    @Input('mask') mask: string;

    private _inputElem: HTMLInputElement;

    private _lastMaskedValue: string = '';

    constructor(private el: ElementRef) { }

    public ngOnInit(): void {
        this._inputElem = this.el.nativeElement;
    }

    @HostListener('input')
    onInput() {
        this._inputElem.value = this._maskValue(this._inputElem.value);
    }

    private _maskValue(val: string): string {
        if (!val || !this.mask || val === this._lastMaskedValue) {
            return val;
        }

        return this._lastMaskedValue = valueToFormat(val, this.mask);
    }

}

/**
   * Apply format to a value string
   *
   * Format can be constructed from next symbols:
   *  - '0': /[0-9]/,
   *  - 'a': /[a-z]/,
   *  - 'A': /[A-Z]/,
   *  - 'B': /[a-zA-Z]/
   *
   * Example: 'AAA-00BB-aaaa'
   * will accept 'COD-12Rt-efww'
   *
   * @param value Current value
   * @param format Format
   */
function valueToFormat(value: string, format: string): string {
    const unmaskedValue = unmaskValue(value);
    const maskedValueArray = unmaskedValue.split('');
    for (let formatCharPosition = 0; formatCharPosition < format.length; formatCharPosition++) {
        const valueChar = maskedValueArray[formatCharPosition];
        // Do skip position if no value was inputted at this position
        if (valueChar === undefined) {
            continue;
        }

        let formatChar: string = format[formatCharPosition];
        let formatRegex = getFormatRegexp(formatChar);

        const isSeparator = formatChar && !formatRegex;
        if (isSeparator) {
            // Adds separator on correct position and skips formatting
            maskedValueArray.splice(formatCharPosition, 0, formatChar);
            continue;
        }

        if (valueChar && formatRegex && formatRegex.test(valueChar)) {
            // Adds formatted char to the correct position
            maskedValueArray.splice(formatCharPosition, 1, valueChar);
        } else {
            maskedValueArray.splice(formatCharPosition, 1, '');
        }
    }

    // Join all parsed value, limiting length to the one specified in format
    return maskedValueArray.join('').substr(0, format.length);
}

function unmaskValue(value: string): string {
    const unmaskedMatches = value.replace(' ', '').match(_allFormatsGlobal);
    return unmaskedMatches ? unmaskedMatches.join('') : '';
}

function getAllFormatRegexp(flags?: string) {
    return new RegExp(_allFormatsStr, flags);
}

function getFormatRegexp(formatChar: string): RegExp | null {
    return formatChar && _formatToRegExp[formatChar] ? _formatToRegExp[formatChar] : null;
}