import { InjectionToken } from '@angular/core';

export interface IHotkeyOptions {

    disableCheatSheet?: boolean;

    cheatSheetHotkey?: string;

    cheatSheetDescription?: string;
}

export const HotkeyOptions = new InjectionToken<IHotkeyOptions>('HotkeyOptions');
