import { AfterViewInit, ElementRef, ViewChild, OnInit, Component } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { Observable, fromEvent, Subject, timer } from 'rxjs';
import { debounceTime, pairwise, startWith, debounce } from 'rxjs/operators';

import { ObserverComponent } from '@domain/base/observer.component';
import { Size } from '@domain/models';
import { KeyMapper, KeyType } from './keys.types';

@Component({ template: '' })
export abstract class TableComponent<T> extends ObserverComponent implements OnInit, AfterViewInit {

    @ViewChild(MatSort) sort: MatSort;

    public scrollerRef: ElementRef;

    public dataSource = new MatTableDataSource<T>();

    public selection = new SelectionModel<KeyType>(true, []);

    public isLoading: boolean;

    public allSelected: boolean;

    protected hasSelected: Subject<boolean> = new Subject<boolean>();

    protected selectionIndex: number;

    protected clientSideSort: boolean;

    protected debounceScroll: number = 100;

    protected scrollTop: number = 0;

    protected scrollThreshold: number = 200;

    protected get sizes(): Size[] {
        return [];
    }

    protected abstract key: KeyMapper<T>;

    protected renderedData: T[] = [];

    public ngOnInit(): void {
        if (this.selection) {
            this.subscribeOnSelectionChange();
            this.subscribeOnHasSelected();
        }

        if (this.sizes.length) {
            this.subscribeOnResize();
        }
    }

    public ngAfterViewInit(): void {
        this.scrollerRef && this.subscribeOnScroll();
        this.sort && this.initSort();
    }

    public onToggleAll() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.getDataSourceData().forEach(row => { this.selection.select(this.key(row)); });
    }

    public onSelect(event: any, item: T): void {
        const key = this.key(item);

        if (!this.selection.isMultipleSelection()) {
            this.selectSingle(key);
            return;
        }

        if (this.isCtrlPressed(event)) {
            this.selectViaCtrl(key);
        } else if (this.isShiftPressed(event)) {
            this.selectViaShift(key);
        } else {
            this.selectSingle(key);
        }
    }

    public isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.getDataSourceData().length;
        return numSelected === numRows;
    }

    public trackById(index: number, item: { id: any }) {
        return item.id;
    }

    public trackByIndex(index: number, item: T) {
        return index;
    }

    protected showToolbar(show: boolean): void {
        // This is intentional
    }

    protected getToolbarTitle(byField: keyof T): string {
        if (this.selection.isEmpty()) {
            return null;
        }

        if (this.selection.selected.length === 1) {
            const entry = this.dataSource.data.find(x => this.key(x) === this.selection.selected[0]);
            return entry[byField as string];
        }

        return `${this.selection.selected.length} selected`;
    }

    public onCloseToolbar(): void {
        this.selection.hasValue() && this.selection.clear();
    }

    protected isCtrlPressed(e: any): boolean {
        return e.ctrlKey || e.metaKey;
    }

    protected isShiftPressed(e: any): boolean {
        return e.shiftKey && !this.selection.isEmpty();
    }

    protected selectSingle(key: string | number): void {

        const selected = this.selection.isSelected(key);
        this.selection.clear();

        if (!selected) {
            this.selectionIndex = this.getDataSourceData().findIndex(x => this.key(x) === key);
            this.selection.toggle(key);
        }
    }

    protected selectViaShift(key: string | number): void {
        const index = this.getDataSourceData().findIndex(x => this.key(x) === key);
        this.selection.clear();
        const ids = this.getDataSourceData().map(x => this.key(x));

        if (index < this.selectionIndex) {
            this.selection.select(...ids.slice(index, this.selectionIndex + 1));
        } else {
            this.selection.select(...ids.slice(this.selectionIndex, index + 1));
        }
    }

    protected selectViaCtrl(key: string | number): void {
        if (this.selection.isEmpty()) {
            this.selectionIndex = this.getDataSourceData().findIndex(x => this.key(x) === key);
        }
        this.selection.toggle(key);
    }

    protected getDataSourceData(): T[] {
        return this.clientSideSort ? this.renderedData : this.dataSource.data;
    }

    protected subscribeOnRenderedData(): void {
        const subscription = this.dataSource.connect().subscribe(renderedData => this.renderedData = renderedData || []);
        this.subscriptions.push(subscription);
    }

    protected onScroll(e: any): void {
        const { offsetHeight, scrollHeight, scrollTop } = e.target;

        if (this.scrollTop >= scrollTop || this.isLoading) {
            this.scrollTop = scrollTop;
            return;
        }

        this.scrollTop = scrollTop;
        if (offsetHeight >= scrollHeight - (scrollTop + this.scrollThreshold)) {
            this.isLoading = true;
            this.onLazyLoad().subscribe(() => {
                this.isLoading = false;
            });
        }
    }

    protected subscribeOnScroll(): void {
        const subscription = fromEvent(this.scrollerRef.nativeElement, 'scroll')
            .pipe(
                debounceTime(this.debounceScroll)
            )
            .subscribe((e: any) => this.onScroll(e));

        this.subscriptions.push(subscription);
    }

    protected subscribeOnSortChange(): void {
        const subscription = this.sort.sortChange
            .subscribe(({ active, direction }: any) => {
                this.onSortChange({ sortOn: active, sortDirection: direction });
            });

        this.subscriptions.push(subscription);
    }

    protected subscribeOnSelectionChange(): void {
        const subscription = this.selection.changed
            .pipe(debounceTime(1))
            .subscribe(value => {
                this.allSelected = this.isAllSelected();
                this.onSelectionChanged();
                this.hasSelected.next(this.selection.hasValue());
            });

        this.subscriptions.push(subscription);
    }

    protected onSelectionChanged(): void {
        // This is intentional
    }

    protected onDelayedSelectionChanged(): void {
        // This is intentional
    }

    protected subscribeOnHasSelected(): void {
        const subscription = this.hasSelected
            .pipe(
                startWith(false),
                pairwise(),
                debounce(([prev, current]) => !prev && current ? timer(250) : timer(0)),
            )
            .subscribe((value) => {
                this.onDelayedSelectionChanged();
            });

        this.subscriptions.push(subscription);
    }

    protected subscribeOnResize(): void {
        const subscription = fromEvent(window, 'resize')
            .pipe(debounceTime(this.debounceScroll))
            .subscribe(() => this.onResize());

        this.subscriptions.push(subscription);
    }

    protected refreshSelection(): void {
        const ids = this.getDataSourceData().map(x => this.key(x));
        const deselected = this.selection.selected.filter(v => !ids.includes(v));
        this.selection.deselect(...deselected);
    }

    protected setDataSource(items: T[]) {
        if (this.scrollerRef && items.length < this.dataSource.data.length) {
            this.scrollerRef.nativeElement.scrollTop = this.scrollerRef.nativeElement.scrollTop * 0.9;
        }

        this.dataSource.data = items;

        if (this.clientSideSort) {
            this.subscribeOnRenderedData();
        }

        this.refreshSelection();
    }

    protected onLazyLoad(): Observable<any> {
        throw new Error('The lazy loading feature is not implemented.' +
            'Please, override implementation of \'protected onLazyLoad(): Observable<any>\'');
    }

    protected onSortChange(event: { sortOn: string; sortDirection: string; }): void {
        throw new Error('The sorting feature is not implemented. ' +
            'Please, override implementation of \'protected onSortChange(event: { sortOn: string; sortDirection: string; }): void\'');
    }

    protected onResize(): void {
        throw new Error('The dynamic calculation count is not implemented. ' +
            'Please, override implementation of \'protected onResize(): void\'');
    }

    protected initSort(): void {
        if (this.clientSideSort) {
            this.dataSource.sort = this.sort;
        } else {
            this.subscribeOnSortChange();
        }
    }
}
