import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiUrls } from '@config/api-urls';
import { ApiService } from '@core/data/services';
import { AuthService } from '@core/auth';
import {
    Player, PlayerStatus, PlayerRebindRequest,
    PlayerEntry, PlayerLog, PlayerCreateRequest, PlayerRotateRequest, PlayersTreeRequest, TargetExtGroup, PlayerSchedule, PlayersControlMultiRequest, PlayerFilter
} from '@domain/players';
import { DataQuery, DataSlice, DataFilter, DataSort } from '@domain/query';
import { UpdateEntitiesLocationRequest } from '@domain/locations';
import { DeleteRequest, EnableRequest, UpdateEntitiesPropertyRequest } from '@domain/models';
import { CampaignTargetType } from '@domain/campaigns';


export class PlayersApi extends ApiService {

    constructor(public http: HttpClient, protected authService: AuthService) {
        super(http, authService);
    }

    /**
     * Enable or disable target players
     * @param params 
     */
    public playersEnable(params: EnableRequest): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersEnable, { customerId, ...params });
    }

    /**
     * Find players by ids
     * @param ids Players ids
     */
    public playersFindByIds(ids: string[]): Observable<PlayerEntry[]> {
        const customerId = this.authService.getCustomerId();
        const query = new DataQuery({
            filters: [
                new DataFilter({
                    field: 'id',
                    filter: { type: 'id', in: ids },
                }),
            ]
        });
        return this.post(ApiUrls.playersFind, { customerId, query });
    }

    /**
     * Find player by id
     * @param id Player id
     */
    public playersFindById(id: string): Observable<PlayerEntry> {
        const customerId = this.authService.getCustomerId();
        const query = {
            filters: [
                {
                    field: 'id',
                    filter: { type: 'id', is: id },
                },
            ],
        };

        return this.post(ApiUrls.playersFind, { customerId, query })
            .pipe(map(players => players[0]));
    }

    public playersGroups(): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersGroups, { customerId });
    }

    public playersTags(): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersTags, { customerId });
    }

    public playersRead(id: string): Observable<Player> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersRead, { customerId, id });
    }

    public playersFind(query: DataQuery): Observable<PlayerEntry[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersFind, { customerId, query });
    }

    public playersFindByFilter(filter: PlayerFilter): Observable<PlayerEntry[]> {
        const customerId = this.authService.getCustomerId();

        const query = new DataQuery({
            slice: new DataSlice({ skip: filter.skip, limit: filter.limit }),
            sort: new DataSort({field: filter.field, desc: filter.desc }),
            filters: []
        });

        if (filter.search) {
            query.filters.push({ field: null, filter: { type: 'text', search: filter.search } });
        }

        if (filter.targets?.length) {
            switch (filter.targetType) {
                case CampaignTargetType.groups:
                    query.filters.push(new DataFilter({ field: 'group', filter: { type: 'string', in: filter.targets } }));
                    break;
                case CampaignTargetType.locations:
                    query.filters.push(new DataFilter({ field: 'locationId', filter: { type: 'id', in: filter.targets } }));
                    break;
                case CampaignTargetType.tags:
                    query.filters.push(new DataFilter({ field: 'tags', filter: { type: 'string', in: filter.targets } }));
                    break;
            }
        }

        if (filter.locationId) {
            query.filters.push({ field: 'locationId', filter: { type: 'id', is: filter.locationId } });
        }

        if (filter.group) {
            query.filters.push({ field: 'group', filter: { type: 'string', is: filter.group } });
        }

        return this.post(ApiUrls.playersFind, { customerId, query });
    }

    public playersFindInLocation(locationId: string): Observable<PlayerEntry[]> {
        const customerId = this.authService.getCustomerId();
        const query = {
            filters: [
                {
                    field: 'locationId',
                    filter: { type: 'id', is: locationId },
                },
            ],
        };
        return this.post(ApiUrls.playersFind, { customerId, query });
    }

    public playersFindNotInLocation(locationId: string): Observable<PlayerEntry[]> {
        const customerId = this.authService.getCustomerId();

        const query = {
            filters: [
                {
                    field: 'locationId',
                    filter: { type: 'id', ne: locationId },
                },
            ],
        };

        return this.post(ApiUrls.playersFind, { customerId, query });
    }

    public playersDelete(ids: string[]): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersDelete, { customerId, ids });
    }

    public playersCreate(request: PlayerCreateRequest): Observable<Player> {
        request.player.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersCreate, request);
    }

    public playersUpdate(player: Player): Observable<Player> {
        player.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersUpdate, player);
    }

    public playersUpdateLocation(request: UpdateEntitiesLocationRequest): Observable<string[]> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersUpdateLocation, request);
    }

    public playersCache(id: string) {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersCache, { customerId, id });
    }

    public playersLog(id: string, query: DataQuery): Observable<PlayerLog[]> {
        const customerId = this.authService.getCustomerId();
        const req = { customerId, id, ...query };
        return this.post(ApiUrls.playersLog, req);
    }

    public playersStatus(id: string): Observable<PlayerStatus> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersStatus, { customerId, id });
    }

    public playersReadRotation(id: string): Observable<any> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersReadRotation, { customerId, id });
    }

    public playersReadVolume(id: string): Observable<any> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersReadVolume, { customerId, id });
    }

    public playersRotate(request: PlayerRotateRequest): Observable<any> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersRotate, request);
    }

    public playersRebind(params: PlayerRebindRequest): Observable<any> {
        params.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersRebind, params);
    }

    public playersControlMulti(request: PlayersControlMultiRequest): Observable<any> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersControlMulti, request);
    }

    public playersSchedulesFind(): Observable<PlayerSchedule[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersSchedulesFind, { customerId });
    }

    public playersSchedulesDelete(request: DeleteRequest): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersSchedulesDelete, { ...request, customerId });
    }

    public playersSchedulesUpdate(params: PlayerSchedule): Observable<PlayerSchedule> {
        params.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersSchedulesUpdate, params);
    }

    public playersSchedulesCreate(params: any): Observable<PlayerSchedule> {
        params.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersSchedulesCreate, params);
    }

    public playersReadTree(request: PlayersTreeRequest): Observable<TargetExtGroup[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersReadTree, { customerId, ...request });
    }

    public playersStatistics(request: { id: string, slice: DataSlice }): Observable<any> {
        const customerId = this.authService.getCustomerId();
        const payload = { customerId, ...request };
        return this.post(ApiUrls.playersStatistics, payload);
    }

    public playersUpdateProperty(request: UpdateEntitiesPropertyRequest): Observable<any> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.playersUpdateProperty, request);
    }
}
