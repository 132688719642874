import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules, Routes } from '@angular/router';
import { RoutingUrls } from '@config/routing-urls';

import { AuthGuard } from '@core/auth';

const routes: Routes = [
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'campaigns',
        canActivate: [AuthGuard],
        loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule)

    },
    {
        path: 'automation',
        canActivate: [AuthGuard],
        loadChildren: () => import('./automation/automation.module').then(m => m.AutomationModule)
    },
    {
        path: 'floors',
        canActivate: [AuthGuard],
        loadChildren: () => import('./floor-mapping/floor-mapping.module').then(m => m.FloorMappingModule)
    },
    {
        path: RoutingUrls.campaignEditPage,
        canActivate: [AuthGuard],
        loadChildren: () => import('./campaign-editor/campaign-editor.module').then(m => m.CampaignEditorModule)
    },
    {
        path: 'content',
        canActivate: [AuthGuard],
        loadChildren: () => import('./content-manager/content-manager.module').then(m => m.ContentManagerModule)
    },
    {
        path: 'settings/locations',
        canActivate: [AuthGuard],
        loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule)
    },
    {
        path: 'settings/tvboxes',
        canActivate: [AuthGuard],
        loadChildren: () => import('./players/players.module').then(m => m.PlayersModule)
    },
    {
        path: 'settings/sensors',
        canActivate: [AuthGuard],
        loadChildren: () => import('./sensors/sensors.module').then(m => m.SensorsModule)
    },
    {
        path: 'settings/accesspoints',
        canActivate: [AuthGuard],
        loadChildren: () => import('./access-points/access-points.module').then(m => m.AccessPointsModule)
    },
    {
        path: 'trash',
        canActivate: [AuthGuard],
        loadChildren: () => import('./trash/trash.module').then(m => m.TrashModule)
    },
    {
        path: 'profiles',
        canActivate: [AuthGuard],
        loadChildren: () => import('./profiles/profiles.module').then(m => m.ProfilesModule)
    },
    {
        path: 'calendar',
        canActivate: [AuthGuard],
        loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
    },
    {
        path: 'editor',
        canActivate: [AuthGuard],
        loadChildren: () => import('./editor/editor.module').then(m => m.EditorModule)
    },
    { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
