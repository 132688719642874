import { ChangeDetectionStrategy, Component, Input, EventEmitter, Output } from '@angular/core';

import { UploadState, UploadStatus } from '../shared';


@Component({
    selector: 'upload-file-state',
    templateUrl: './upload-file-state.component.html',
    styleUrls: ['./upload-file-state.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadFileStateComponent {

    @Input() fileState: UploadState;

    @Output() cancel = new EventEmitter();

    @Output() pause = new EventEmitter();

    @Output() play = new EventEmitter();

    UploadStatus = UploadStatus;
}
