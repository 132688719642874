<div class="page-content">

    <page-actions *ngIf="showPageActions"
        [showCustomButton]="showCustomButton"
        [disabledCustomButton]="disabledCustomButton"
        (addItem)="onAddItem()">
        <ng-content select="[actions]"></ng-content>
    </page-actions>

    <ng-container *ngIf="!showLeftSidebar && !showRightSidebar; else content">
        <div class="page-content__wrapper"
             [ngClass]="{ '_actions': showPageActions }">
            <ng-container *ngTemplateOutlet="selectOutlet"></ng-container>
        </div>
    </ng-container>

    <ng-template #content>
        <mat-sidenav-container class="page-content__wrapper"
                               [ngClass]="{ '_actions': showPageActions }"
                               [hasBackdrop]="false"
                               autosize>
            <mat-sidenav *ngIf="showLeftSidebar"
                         class="page-content__sidebar"
                         [ngClass]="{ '-collapsed': !openedLeftSidebar }"
                         [mode]="(openedLeftSidebar) ? 'side' : 'over'"
                         [@onChangeSideNavLeft]="{ value: (openedLeftSidebar || visibleLeftSidebar) ? 'open': 'close', params: { leftWidth: leftWidth } }"
                         opened>
                <div class="page-content__sidebar-wrapper">
                    <div class="page-content__sidebar-content" [@onChangeSideNavContent]="(openedLeftSidebar || visibleLeftSidebar)">
                        <ng-content select="[sidebar-left]"></ng-content>
                    </div>
                </div>

                <button mat-mini-fab class="page-content__button" color="" (click)="onToggleLeftSidebar()">
                    <mat-icon *ngIf="openedLeftSidebar">keyboard_arrow_left</mat-icon>
                    <mat-icon *ngIf="!openedLeftSidebar">menu</mat-icon>
                </button>
            </mat-sidenav>

            <mat-sidenav-content class="page-content__content"
                                 [@onChangeMainContentLeft]="{ value: showLeftSidebar ? (openedLeftSidebar ? 'open': 'close') : null, params: { leftWidth: leftWidth } }"
                                 [@onChangeMainContentRight]="{ value: showRightSidebar ? (openedRightSidebar ? 'open': 'close') : null, params: { rightWidth: rightWidth } }">
                <ng-container *ngTemplateOutlet="selectOutlet"></ng-container>
            </mat-sidenav-content>

            <mat-sidenav *ngIf="showRightSidebar"
                         class="page-content__sidebar"
                         position="end"
                         [ngClass]="{ '-collapsed': !openedRightSidebar, '-disabled': disabledRightSidebar }"
                         [mode]="(openedRightSidebar) ? 'side' : 'over'"
                         [@onChangeSideNavRight]="{ value: (openedRightSidebar || visibleRightSidebar) ? 'open': 'close', params: { rightWidth: rightWidth }  }"
                         opened>
                <div class="page-content__sidebar-wrapper">
                    <div class="page-content__sidebar-content" [@onChangeSideNavContent]="(openedRightSidebar || visibleRightSidebar)">
                        <ng-content select="[sidebar-right]"></ng-content>
                    </div>
                </div>

                <button mat-mini-fab
                        class="page-content__button -right"
                        color=""
                        (click)="onToggleRightSidebar()">
                    <mat-icon *ngIf="openedRightSidebar">keyboard_arrow_right</mat-icon>
                    <mat-icon *ngIf="!openedRightSidebar">menu</mat-icon>
                </button>
            </mat-sidenav>
        </mat-sidenav-container>
    </ng-template>

    <ng-template #selectOutlet>
        <ng-content select="[content]"></ng-content>
    </ng-template>
</div>
