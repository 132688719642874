import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiService } from '@core/data/services';
import { AuthService } from '@core/auth';
import { ApiUrls } from '@config/api-urls';
import { Sensor, SensorEntry, CounterLineValues, LocationCountersRequest } from '@domain/sensors';
import { DataQuery, DataFilter } from '@domain/query';
import { UpdateEntitiesLocationRequest } from '@domain/locations';
import { EnableRequest } from '@domain/models';


export class SensorsApi extends ApiService {

    constructor(public http: HttpClient, protected auth: AuthService) {
        super(http, auth);
    }

    /**
     * Get list of sensors groups
     */
    public sensorsGroups(): Observable<string[]> {
        return this.post(ApiUrls.sensorsGroups, { customerId: this.authService.getCustomerId() });
    }

    /**
     * Read sensor by id
     * @param id Sensor id
     */
    public sensorsRead(id: string): Observable<Sensor> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.sensorsRead, { customerId, id });
    }

    /**
     * Update a sensor entry
     * @param sensor Sensor to update
     */
    public sensorsUpdate(sensor: Sensor): Observable<Sensor> {
        sensor.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.sensorsUpdate, sensor);
    }

    /**
     * Create a new sensor entry
     * @param sensor Sensor to create
     */
    public sensorsCreate(sensor: Sensor): Observable<Sensor> {
        sensor.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.sensorsCreate, sensor);
    }

    /**
     * Delete sensors by ids
     * @param ids Sensors ids to remove
     */
    public sensorsDelete(ids: string[]): Observable<string[]> {
        return this.post(ApiUrls.sensorsDelete, { customerId: this.authService.getCustomerId(), ids });
    }

    /**
     * Find sensors by specified query
     * @param query Query to find
     */
    public sensorsFind(query: DataQuery): Observable<SensorEntry[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.sensorsFind, { customerId, query });
    }

    /**
     * Find sensors which are in specified location 
     * @param locationId Location Id
     */
    public sensorsFindInLocation(locationId: string): Observable<SensorEntry[]> {
        const customerId = this.authService.getCustomerId();
        const query = {
            filters: [
                {
                    field: 'locationId',
                    filter: { type: 'id', is: locationId },
                },
            ],
        };
        return this.post(ApiUrls.sensorsFind, { customerId, query });
    }

    /**
     * Find sensors which are not in specified location 
     * @param locationId Location Id
     */
    public sensorsFindNotInLocation(locationId: string): Observable<SensorEntry[]> {
        const customerId = this.authService.getCustomerId();

        const query = {
            filters: [
                {
                    field: 'locationId',
                    filter: { type: 'id', ne: locationId },
                },
            ],
        };

        return this.post(ApiUrls.sensorsFind, { customerId, query });
    }

    /**
     * Find sensors by ids
     * @param ids Sensors ids
     */
    public sensorsFindByIds(ids: string[]): Observable<SensorEntry[]> {
        const customerId = this.authService.getCustomerId();
        const query = new DataQuery({
            filters: [
                new DataFilter({
                    field: 'id',
                    filter: { type: 'id', in: ids },
                }),
            ]
        });
        return this.post(ApiUrls.sensorsFind, { customerId, query });
    }

    /**
     * Enable or disable target sensors
     * @param params 
     */
    public sensorsEnable(params: EnableRequest): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.sensorsEnable, { customerId, ...params });
    }

    /**
     * Gets list of sensors tags.
     */
    public sensorsTags(): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.sensorsTags, { customerId });
    }

    /**
     * Gets sensors locations counters.
     * @param request
     */
    public sensorsLocationCounters(request: LocationCountersRequest): Observable<Record<string, CounterLineValues>> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.sensorsLocationCounters, request);
    }

    /**
     * Updates sensors location.
     * @param request
     */
    public sensorsUpdateLocation(request: UpdateEntitiesLocationRequest): Observable<string[]> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.sensorsUpdateLocation, request);
    }
}
