import { Inject, Injectable } from '@angular/core';
import { Router, Event, NavigationError } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { filter, catchError } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { ErrorEntry, ErrorPayload, Severity } from './errors.model';
import { ErrorsOptions, IErrorsOptions } from './errors-options.model';


@Injectable()
export class ErrorsService {

    constructor(private router: Router, @Inject(ErrorsOptions) private options: IErrorsOptions, public http: HttpClient) {
        options.logRoutingErrors && this.subscribeOnNavigationError();
    }

    public log(error: Partial<ErrorPayload>, severity: Severity): Observable<any> {
        const entry = new ErrorEntry({
            severity,
            source: this.options.source,
            payload: new ErrorPayload({
                ...error,
                location: document.location.toString(),
                userAgent: navigator.userAgent,
            })
        });
        return this.postError(entry);
    }

    public logError(error: Partial<ErrorPayload>): Observable<any> {
        return this.log(error, Severity.ERROR);
    }

    private postError(error: ErrorEntry): Observable<any> {
        return this.http.post(environment.incidentUrl, error, { observe: 'response' })
            .pipe(catchError(() => of([])));
    }

    private subscribeOnNavigationError(): void {
        this.router
            .events
            .pipe(
                filter((event: Event) => event instanceof NavigationError)
            )
            .subscribe((event: NavigationError) => this.logError({ message: event.toString(), name: 'NavigationError' }));
    }

}
