import { BaseEntry } from '../base/base-entry.model';

export enum SensorZoneCountMode {
    counter = 'counter',
    locationEntry = 'location-entry',
    facilityEntry = 'facility-entry',
    facilityToFacility = 'facility-to-facility'
}

export enum SensorZoneType {
    line = 'line',
    area = 'area',
    person = 'person'
}

export const SensorZoneTypes = {
    counter: [SensorZoneType.line, SensorZoneType.area],
    person: [SensorZoneType.person]
};

export const SensorZoneCountModes = {
    counter: [SensorZoneCountMode.counter, SensorZoneCountMode.locationEntry, SensorZoneCountMode.facilityEntry, SensorZoneCountMode.facilityToFacility],
    person: []
};

export class SensorZone extends BaseEntry<SensorZone> {
    public id: string;
    public version: number;
    public customerId: string;
    public sensorId: string;
    public enabled: boolean;
    public hardwareId: string;
    public name: string;
    public type: SensorZoneType;
    public countMode: SensorZoneCountMode;
    public facility: number;
}
