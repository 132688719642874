import { Injectable, NgZone, OnDestroy, Inject } from '@angular/core';

import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { UploadStatus } from './shared/upload-status.enum';

import { pick } from './shared/utils';
import { UPLOADER_CONFIG } from './file-uploader.config';
import { FileEntry, UploadOptions, UploadState, UploadEvent } from './shared';
import { UploadTotal } from './shared/upload-total.model';
import { Tus } from './shared/tus';
import { UploadAction } from './shared/upload-control-event.model';
import { Uploader } from './shared/uploader';


interface DefaultOptions {
    concurrency: number;

    stateChange: (evt: Uploader) => void;
}


@Injectable()
export class UploadService implements OnDestroy {

    /** Upload status events */
    get events(): Observable<UploadState> {
        return this.eventsStream.asObservable();
    }

    get uploadTotal(): Observable<UploadTotal> {
        return this.uploadTotalStream.asObservable().pipe(startWith(this.total));
    }

    get complete(): Observable<Uploader> {
        return this.completeStream.asObservable();
    }

    public queue: Uploader[] = [];

    public total = new UploadTotal({ count: 0, progress: 0, size: 0, uploaded: 0 });

    public options: UploadOptions & DefaultOptions = {
        concurrency: 3,
        stateChange: (evt: Uploader) => {
            setTimeout(() =>
                this.ngZone.run(() => {

                    this.eventsStream.next(evt);
                    this.uploadTotalStream.next(this.updateTotal());

                    if (evt.status === UploadStatus.complete) {
                        this.completeStream.next(evt);
                    }
                })
            );
        },
        uploaderClass: Tus
    };

    private readonly eventsStream: Subject<any> = new Subject<any>();

    private readonly completeStream: Subject<Uploader> = new Subject<Uploader>();

    private readonly uploadTotalStream: Subject<UploadTotal> = new Subject<UploadTotal>();

    private subscriptions: Subscription[] = [];

    constructor(private ngZone: NgZone, @Inject(UPLOADER_CONFIG) options: UploadOptions = {}) {
        this.options = { ...this.options, ...options };
        this.subscribeOnChangeEvents();
        this.subscribeOnOffline();
        this.subscribeOnOnline();
    }

    public ngOnDestroy(): void {
        this.disconnect();
        this.subscriptions.forEach(sub => sub && sub.unsubscribe());
    }

    /**
     * Initializes service
     * @param options Options
     * @returns Observable that emits a new value on progress or status changes
     */
    public init(options: UploadOptions = {}): Observable<UploadState> {
        this.options = { ...this.options, ...options };
        return this.events;
    }

    /**
     * Initializes service
     * @param options Options
     * @returns Observable that emits the current array of upload states.
     */
    public connect(options?: UploadOptions): Observable<UploadEvent[]> {
        return this.init(options).pipe(
            startWith(0),
            map(() => this.queue.map(uploader => pick(uploader, UploadState.stateKeys)))
        );
    }

    /** Terminates all uploads and clears the queue. */
    public disconnect(): void {

        this.queue = [];
    }

    /** Creates new Uploader and adds to the queue. */
    public addFiles(files: FileEntry[] = [], options: UploadOptions = {}): void {
        const instanceOptions = { ...this.options, ...options };
        files.forEach(file => this.addUploader(file, instanceOptions));
        this.autoUploadFiles();
    }

    /** Creates new uploader instance and push to queue. */
    private addUploader(entry: FileEntry, options: UploadOptions): void {
        const uploader = new (options.uploaderClass)(entry, options);
        this.queue.push(uploader);
        uploader.status = UploadStatus.added;
    }

    /** Starts auto uploading files. */
    private autoUploadFiles(): void {
        this.queue
            .filter(({ status }) => status === UploadStatus.added)
            .forEach(uploader => (uploader.status = UploadStatus.queue));

        this.processQueue();
    }

    /* Process to start new uploading. */
    private processQueue(): void {
        this.queue = this.queue.filter(({ status }) => status !== UploadStatus.cancelled);

        this.queue
            .filter(({ status }) => status === UploadStatus.queue)
            .slice(0, Math.max(this.options.concurrency - this.runningProcess(), 0))
            .forEach(uploader => uploader.upload());
    }

    /** Returns number of active uploads. */
    private runningProcess(): number {
        return this.queue.filter(({ status }) => status === UploadStatus.uploading).length;
    }

    public control(evt: { uploadId?: string, action: UploadAction }): void {
        const target = evt.uploadId
            ? this.queue.filter(({ uploadId }) => uploadId === evt.uploadId)
            : this.queue;

        target.forEach(uploader => uploader.configure(evt));
    }

    private updateTotal(): UploadTotal {
        const size = this.queue.reduce((sum: number, u: Uploader) => sum + u.size, 0);
        const progress = Math.round(+(this.queue.reduce((sum: number, u: Uploader) => sum + u.uploaded, 0) / size) * 100);
        const uploaded = this.queue.filter(u => u.status === UploadStatus.complete).length;
        this.total = new UploadTotal({ count: this.queue.length, progress, size, uploaded });

        return this.total;
    }

    /** Subscribes on uploading events change. */
    private subscribeOnChangeEvents(): void {
        const subscription = this.events.subscribe(({ status }) => {
            if (status !== UploadStatus.uploading && status !== UploadStatus.added) {
                this.ngZone.runOutsideAngular(() => this.processQueue());
            }
        });

        this.subscriptions.push(subscription);
    }

    private subscribeOnOnline(): void {
        const subscription = fromEvent(window, 'online').subscribe(() => this.control({ action: 'upload' }));
        this.subscriptions.push(subscription);
    }

    private subscribeOnOffline(): void {
        const subscription = fromEvent(window, 'offline').subscribe(() => this.control({ action: 'pause' }));
        this.subscriptions.push(subscription);
    }
}
