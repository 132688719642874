import { BaseEntry } from '../base/base-entry.model';

export class UIConfig extends BaseEntry<UIConfig> {
    campaignsModifiable: boolean;
    campaignsVisible: boolean;
    childCustomersModifiable: boolean;
    childCustomersVisible: boolean;
    groupsAvailable: boolean;
    locationsModifiable: boolean;
    locationsVisible: boolean;
    playersAvailable: boolean;
    playersCaptureAvailable: boolean;
    playersControlAvailable: boolean;
    playersModifiable: boolean;
    playersVisible: boolean;
    profilesModifiable: boolean;
    profilesVisible: boolean;
    resourcesModifiable: boolean;
    resourcesVisible: boolean;
    sensorsAvailable: boolean;
    sensorsModifiable: boolean;
    sensorsVisible: boolean;
    vouchersModifiable: boolean;
    vouchersVisible: boolean;
    wapsAvailable: boolean;
    wapsModifiable: boolean;
    wapsVisible: boolean;
}
