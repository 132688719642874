import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiUrls } from '@config/api-urls';
import { AuthService } from '@core/auth';
import { ApiService } from '@core/data/services';
import { DataQuery, DataFilter } from '@domain/query';
import { ActionEntry, Action } from '@domain/actions';
import { EnableRequest } from '@domain/models';


export class ActionsApi extends ApiService {

    constructor(public http: HttpClient, protected authService: AuthService) {
        super(http, authService);
    }

    /**
     * Count actions by specified query
     * @param query Query object
     */
    public actionsCount(query: DataQuery): Observable<number> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.actionsCount, { customerId, query });
    }

    /**
     * List actions by specified query
     * @param query Query object
     */
    public actionsFind(query: DataQuery): Observable<ActionEntry[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.actionsFind, { customerId, query });
    }

    /**
     * List of action groups by specified customer
     */
    public actionsGroups(): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.actionsGroups, { customerId });
    }

    /**
     * Create a new action
     * @param action Action object
     */
    public actionsCreate(action: Action): Observable<Action> {
        action.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.actionsCreate, action);
    }

    /**
     * Update action information
     * @param action Action object
     */
    public actionsUpdate(action: Action): Observable<Action> {
        return this.post(ApiUrls.actionsUpdate, action);
    }

    /**
     * Enable or disable target actions
     * @param params
     */
    public actionsEnable(params: EnableRequest): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.actionsEnable, { customerId, ...params });
    }

    /**
     * Find actions by ids
     * @param ids Actions ids
     */
    public actionsFindByIds(ids: string[]): Observable<ActionEntry[]> {
        const customerId = this.authService.getCustomerId();
        const query = new DataQuery({
            filters: [
                new DataFilter({
                    field: 'id',
                    filter: { type: 'id', in: ids },
                }),
            ]
        });
        return this.post(ApiUrls.actionsFind, { customerId, query });
    }

    /**
     * Read action information by id
     * @param id Action Id
     */
    public actionsRead(id: string): Observable<Action> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.actionsRead, { id, customerId });
    }

    /**
     * Delete actions by ids
     * @param ids Actions ids to remove
     */
    public actionsDelete(ids: string[]): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.actionsDelete, { ids, customerId });
    }
}
