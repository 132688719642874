import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiService } from '@core/data/services';
import { AuthService } from '@core/auth';
import { ApiUrls } from '@config/api-urls';
import { SensorZone, ZonesDeleteRequest } from '@domain/sensors';
import { DataQuery } from '@domain/query';


export class SensorsZonesApi extends ApiService {

    constructor(public http: HttpClient, protected auth: AuthService) {
        super(http, auth);
    }

    public sensorsZoneCreate(zone: SensorZone): Observable<SensorZone> {
        return this.post(ApiUrls.sensorsZoneCreate, { customerId: this.authService.getCustomerId(), ...zone });
    }

    public sensorsZoneUpdate(zone: SensorZone): Observable<SensorZone> {
        return this.post(ApiUrls.sensorsZoneUpdate, { customerId: this.authService.getCustomerId(), ...zone });
    }

    public sensorsZonesDelete(params: ZonesDeleteRequest): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.sensorsZonesDelete, { customerId, ...params });
    }

    public sensorsZonesGet(id: string): Observable<SensorZone[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.sensorsZonesGet, { customerId, id });
    }

    public sensorsZonesAll(): Observable<SensorZone[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.sensorsZonesAll, { customerId });
    }

    public sensorsZonesFind(query: DataQuery): Observable<SensorZone[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.sensorsZonesAll, { customerId, query });
    }
}
