import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BreadcrumbItem } from '@domain/models';

@Component({
    selector: 'page-breadcrumbs',
    templateUrl: './page-breadcrumbs.component.html',
    styleUrls: ['./page-breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageBreadcrumbsComponent {

    @Input() breadcrumbs: BreadcrumbItem[] = [];

    @Output() select: EventEmitter<BreadcrumbItem> = new EventEmitter();

    public onSelect(item: BreadcrumbItem) {
        if (item.action) {
            item.action();
        }

        this.select.emit(item);
    }
}
