import { ErrorHandler, Injectable, Injector, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { NotificationMessage, NotificationsService } from '@core/notifications';

import { ErrorsService } from './errors.service';
import { ErrorPayload } from './errors.model';
import { ErrorsOptions, IErrorsOptions } from './errors-options.model';


@Injectable()
export class ErrorsHandler implements ErrorHandler {

    constructor(private injector: Injector, @Inject(ErrorsOptions) private options: IErrorsOptions) { }

    public handleError(error: Error | HttpErrorResponse): void {
        if (this.isClientError(error)) {
            this.handleClientError(error);
        } else {
            this.handleServerError(error['rejection'] || error);
        }
    }

    private handleClientError(error: any): void {
        if (navigator.onLine && this.options.logClientErrors) {
            const errorsService = this.injector.get(ErrorsService);
            const errorInfo = this.getClientErrorPayload(error);
            errorsService.logError(errorInfo).subscribe();
        }
    }

    private handleServerError(error: HttpErrorResponse | any): void {
        const notifier = this.injector.get(NotificationsService);
        const errorsService = this.injector.get(ErrorsService);

        const errorInfo = error instanceof HttpErrorResponse
            ? this.getServerErrorPayload(error)
            : this.getErrorByCode(error['code']);

        if (navigator.onLine && this.options.logServerErrors) {
            errorsService.logError(errorInfo).subscribe();
        }

        this.shouldNotify(error['code']) && notifier.show(NotificationMessage.error(errorInfo.name, errorInfo.message));
    }

    private isClientError(error: Error): boolean {
        return !!(error['message'] && error['stack'] && !error['rejection']);
    }

    private getClientErrorPayload(error: any): ErrorPayload {
        return new ErrorPayload({ 
            message: error['message'] || null, 
            code: error['code'] || null, 
            name: error['name'] || null,
            stack: error['stack'] || null,
        });
    }

    private getServerErrorPayload(error: HttpErrorResponse): ErrorPayload {
        return new ErrorPayload({
            status: error['status'] || null,
            message: error['message'] || null,
            name: error['name'] || null,
        });
    }


    /*
    responses' map from BE
    -32600, "invalid request", null);
    -32601, "method not found", null);
    -32602, "method parameters invalid", null);
    -32603, "internal error", null);
    -32000, "error not handled", null);
    -32001, "unauthorized", null);
    -32002, "forbidden", null);
    -32005, "concurrent modification error", null);
    -32008, "duplicate key", null);
    */
    private getErrorByCode(code: number): ErrorPayload {
        switch (code) {
            case -32700:
                return new ErrorPayload({ status: 500, code, message: 'Parse error.' });
            case -32600:
                return new ErrorPayload({ status: 400, code, message: 'Invalid Request.' });
            case -32601:
                return new ErrorPayload({ status: 404, code, message: 'Method not found.' });
            case -32602:
                return new ErrorPayload({ status: 500, code, message: 'Invalid params.' });
            case -32603:
                return new ErrorPayload({ status: 500, code, message: 'Internal error.' });
            case -32008:
                return new ErrorPayload({ status: 500, code, message: 'Duplicate identifier.' });
            default:
                return new ErrorPayload({ status: 500, code, message: 'Server error.' });
        }
    }

    private shouldNotify(code: number): boolean {
        const skipCodes = [-32005];
        return !skipCodes.includes(code);
    }
}
