import { BaseEntry } from '../base/base-entry.model';
import { ActionClauseComp } from './action-clause-comp.model';
import { ActionSourceComp } from './action-source-comp.model';
import { ActionTargetComp } from './action-target-comp.model';

export class Action extends BaseEntry<Action> {
    public clause: ActionClauseComp;
    public customerId: string;
    public deleted: boolean;
    public enabled: boolean;
    public group: string;
    public id: string;
    public name: string;
    public notes: string;
    public source: ActionSourceComp;
    public target: ActionTargetComp;
    public version: number;
}
