<div class="toolbar-buttons" (resizableContent)="onResize($event)">
    <ng-content></ng-content>
</div>

<button mat-icon-button [matMenuTriggerFor]="entryMenu" *ngIf="showMenu">
    <mat-icon>more_vert</mat-icon>
</button>

<button mat-icon-button aria-label="Close toolbar" (click)="close.emit()" *ngIf="type === 'slide'">
    <mat-icon>close</mat-icon>
</button>

<mat-menu #entryMenu>
    <ng-content select="[menuItems]"></ng-content>
</mat-menu>

<div class="toolbar-title" *ngIf="title" #titleEl>
    <p class="title mat-body-strong ellipsis">{{ title }}</p>
    <p class="sub-title mat-caption ellipsis" *ngIf="subTitle">{{ subTitle }}</p>
</div>
