import { BaseEntry } from '../base/base-entry.model';
import { CampaignSchedule2Entry } from './campaign-schedule2-entry.model';

export class CampaignSchedule extends BaseEntry<CampaignSchedule> {
    public id: string;
    public name: string;
    public notes: string;
    public version: number;
    public schedules2: CampaignSchedule2Entry[];
}
