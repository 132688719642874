import { BreadcrumbItem } from '@domain/models';
import { Folder, ResourceFilter } from '@domain/resources';

export function filterFolders(folders: Folder[], filter: ResourceFilter): Folder[] {
    let filteredFolders = folders.filter(x => x.id && x.folderId === filter.folderId);

    if (filter.search) {
        filteredFolders = filteredFolders.filter(x => x.name?.includes(filter.search));
    }

    if (filter.field === 'name') {
        filteredFolders = filteredFolders.sort((a, b) => filter.desc ? b.name?.localeCompare(a.name) : a.name?.localeCompare(b.name));
    }

    return filteredFolders;
}

export function sortFoldersByName(folders: Folder[]): Folder[] {
    return (folders || []).sort((a, b) => {
        if (a.id && b.id) {
            return a.name?.localeCompare(b.name);
        }

        return a.id ? 1 : 0;
    });
}

export function buildBreadcrumbs(folders: Folder[], folderId: string): BreadcrumbItem[] {
    const breadcrumbs: BreadcrumbItem[] = [];

    while (folderId) {
        const folder = folders.find(x => x.id === folderId);

        if (!folder) {
            break;
        }

        breadcrumbs.push(new BreadcrumbItem({ name: folder.name, id: folder.id }));
        folderId = folder.folderId;
    }

    breadcrumbs.push(new BreadcrumbItem({ name: 'Home', id: null }));

    return breadcrumbs.reverse();
}
