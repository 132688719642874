<form [formGroup]="profileForm">

    <image-upload
        [formControl]="profileForm.get('avatarId')"
        [width]="150"
        [height]="150"
        [config]="uploaderConfig">
    </image-upload>

    <section>
        <h2 class="mat-h2">{{ 'PROFILES.MY_PROFILE.USER_INFORMATION' | translate }}</h2>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'PROFILES.MY_PROFILE.FIELDS.FIRST_NAME' | translate }}</mat-label>
            <input matInput formControlName="firstName">
        </mat-form-field>
    
        <mat-form-field appearance="outline">
            <mat-label>{{ 'PROFILES.MY_PROFILE.FIELDS.LAST_NAME' | translate }}</mat-label>
            <input matInput formControlName="lastName">
        </mat-form-field>
    
        <mat-form-field appearance="outline">
            <mat-label>{{ 'FIELDS.EMAIL' | translate }}</mat-label>
            <input matInput formControlName="email">
        </mat-form-field>
    
        <mat-form-field class="no-subscript-wrapper" appearance="outline">
            <mat-label>{{ 'PROFILES.MY_PROFILE.FIELDS.NOTES' | translate }}</mat-label>
            <textarea matInput formControlName="notes"></textarea>
        </mat-form-field>
    </section>

    <section>
        <h2 class="mat-h2">{{ 'TITLES.LANGUAGES' | translate }}</h2>

        <mat-radio-group aria-label="Select an language" [value]="selectedLanguage" (change)="onChangeLanguage($event.value)">
            <mat-radio-button [value]="Language.English">{{ 'TITLES.ENGLISH' | translate }}</mat-radio-button>
            <mat-radio-button [value]="Language.German">{{ 'TITLES.GERMAN' | translate }}</mat-radio-button>
        </mat-radio-group>
    </section>

    <section>
        <h2 class="mat-h2">{{ 'PROFILES.MY_PROFILE.COMPANY_INFORMATION' | translate }}</h2>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'PROFILES.MY_PROFILE.FIELDS.POSITION' | translate }}</mat-label>
            <input matInput formControlName="position">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'PROFILES.MY_PROFILE.FIELDS.ROLE' | translate }}</mat-label>
            <mat-select matInput formControlName="roleId" [disabled]="!isAdmin">
                <mat-option *ngFor="let role of roles" [value]="role.id">
                    {{ role.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'PROFILES.MY_PROFILE.FIELDS.GROUP' | translate }}</mat-label>
            <input matInput formControlName="group" [matAutocomplete]="autoGroups">
            <mat-autocomplete #autoGroups="matAutocomplete">
                <mat-option *ngFor="let group of groups" [value]="group">{{ group }}</mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </section>

    <button mat-flat-button color="accent" type="button" (click)="onSave()">
        {{ 'BUTTONS.SAVE' | translate }}
    </button>
</form>

<form [formGroup]="passwordForm">
    <section>
        <h2 class="mat-h2">{{ 'PROFILES.MY_PROFILE.MY_PASSWORD' | translate }}</h2>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'PROFILES.MY_PROFILE.FIELDS.OLD_PASSWORD' | translate }}</mat-label>
            <input matInput type="password" formControlName="oldPassword">
        </mat-form-field>
    
        <mat-form-field appearance="outline">
            <mat-label>{{ 'PROFILES.MY_PROFILE.FIELDS.NEW_PASSWORD' | translate }}</mat-label>
            <input matInput type="password" formControlName="newPassword">
        </mat-form-field>
    
        <mat-form-field appearance="outline">
            <mat-label>{{ 'PROFILES.MY_PROFILE.FIELDS.NEW_PASSWORD_CONFIRM' | translate }}</mat-label>
            <input matInput type="password" formControlName="newPasswordConfirm">
        </mat-form-field>
    
        <button mat-button color="accent" [disabled]="passwordForm.invalid" type="button" (click)="onChangePassword()">
            {{ 'PROFILES.MY_PROFILE.BUTTONS.CHANGE_PASSWORD' | translate }}
        </button>
    </section>
</form>
