import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiUrls } from '@config/api-urls';
import { AuthService } from '@core/auth';
import { ApiService } from '@core/data/services';


export class UploadsApi extends ApiService {

    constructor(public http: HttpClient, protected authService: AuthService) {
        super(http, authService);
    }

    /**
     * List of supported media types of resources
     */
    public uploadsMediaTypes(): Observable<string[]> {
        return this.post(ApiUrls.uploadsMediaTypes, {});
    }
}
