import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

declare var InstallTrigger: any;
declare var opr: any;
declare var safari: any;

class KnownClassNames {
    protected MAC = '-mac';
    protected OPERA = '-opera';
    protected FIREFOX = '-moz';
    protected SAFARI = '-safari';
    protected IE = '-ie';
    protected IE9 = '-ie9';
    protected IE10 = '-ie10';
    protected EDGE = '-edge';
    protected EDGE13 = '-edge-13';
    protected EDGE14 = '-edge-14';
    protected CHROME = '-chrome';
    protected BLINK = '-blink';
    protected YA = '-ya';
    protected IOS = '-ios';
    protected IPAD = '-ipad';
    protected IPHONE = '-iphone';
}

class KnownBrowsers {
    protected IS_MAC = navigator.appVersion.indexOf('Mac') !== -1;
    // Opera 8.0+
    protected IS_OPERA = (!!window['opr'] && !!opr.addons) ||
        !!window['opera'] ||
        navigator.userAgent.indexOf(' OPR/') >= 0;
    // Firefox 1.0+
    protected IS_FIREFOX = typeof InstallTrigger !== 'undefined';
    // At least Safari 3+ = "[object HTMLElementConstructor];
    // protected IS_SAFARI = false; Object.prototype.toString.call(window['HTMLElement']).indexOf('Constructor') > 0 ||
    //     /constructor/i.test(window['HTMLElement']) ||
    //     // @ts-ignore
    //     (function(p) {
    //         return p.toString() === '[object SafariRemoteNotification]';
    //     })(!window['safari'] || safari.pushNotification);
    // Internet Explorer 6-11
    protected IS_IE = /*@cc_on!@*/ false || !!document['documentMode'];
    // @ts-ignore
    protected IS_IE9 = Function('/*@cc_on return document.documentMode===9@*/')();
    // @ts-ignore
    protected IS_IE10 = Function('/*@cc_on return document.documentMode===10@*/')();
    // Edge 20+
    protected IS_EDGE14 = navigator.userAgent.indexOf(' Edge/14') >= 0;
    protected IS_EDGE13 = navigator.userAgent.indexOf(' Edge/13') >= 0;
    protected IS_EDGE = !this.IS_IE && !!window['StyleMedia'];
    // Chrome 1+
    protected IS_CHROME = !!window['chrome'] && !!window['chrome'].webstore;
    // Blink engine detection
    protected IS_BLINK = (this.IS_CHROME || this.IS_OPERA) && !!window['CSS'];
    protected IS_YA = navigator.userAgent.indexOf(' YaBrowser/') >= 0;

    protected IS_IPAD = window.navigator.userAgent.toLowerCase().indexOf('ipad') !== -1;
    protected IS_IPHONE = window.navigator.userAgent.toLowerCase().indexOf('windows') === -1 &&
        window.navigator.userAgent.toLowerCase().indexOf('iphone') !== -1;
}

@Injectable()
export class DetectBrowserService {
    private knownBrowsers = new KnownBrowsers();
    private knownClassNames = new KnownClassNames();

    constructor(@Inject(DOCUMENT) private document: Document) {}

    public detect() {
        const classNames: string[] = [];

        Object.keys(this.knownBrowsers).forEach(browser => {
            this.knownBrowsers[browser] && classNames.push(this.knownClassNames[browser.slice(3, browser.length)]);
        });

        this.addClassName(classNames);
    }

    private addClassName(classNames: string[]) {
        if (this.document.documentElement.classList) {
            this.document.documentElement.classList.add(...classNames);
        } else {
            this.document.documentElement.className += classNames.join(' ');
        }
    }
}
