<table aria-label="Hotkeys information table">
    <thead>
        <tr>
            <th scope="col">Description</th>
            <th scope="col">Hotkey</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let keys of hotkeys">
            <td>{{ keys[1].description }}</td>
            <td class="text-right">
                <kbd>{{ keys[1].key | hotkey }}</kbd>
            </td>
        </tr>
    </tbody>
</table>