import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class AuthService {
    
    public isLoggedIn(): boolean {
        if (Math.floor(Date.now() / 1000) >= this.getExpirationTimestamp()) {
            sessionStorage.removeItem(environment.userStorageKey);
        }

        return sessionStorage.getItem(environment.userStorageKey) !== null;
    }

    public parseAccessToken(): any {
        if (!location.hash) {
            return null;
        }

        const hash = location.hash.substring(1);
        const params = hash.split('&');

        const oauthResult: any = {};

        params.forEach(el => {
            const b = el.split('=');
            oauthResult[b[0]] = b[1];
        });

        if ('expires_in' in oauthResult) {
            oauthResult['_expires_at'] = Math.floor(Date.now() / 1000) + (oauthResult['expires_in'] - 60);
        }

        return oauthResult;
    }

    public startAuthentication(): void {
        window.location.href =
            environment.auth.authority +
            '?response_type=' +
            environment.auth.response_type +
            '&client_id=' +
            environment.auth.client_id +
            '&redirect_uri=' +
            window.location.href +
            '&scope=' +
            environment.auth.scope;
    }

    public logout(): void {
        sessionStorage.removeItem(environment.currentUser);
        sessionStorage.removeItem(environment.userStorageKey);
        sessionStorage.removeItem('content_last_folder');
        const loginUrl =
            environment.auth.authority +
            '?response_type=' +
            environment.auth.response_type +
            '&client_id=' +
            environment.auth.client_id +
            '&redirect_uri=' +
            environment.auth.redirect_uri +
            '&scope=' +
            environment.auth.scope;

        const logoutUrl = environment.auth.logout + '?redirect_uri=' + encodeURIComponent(loginUrl);

        window.location.href = logoutUrl;

        return null;
    }

    public getAuthToken(): string {
        try {
            return JSON.parse(sessionStorage.getItem(environment.userStorageKey))['access_token'];
        } catch (e) {
            return null;
        }
    }

    public getCustomerId(): string {
        try {
            return JSON.parse(sessionStorage.getItem(environment.userStorageKey))['customer_id'];
        } catch (e) {
            return null;
        }
    }

    public getCurrentUser() {
        return JSON.parse(sessionStorage.getItem(environment.currentUser));
    }

    public getWritePermission(item: string): boolean {
        return this.getCurrentUser().uiConfig[`${item}Modifiable`];
    }

    public getReadPermission(item: string): boolean {
        return this.getCurrentUser().uiConfig[`${item}Visible`];
    }

    public isAvailable(item: string): boolean {
        return this.getCurrentUser().uiConfig[`${item}Available`];
    }

    public getExpirationTimestamp(): number {
        try {
            const authArray = JSON.parse(sessionStorage.getItem(environment.userStorageKey));
            return +authArray['_expires_at'];
        } catch (e) {
            return 0;
        }
    }
}
