import { UploadStatus } from './upload-status.enum';

/** Read only upload stream events */
export type UploadEvent = Readonly<UploadState>;

/**
 * Upload state
 */
export class UploadState {

    static stateKeys: (keyof UploadState)[] = ['name', 'progress', 'uploadId', 'remaining', 'size', 'speed', 'status', 'extension', 'uploaded'];

    /** Original file name */
    public name: string;

    /** Original file extension */
    public extension: string;

    /** Progress percentage */
    public progress: number;

    /** Estimated remaining time */
    public remaining: number;

    /** File size in bytes */
    public size: number;

    /** Uploaded file size in bytes */
    public uploaded: number;

    /** Upload speed bytes/sec */
    public speed: number;

    /** Upload status */
    public status: UploadStatus;

    /** Upload operation id */
    public uploadId: string;
}
