import { NgModule } from '@angular/core';

import { directives } from './directives';
import { pipes } from './pipes';

@NgModule({
    declarations: [...directives, ...pipes],
    exports: [...directives, ...pipes],
    providers: [],
})
export class CommonToolsModule { }
