import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Location, TimeConfig } from '@domain/locations';
import { notEmptyArrayRequired } from '@domain/validators';

export class LocationsCreator {

    constructor(public fb: UntypedFormBuilder) { }

    public locationFormGroup(location: Location): UntypedFormGroup {
        const formGroup = this.fb.group({
            id: [location.id],
            version: [location.version],
            name: [location.name, [Validators.required]],
            group: [location.group],
            tags: [location.tags],
            hotspotId: [location.hotspotId],
            termsId: [location.termsId],
            timezone: [location.timezone, [Validators.required]],
            facilities: [location.facilities, [notEmptyArrayRequired]],
            address: this.fb.group({
                latitude: [location.address.latitude],
                longitude: [location.address.longitude],
                fullAddress: [location.address.fullAddress],
                cityName: [location.address.cityName],
                countryName: [location.address.countryName],
            }),
            sessionLimits: this.fb.group({
                duration: [location.sessionLimits ? location.sessionLimits.duration : null],
                transferred: [location.sessionLimits ? location.sessionLimits.transferred : null],
            }),
        });

        if (location.timeConfig) {
            formGroup.addControl('timeConfig', this.timeConfigFormGroup(location.timeConfig));
        }

        return formGroup;
    }

    public timeConfigFormGroup(timeConfig: TimeConfig): UntypedFormGroup {
        return this.fb.group({
            type: [timeConfig.type || 'NTP', [Validators.required]],
            options: this.fb.group({
                updateMinutes: [timeConfig.options?.updateMinutes ? timeConfig.options.updateMinutes / 60 : this.getDefaultUpdateMinutes(timeConfig.options)]
            }),
            servers: this.fb.array(timeConfig.servers.map(x => this.fb.control(x || null, [Validators.required]))),
        });
    }

    private getDefaultUpdateMinutes(options: Record<string, any>): number {
        if (options?.updateMinutes === undefined) {
            return 24;
        }

        return options?.updateMinutes || null;
    }
}
