import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SensorZoneType, SensorZoneCountMode } from '@domain/sensors';


@Pipe({ name: 'sensorZoneType' })
export class SensorZoneTypePipe implements PipeTransform {

    constructor(protected translate: TranslateService) {
    }

    transform(value: SensorZoneType, lang: string): string {
        switch (value) {
            case SensorZoneType.line:
                return this.translate.instant('SENSOR_ZONE_TYPE.LINE');
            case SensorZoneType.area:
                return this.translate.instant('SENSOR_ZONE_TYPE.AREA');
            case SensorZoneType.person:
                return this.translate.instant('SENSOR_ZONE_TYPE.PERSON');
            default:
                return null;
        }
    }
}

@Pipe({ name: 'sensorZoneMode' })
export class SensorZoneModePipe implements PipeTransform {

    constructor(protected translate: TranslateService) {
    }

    transform(value: SensorZoneCountMode, lang: string): string {
        switch (value) {
            case SensorZoneCountMode.counter:
                return this.translate.instant('SETTINGS.SENSOR_ZONE.COUNTER');
            case SensorZoneCountMode.facilityEntry:
                return this.translate.instant('SETTINGS.SENSOR_ZONE.FACILITY_ENTRY');
            case SensorZoneCountMode.facilityToFacility:
                return this.translate.instant('SETTINGS.SENSOR_ZONE.FACILITY_TO_FACILITY');
            case SensorZoneCountMode.locationEntry:
                return this.translate.instant('SETTINGS.SENSOR_ZONE.LOCATION_ENTRY');
            default:
                return null;
        }
    }
}
