import { Pipe, PipeTransform } from '@angular/core';
import { Helpers } from '@domain/utils/helpers';

@Pipe({ name: 'macAddress' })
export class MacAddressPipe implements PipeTransform {
    transform(value: number): string {
        if (!value) {
            return null;
        }

        return new Array(6)
            .join('00')
            .match(/../g)
            .concat(Helpers.toHex(value, 10).match(/.{1,2}/g))
            .reverse()
            .slice(0, 6)
            .reverse()
            .join(':');
    }
}
