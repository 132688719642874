import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiUrls } from '@config/api-urls';
import { ApiService } from '@core/data/services';
import { AuthService } from '@core/auth';
import { Location, LocationEntry, UpdateLocationsHotspotRequest, UpdateLocationsTermsRequest } from '@domain/locations';
import { DataQuery, DataFilter } from '@domain/query';
import { CityEntry, CityFindRequest } from '@domain/models';


export class LocationsApi extends ApiService {

    constructor(public http: HttpClient, protected auth: AuthService) {
        super(http, auth);
    }

    public locationsGroups(): Observable<string[]> {
        return this.post(ApiUrls.locationsGroups, { customerId: this.authService.getCustomerId() });
    }

    public locationsTags(): Observable<string[]> {
        return this.post(ApiUrls.locationsTags, { customerId: this.authService.getCustomerId() });
    }

    public locationsRead(id: string): Observable<Location> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.locationsRead, { customerId, id });
    }

    public locationsFind(query: DataQuery): Observable<LocationEntry[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.locationsFind, { customerId, query });
    }

    public locationsAll(): Observable<LocationEntry[]> {
        return this.post(ApiUrls.locationsFind, { customerId: this.authService.getCustomerId() });
    }

    public locationsUpdate(location: Location): Observable<Location> {
        location.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.locationsUpdate, location);
    }

    public locationsDelete(ids: string[]): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.locationsDelete, { customerId, ids });
    }

    public locationsCreate(location: Location): Observable<Location> {
        location.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.locationsCreate, location);
    }

    public locationsUpdateHotspot(hotspotId: string, assignToIds: string[], unassignFromIds: string[]): Observable<any[]> {
        const customerId = this.authService.getCustomerId();

        const requestParams = [
            {
                id: 1,
                method: ApiUrls.locationsUpdateHotspot,
                params: new UpdateLocationsHotspotRequest({ customerId, ids: assignToIds, hotspotId }),
            },
            {
                id: 2,
                method: ApiUrls.locationsUpdateHotspot,
                params: new UpdateLocationsHotspotRequest({ customerId, ids: unassignFromIds, hotspotId: null }),
            },
        ];
        return this.postBatch(requestParams);
    }

    public locationsUpdateTerms(termsId: string, assignToIds: string[], unassignFromIds: string[]): Observable<any[]> {
        const customerId = this.authService.getCustomerId();

        const requestParams = [
            {
                id: 1,
                method: ApiUrls.locationsUpdateTerms,
                params: new UpdateLocationsTermsRequest({ customerId, ids: assignToIds, termsId }),
            },
            {
                id: 2,
                method: ApiUrls.locationsUpdateTerms,
                params: new UpdateLocationsTermsRequest({ customerId, ids: unassignFromIds, termsId: null }),
            },
        ];
        return this.postBatch(requestParams);
    }

    public locationsFindCity(query: string): Observable<CityEntry[]> {
        return this.post(ApiUrls.locationsFindCity, new CityFindRequest({ query, field: 'gps' }));
    }

    public locationsFindAssigned(locationId: string): Observable<any[]> {
        const customerId = this.authService.getCustomerId();

        const query = new DataQuery({
            filters: [
                new DataFilter({
                    field: 'locationId',
                    filter: { type: 'id', is: locationId },
                }),
            ]
        });

        const requestParams = [
            { id: 1, method: ApiUrls.playersFind, params: { customerId, query } },
            { id: 2, method: ApiUrls.sensorsFind, params: { customerId, query } },
            { id: 3, method: ApiUrls.wapsFind, params: { customerId, query } },
        ];

        return this.postBatch(requestParams);
    }
}
