import { Editor } from './editor';
import { Campaign } from './campaign';
import { Assets } from './assets';
import { AccessPoint } from './accessPoint';
import { Sensors } from './sensors';
import { ui } from './ui';

export const Config = {
    editor: Editor,
    campaign: Campaign,
    assets: Assets,
    accessPoint: AccessPoint,
    sensors: Sensors,
    ui
};
