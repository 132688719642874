import { Folder } from '../resources/folder.model';
import { Resource, ResourceSubtype, ResourceType } from '../resources/resource.model';
import { PlaylistProperties } from '../resources/playlist-properties.model';
import { BaseEntry } from '../base/base-entry.model';

export class CampaignPlaylistEntry extends BaseEntry<CampaignPlaylistEntry> {
    public resourceId: string;
    public type: string;
    public subtype: string;
    public transition: string;
    public color: string;
    public duration: number;
    public continuous: boolean;

    public properties: {

        name?: string;

        duration?: number;

        tags?: string[];

        [key: string]: any

    };

    public static fromFolder(folder: Folder, defaultSettings: { duration: number, transition: string, color: string }): CampaignPlaylistEntry {
        return new CampaignPlaylistEntry({
            resourceId: folder.id,
            type: 'foreign',
            subtype: 'folder',
            continuous: false,
            color: defaultSettings.color,
            duration: defaultSettings.duration,
            transition: defaultSettings.transition,
            properties: {
                name: folder.name
            }
        });
    }

    public static fromResource(resource: Resource, defaultSettings: { duration: number, transition: string, color: string }): CampaignPlaylistEntry {
        const playlistProperties = resource.playlistProperties || new PlaylistProperties({});        
    
        return new CampaignPlaylistEntry({
            resourceId: resource.id,
            type: resource.type,
            subtype: resource.subtype,
            continuous: false,
            color: playlistProperties.color || defaultSettings.color,
            duration: this.getDuration(resource, defaultSettings.duration),
            transition: defaultSettings.transition,
            properties: {
                name: resource.name,
                duration: this.getResourceDuration(resource)
            }
        });
    }

    private static getDuration(resource: Resource, defaultDuration: number): number {
        const resourceDuration = this.getResourceDuration(resource);

        if (resourceDuration || resourceDuration === 0) {
            return resourceDuration;
        }

        const playlistProperties = resource.playlistProperties || new PlaylistProperties({});
        return playlistProperties.duration || defaultDuration;
    }

    private static getResourceDuration(resource: Resource): number {
        if (resource.type === ResourceType.compose2 && resource.subtype === ResourceSubtype.survey) {
            return 0;
        }

        return resource.duration;
    }
}
