import { AbstractControl, ValidatorFn } from '@angular/forms';

export function matchPassword(ac: AbstractControl): ValidatorFn {
    const password = ac.get('newPassword').value;
    const confirmPassword = ac.get('newPasswordConfirm').value;

    if (password !== confirmPassword) {
        ac.get('newPasswordConfirm').setErrors({ matchPassword: true });
    } else {
        ac.get('newPasswordConfirm').setErrors(null);
    }

    return null;
}
