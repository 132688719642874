import { BaseEntry } from '../base/base-entry.model';

export class User extends BaseEntry<User> {
    public avatarId: string;
    public customerId: string;
    public email: string;
    public firstName: string;
    public group: string;
    public id: string;
    public lastName: string;
    public notes: string;
    public phone: string;
    public position: string;
    public roleId: string;
    public version: number;
}
