import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiUrls } from '@config/api-urls';
import { AuthService } from '@core/auth';
import { ApiService } from '@core/data/services';
import { Role, RoleRef } from '@domain/roles';


export class RolesApi extends ApiService {

    constructor(public http: HttpClient, protected authService: AuthService) {
        super(http, authService);
    }

    /**
     * Load role information by id
     * @param id Role Id
     */
    public rolesRead(id: string): Observable<Role> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.rolesRead, { customerId, id });
    }

    /**
     * Update role information
     * @param role Role object
     */
    public rolesUpdate(role: Role): Observable<Role> {
        role.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.rolesUpdate, role);
    }

    /**
     * Delete roles
     * @param ids Role ids to remove
     */
    public rolesDelete(ids: string[]): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.rolesDelete, { customerId, ids });
    }

    /**
     * Create new role
     * @param role Role object
     */
    public rolesCreate(role: Role): Observable<Role> {
        role.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.rolesCreate, role);
    }

    /**
     * List roles for specified customer
     */
    public rolesFind(): Observable<RoleRef[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.rolesFind, { customerId });
    }
}
