<mat-toolbar>

    <mat-progress-bar *ngIf="showLoadingBar" class="loading-bar" color="accent" mode="indeterminate"></mat-progress-bar>

    <ng-container *ngIf="showMenuToggler">
        <button mat-icon-button (click)="toggleMenu.emit()">
            <mat-icon>menu</mat-icon>
        </button>
        <div class="toolbar-separator"></div>
    </ng-container>

    <ng-content></ng-content>

    <!-- <div class="mat-h2">{{ title | translate }}</div> -->

    <div class="flex-end">

        <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
            <div class="flex-center">
                <img class="avatar" [src]="avatarUrl$ | async" alt="user avatar">
                <div class="username_wrapper mr-12">
                    <span class="username">{{ user?.firstName }} {{ user?.lastName }}</span>
                    <span class="organization_name" *ngIf="selectedCustomer; else organizationName">
                        {{ selectedCustomer.name }}
                    </span>
                    <ng-template #organizationName>
                        <span class="organization_name">{{ customer.name }}</span>
                    </ng-template>
                </div>

                <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
        </button>

        <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

            <button mat-menu-item (click)="openProfile.emit()">
                <mat-icon>account_circle</mat-icon>
                <span>{{ 'TOOLBAR.MY_PROFILE' | translate }}</span>
            </button>

            <button *ngIf="readPermission" mat-menu-item [matMenuTriggerFor]="organizationMenu">
                <mat-icon>work</mat-icon>
                <span>{{ 'TOOLBAR.MY_ORGANIZATION' | translate }}</span>
            </button>

            <button (click)="onLogout()" mat-menu-item>
                <mat-icon>exit_to_app</mat-icon>
                <span>{{ 'TOOLBAR.LOGOUT' | translate }}</span>
            </button>

        </mat-menu>

        <mat-menu #organizationMenu="matMenu" [overlapTrigger]="false" class="sub-organization-menu">

            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="customers-tree">

                <mat-tree-node mat-menu-item (click)="onChangeCustomer(node)"
                    [class.selected]="node.id === selectedCustomer?.id" 
                    *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="20">
                    <button mat-icon-button disabled class="mdc-icon-button-34"></button>
                    {{node.name}}
                </mat-tree-node>

                <mat-tree-node mat-menu-item [class.selected]="node.id === selectedCustomer?.id" 
                    (click)="onChangeCustomer(node)" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="20">
                    <button mat-icon-button class="mdc-icon-button-34" matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                        </mat-icon>
                    </button>
                    {{ node.name }}
                </mat-tree-node>
            </mat-tree>
        </mat-menu>
    </div>

</mat-toolbar>
