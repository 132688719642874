import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { Observable } from 'rxjs';

import { ConfirmationComponent, NotificationComponent } from '../components';
import { ConfirmationMessage, NotificationMessage } from '../shared';


@Injectable()
export class NotificationsService {

    constructor(private snackBar: MatSnackBar, private dialog: MatDialog) { }

    public show(message: NotificationMessage): void {
        const config: MatSnackBarConfig = { 
            horizontalPosition: 'right', 
            verticalPosition: 'bottom', 
            duration: 5000, 
            panelClass: 'notification-panel',
            data: message
        };
        this.snackBar.openFromComponent(NotificationComponent, config);
    }

    public confirm(data: ConfirmationMessage): Observable<boolean> {
        return this.dialog.open(ConfirmationComponent, { panelClass: data.items ? 'md-dialog' : 'sm-dialog', data })
            .afterClosed();
    }
}
