import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Kiosk, Player, PlayerType, RebootSchedule, PlayerSchedule, PlayerScheduleEntry } from '@domain/players';
import { Time } from '@domain/utils';
import { arrayRequired } from '@domain/validators';

export class PlayersCreator {

    constructor(public fb: UntypedFormBuilder) { }

    public playerFormGroup(player: Player): UntypedFormGroup {
        return this.fb.group({
            id: [player.id],
            name: [player.name, [Validators.required]],
            group: [player.group],
            scheduleId: [player.scheduleId],
            locationId: [player.locationId, [Validators.required]],
            type: [player.type || PlayerType.generic],
            enabled: [player.enabled !== undefined ? player.enabled : true],
            audited: [player.audited !== undefined ? player.audited : false],
            version: [player.version],
            notes: [player.notes],
            facility: [player.facility],
            ticker: this.fb.group({
                resourceId: [player.ticker?.resourceId || null]
            }),
            overlay: this.fb.group({
                resourceId: [player.overlay?.resourceId || null]
            }),
            tags: [player.tags || []]
        });
    }

    public kioskFormGroup(kiosk: Kiosk): UntypedFormGroup {
        kiosk = kiosk || new Kiosk();
        return this.fb.group({
            resourceId: [kiosk.resourceId || null, Validators.required],
            timeout: [Time.convert(kiosk.timeout, 'millisecond', 'second')],
        });
    }

    public rebootScheduleFormGroup(reboot: RebootSchedule): UntypedFormGroup {

        reboot = reboot || new RebootSchedule();

        return this.fb.group({
            clear: [reboot.clear || false],
            time: [Time.asString(reboot.time)],
            weekdays: [reboot.weekdays || []]
        });
    }

    public playerScheduleFormGroup(schedule: PlayerSchedule): UntypedFormGroup {
        return this.fb.group({
            id: [schedule.id],
            name: [schedule.name, [Validators.required]],
            version: [schedule.version],
            schedules: this.fb.array((schedule.schedules || []).map(entry => this.createPlayerScheduleEntryFormGroup(entry)))
        });
    }

    public createPlayerScheduleEntryFormGroup(entry: PlayerScheduleEntry): UntypedFormGroup {

        entry = entry || new PlayerScheduleEntry();

        return this.fb.group({
            name: [entry.name, [Validators.required]],
            dateBegin: [entry.dateBegin || null],
            dateEnd: [entry.dateEnd || null],
            timeBegin: [Time.asString(entry.timeBegin), [Validators.required]],
            timeEnd: [Time.asString(entry.timeEnd), [Validators.required]],
            weekdays: [entry.weekdays, [arrayRequired]],
        });
    }

}
