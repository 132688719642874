import { Injectable } from '@angular/core';


import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class TranslationsResolver  {
    constructor(private translate: TranslateService) { }

    public resolve(): Observable<string[]> | Promise<string[]> {
        return this.translate.get('BUTTONS.ADD');
    }
}
