import { capitalize } from 'lodash';
import { Dimension } from '@editor/shared/interfaces/dimension.model';

export const Editor = {
    durations: [{ id: '15', label: '15 Sec.' }, { id: '20', label: '20 Sec.' }, { id: '25', label: '25 Sec.' }],

    // HD 1280×720
    // Full HD 1920×1080
    // 2K 2560x1440
    // 4K 3840×2160

    dimensions: {
        slide: {
            horizontal: [
                {
                    type: 'HD',
                    width: 1280,
                    height: 720,
                },
                {
                    type: 'FHD',
                    width: 1920,
                    height: 1080,
                },
                {
                    type: '2K',
                    width: 2560,
                    height: 1440,
                },
                {
                    type: '4K',
                    width: 3840,
                    height: 2160,
                },
            ],
            vertical: [
                {
                    type: 'HD',
                    width: 720,
                    height: 1280,
                },
                {
                    type: 'FHD',
                    width: 1080,
                    height: 1920,
                },
                {
                    type: '2K',
                    width: 1440,
                    height: 2560,
                },
                {
                    type: '4K',
                    width: 2160,
                    height: 3840,
                },
            ],
        },
        default: {
            width: 377,
            height: 600,
        },
    },

    generateDimensions(): Dimension[] {
        const defaultDimensions: Dimension[] = [
            {
                width: null,
                height: null,
                title: 'Manual',
                type: 'manual',
            },
        ];

        return Object.keys(this.dimensions.slide).reduce(
            (result, key) => {
                const dimension = this.dimensions.slide[key];

                return [
                    ...result,
                    ...dimension.map(d => ({
                        title: `${capitalize(key)} ${d.width}x${d.height} (${d.type})`,
                        ...d,
                    })),
                ];
            },
            [...defaultDimensions],
        );
    },
};
