import { BaseEntry } from '../base/base-entry.model';


export class TargetRef extends BaseEntry<TargetRef> {
    public id: string;
    public name: string;
    public type: string;
}

export enum TargetType {
    any = 'any',
    group = 'group',
    tag = 'tag',
    location = 'location',
    player = 'player',
    wap = 'wap',
    sensor = 'sensor',
    folder = 'folder',
    resource = 'resource',

    campaign = 'campaign',
}
