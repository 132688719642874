import { Component, Input, Output, EventEmitter, HostBinding, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';

import { environment } from '@environments/environment.common';
import { UIConfig } from '@domain/models';
import { BaseEntry } from '@domain/base';


class NavigationNode extends BaseEntry<NavigationNode> {
    id: string;
    translate: string;
    icon: string;
    url?: string;
    children?: NavigationNode[];
}

@Component({
    selector: 'main-sidenav',
    templateUrl: './main-sidenav.component.html',
    styleUrls: ['./main-sidenav.component.scss'],
})
export class MainSidenavComponent implements OnChanges {

    @Input() uiConfig: UIConfig;

    @HostBinding('class.collapsed')
    @Input() collapsed: boolean;

    @Output() toggleMenu: EventEmitter<any> = new EventEmitter();

    public version: string = environment.version;

    public navigation: NavigationNode[] = [];

    constructor(private _route: Router) { 
    }

    public ngOnChanges(changes: SimpleChanges): void {
        changes['uiConfig'] && this.onUiConfigChanges();
    }

    public onGoToDashboard(): void {
        this._route.navigate(['dashboard', this.getDashboard(this.uiConfig)]);
    }

    public onHeaderClick(panel: MatExpansionPanel): void {
        (this.collapsed && !panel.expanded) && panel.open();
        this.collapsed && this.toggleMenu.emit();
    }

    private onUiConfigChanges(): void {
        this.navigation = this.generateNavigationMenu(this.uiConfig);
    }

    private getDashboard(uiConfig: UIConfig): string {
        switch (true) {
            case uiConfig.playersAvailable: return 'digital-signage';
            case uiConfig.wapsAvailable: return 'wifi';
            case uiConfig.sensorsAvailable: return 'sensors';
        }
    }

    private generateNavigationMenu(uiConfig: UIConfig): NavigationNode[] {
        const navigationResult: NavigationNode[] = [
            new NavigationNode({
                id: 'dashboard',
                translate: 'NAV.DASHBOARD',
                icon: 'dashboard',
                url: `dashboard/${this.getDashboard(uiConfig)}`,
            })
        ];

        if (uiConfig.resourcesVisible) {
            navigationResult.push(new NavigationNode({
                id: 'content',
                translate: 'NAV.CONTENT',
                icon: 'insert_photo',
                url: 'content',
            }));
        }

        if (uiConfig.campaignsVisible) {
            navigationResult.push(...[
                new NavigationNode({
                    id: 'campaigns',
                    translate: 'NAV.CAMPAIGNS',
                    icon: 'play_circle_outline',
                    url: '/campaigns',
                }),
                new NavigationNode({
                    id: 'calendar',
                    translate: 'NAV.CALENDAR',
                    icon: 'today',
                    url: 'calendar',
                }),
                new NavigationNode({
                    id: 'automation',
                    translate: 'NAV.AUTOMATION',
                    icon: 'cached',
                    url: 'automation',
                }),
            ]);
        }

        if (uiConfig.sensorsVisible || uiConfig.playersVisible || uiConfig.locationsVisible || uiConfig.wapsVisible) {
            const settingsNode = new NavigationNode({
                id: 'settings',
                translate: 'NAV.SETTINGS',
                icon: 'settings',
                children: []
            });

            if (uiConfig.locationsVisible) {
                settingsNode.children.push(new NavigationNode({
                    id: 'locations',
                    translate: 'SETTINGS.LOCATIONS.TITLE',
                    url: 'settings/locations',
                }));
            }

            if (uiConfig.playersVisible) {
                settingsNode.children.push(new NavigationNode({
                    id: 'tv-boxes',
                    translate: 'SETTINGS.TV_BOXES.TITLE',
                    url: 'settings/tvboxes',
                }));
            }

            if (uiConfig.wapsVisible) {
                settingsNode.children.push(new NavigationNode({
                    id: 'access-points',
                    translate: 'NAV.ACCESS_POINTS',
                    url: 'settings/accesspoints',
                }));
            }

            if (uiConfig.sensorsVisible) {
                settingsNode.children.push(new NavigationNode({
                    id: 'sensors',
                    translate: 'SETTINGS.SENSORS.TITLE',
                    url: 'settings/sensors',
                }));
            }

            navigationResult.push(settingsNode);
        }

        if (uiConfig.profilesVisible) {

            const profilesNode = new NavigationNode({
                id: 'profiles',
                translate: 'NAV.PROFILES',
                icon: 'supervisor_account',
                children: [],
            });

            profilesNode.children.push(new NavigationNode({
                id: 'users',
                translate: 'PROFILES.USERS.TITLE',
                url: 'profiles/users',
            }));

            profilesNode.children.push(new NavigationNode({
                id: 'roles',
                translate: 'PROFILES.ROLES.TITLE',
                url: 'profiles/roles',
            }));

            profilesNode.children.push(new NavigationNode({
                id: 'my_organization',
                translate: 'PROFILES.ORGANIZATION.TITLE',
                url: 'profiles/customers/me',
            }));

            navigationResult.push(profilesNode);
        }

        if (uiConfig.resourcesVisible) {
            navigationResult.push(new NavigationNode({
                id: 'trash',
                translate: 'NAV.TRASH',
                icon: 'delete',
                url: 'trash',
            }));
        }

        return navigationResult;
    }
}
