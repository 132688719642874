import { NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DataModule, AppRouterStateSerializer } from '@core/data';
import { AuthModule, AuthService } from '@core/auth';
import { NetworkModule } from '@core/network';
import { NotificationsModule } from '@core/notifications';
import { FileUploaderModule, UPLOADER_CONFIG, Tus } from '@modules/file-uploader';
import { MainLayoutModule } from '@modules/main-layout';
import { environment } from '@environments/environment';
import { MyProfileModule } from '@my-profile/my-profile.module';
import { HotkeyModule } from '@core/hotkey';
import { ErrorsModule } from '@core/errors';
import { FormsCreatorModule } from '@core/forms-creator';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PageBuilderModule } from '@modules/page-builder';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function uploaderOptionsFactory(injector: Injector) {
    const auth = injector.get(AuthService);
    return {
        endpoint: `${environment.uploadUrl}/tus`,
        token: () => auth.getAuthToken(),
        concurrency: 1,
        uploaderClass: Tus
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserModule,
        MatNativeDateModule,
        BrowserAnimationsModule,
        LayoutModule,
        HttpClientModule,
        MatSidenavModule,
        MainLayoutModule,
        PageBuilderModule,
        TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: (createTranslateLoader), deps: [HttpClient] } }),
        AuthModule.forRoot(),
        DataModule.forRoot(),
        NetworkModule.forRoot(),
        NotificationsModule.forRoot(),
        HotkeyModule.forRoot(),
        FormsCreatorModule.forRoot(),
        ErrorsModule.forRoot({ source: 'WEB', logClientErrors: environment.production, logServerErrors: environment.production, logRoutingErrors: environment.production }),
        FileUploaderModule.forRoot({ options: { provide: UPLOADER_CONFIG, useFactory: uploaderOptionsFactory, deps: [Injector] } }),
        NgxGoogleAnalyticsModule.forRoot(environment.ga),
        NgxGoogleAnalyticsRouterModule,
        NgxsModule.forRoot([], { developmentMode: !environment.production }),
        NgxsLoggerPluginModule.forRoot({ logger: console, collapsed: false, disabled: environment.production }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
        NgxsRouterPluginModule.forRoot(),
        NgxsResetPluginModule.forRoot(),
        MyProfileModule
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: RouterStateSerializer, useClass: AppRouterStateSerializer },
    ],
})
export class AppModule { }
