import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { IS_TOKEN_NEEDED } from './auth-header.token';




@Injectable()
export class AuthHeadersInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.context.get(IS_TOKEN_NEEDED) === true) {
            req = this.addAuthorizationHeader(req);
        }

        return next.handle(req);
    }

    private addAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
        const token = this.authService.getAuthToken();

        if (token) {
            return req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
        }

        return req;
    }
}
