import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiUrls } from '@config/api-urls';
import { AuthService } from '@core/auth';
import { ApiService } from '@core/data/services';

import { Wap, WapEntry } from '@domain/waps';
import { DataQuery, DataFilter } from '@domain/query';
import { UpdateEntitiesLocationRequest } from '@domain/locations';
import { EnableRequest } from '@domain/models';


export class WapsApi extends ApiService {

    constructor(public http: HttpClient, protected authService: AuthService) {
        super(http, authService);
    }

    /**
     * Enable or disable target waps
     * @param params 
     */
    public wapsEnable(params: EnableRequest): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.wapsEnable, { customerId, ...params });
    }

    /**
     * Get list of waps groups
     */
    public wapsGroups(): Observable<string[]> {
        return this.post(ApiUrls.wapsGroups, { customerId: this.authService.getCustomerId() });
    }

    /**
     * Get list of waps tags
     */
    public wapsTags(): Observable<string[]> {
        return this.post(ApiUrls.wapsTags, { customerId: this.authService.getCustomerId() });
    }

    /**
     * Read wap by id
     * @param id Wap id
     */
    public wapsRead(id: string): Observable<Wap> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.wapsRead, { customerId, id });
    }

    /**
     * Update a wap entry
     * @param wap Wap to update
     */
    public wapsUpdate(wap: Wap): Observable<Wap> {
        wap.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.wapsUpdate, wap);
    }

    /**
     * Create a new wap entry
     * @param wap Wap to create
     */
    public wapsCreate(wap: Wap): Observable<Wap> {
        wap.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.wapsCreate, wap);
    }

    /**
     * Delete waps by ids
     * @param ids Waps ids to remove
     */
    public wapsDelete(ids: string[]): Observable<string[]> {
        return this.post(ApiUrls.wapsDelete, { customerId: this.authService.getCustomerId(), ids });
    }

    /**
     * Find waps by specified query
     * @param query Query to find
     */
    public wapsFind(query: DataQuery): Observable<WapEntry[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.wapsFind, { customerId, query });
    }

    /**
     * Find waps by ids
     * @param ids Waps ids
     */
    public wapsFindByIds(ids: string[]): Observable<WapEntry[]> {
        const customerId = this.authService.getCustomerId();
        const query = new DataQuery({
            filters: [
                new DataFilter({
                    field: 'id',
                    filter: { type: 'id', in: ids },
                }),
            ]
        });
        return this.post(ApiUrls.wapsFind, { customerId, query });
    }

    /**
     * Find waps in specified location
     * @param locationId Target location id
     */
    public wapsFindInLocation(locationId: string): Observable<WapEntry[]> {
        const customerId = this.authService.getCustomerId();
        const query = {
            filters: [
                {
                    field: 'locationId',
                    filter: { type: 'id', is: locationId },
                },
            ],
        };
        return this.post(ApiUrls.wapsFind, { customerId, query });
    }

    /**
     * Find waps except specified location
     * @param locationId Target location id
     */
    public wapsFindNotInLocation(locationId: string): Observable<WapEntry[]> {
        const customerId = this.authService.getCustomerId();

        const query = {
            filters: [
                {
                    field: 'locationId',
                    filter: { type: 'id', ne: locationId },
                },
            ],
        };

        return this.post(ApiUrls.wapsFind, { customerId, query });
    }

    /**
     * Update waps location
     * @param request
     */
    public wapsUpdateLocation(request: UpdateEntitiesLocationRequest): Observable<string[]> {
        request.customerId = this.authService.getCustomerId();

        return this.post(ApiUrls.wapsUpdateLocation, request);
    }
}
