import { BaseEntry } from '../base/base-entry.model';

export class PlayerScheduleEntry extends BaseEntry<PlayerScheduleEntry> {
    public name: string;
    public dateBegin: number;
    public dateEnd: number;
    public timeBegin: number;
    public timeEnd: number;
    public weekdays: string[];
}
