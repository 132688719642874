import { Injectable } from '@angular/core';

import { AuthService } from '@core/auth';
import { ResourceSubtype, ResourceType } from '@domain/resources';


@Injectable()
export class LicenseService {

    public readonly filterItems: any[] = [];

    private readonly showDS = this.authService.isAvailable('players');

    constructor(private authService: AuthService) {
        this.filterItems = this.getFilterItems();
    }

    public buildFilterItems(type: string) {
        return {
            video: { text: 'Video', value: { type: ResourceType.file, subtype: ResourceSubtype.video } },
            image: { text: 'Image', value: { type: ResourceType.file, subtype: ResourceSubtype.image } },
            pdf: { text: 'PDF', value: { type: ResourceType.file, subtype: ResourceSubtype.pdf } },
            audio: { text: 'Audio', value: { type: ResourceType.file, subtype: ResourceSubtype.audio } },
            divider: { text: 'Divider', value: null },
            template: { text: 'Template', value: { type: ResourceType.compose2, subtype: ResourceSubtype.template } },
            slide: { text: 'Slide', value: { type: ResourceType.compose2, subtype: ResourceSubtype.slide } },
        }[type];
    }

    protected getFilterItems() {
        const filterItems = ['video', 'image', 'pdf', 'audio', 'divider', 'slide', 'template'].map(item =>
            this.buildFilterItems(item),
        );

        // if (this.showWifi) {
        //     filterItems.push(
        //         { text: 'Hotspot', value: { type: ResourceType.compose2, subtype: ResourceSubtype.hotspot } },
        //         { text: 'Terms of Use', value: { type: ResourceType.compose2, subtype: ResourceSubtype.terms } },
        //         { text: 'Survey', value: { type: ResourceType.compose2, subtype: ResourceSubtype.survey } },
        //     );
        // }

        if (this.showDS) {
            filterItems.push(
                { text: 'Divider', value: null },
                { text: 'Ticker', value: { type: ResourceType.ticker, subtype: ResourceSubtype.local } },
                { text: 'Kiosk', value: { type: ResourceType.kiosk, subtype: ResourceSubtype.external } },
                { text: 'Web-page', value: { type: ResourceType.web, subtype: null } },
                { text: 'Audio stream', value: { type: ResourceType.stream, subtype: ResourceSubtype.music2biz } },
            );
        }

        return filterItems;
    }
}
