import { NavigationEnd, Router, GuardsCheckEnd, ActivationEnd, NavigationError } from '@angular/router';
import { Observable } from 'rxjs';
import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { AuthService } from '@core/auth';


import { DataState } from '@core/data';
import { Customer, CustomersTree } from '@domain/customers';
import { User } from '@domain/users';
import { Tree } from '@domain/operators/tree';


interface CustomerFlatNode {
    id: string;
    expandable: boolean;
    name: string;
    level: number;
}

@Component({
    selector: 'main-toolbar',
    templateUrl: './main-toolbar.component.html',
    styleUrls: ['./main-toolbar.component.scss'],
})
export class MainToolbarComponent implements OnInit {

    @Input() user: User;

    @Input() customersTree: CustomersTree[];

    @Input() customer: Customer;

    @Input() customerId: string;

    @Input() showMenuToggler: boolean;

    @Select(DataState.avatarUrl) avatarUrl$: Observable<string>;

    @Output() openProfile: EventEmitter<any> = new EventEmitter();

    @Output() toggleMenu: EventEmitter<any> = new EventEmitter();

    @Output() changeCustomer: EventEmitter<string> = new EventEmitter();

    public showLoadingBar: boolean;

    public selectedCustomer: CustomersTree;

    public readPermission: boolean = this.authService.getReadPermission(`childCustomers`);

    public title: string;

    public treeControl = new FlatTreeControl<CustomerFlatNode>(node => node.level, node => node.expandable);

    public treeFlattener = new MatTreeFlattener(this.transformer, node => node.level, node => node.expandable, node => node.children);

    public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


    constructor(
        private authService: AuthService,
        private router: Router,
    ) {
        this.subscribeOnRouteEvents();
    }

    public ngOnInit(): void {
        const selectedCustomerId = this.authService.getCustomerId();
        this.selectedCustomer = Tree.findNode(this.customersTree, (x: CustomersTree) => x.id === selectedCustomerId);
        this.dataSource.data = this.customersTree;
        this.expandToCurrent(this.selectedCustomer.id);
    }

    private expandToCurrent(currentNodeId: string): void {
        let nodeId = currentNodeId;

        do {
            const node: CustomersTree = Tree.findNode(this.customersTree, (x: CustomersTree) => x.id === nodeId);
            nodeId = node?.parentId || null;

            if (nodeId) {
                const itemToExpand = this.treeControl.dataNodes.find(x => x.id === nodeId);
                itemToExpand && this.treeControl.expand(itemToExpand);
            }

        } while (!!nodeId);
    }

    private transformer(node: CustomersTree, level: number) {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level,
            id: node.id
        };
    }

    public hasChild = (_: number, node: CustomerFlatNode) => node.expandable;

    public onChangeCustomer(customer: Customer): void {
        if (this.selectedCustomer?.id === customer.id) {
            return;
        }

        this.selectedCustomer = Tree.findNode(this.customersTree, (x: CustomersTree) => x.id === customer.id);
        this.changeCustomer.emit(this.selectedCustomer.id);
    }

    public onLogout(): void {
        this.authService.logout();
    }

    private subscribeOnRouteEvents(): void {
        this.router.events.subscribe(event => {
            if (event instanceof GuardsCheckEnd && event.shouldActivate) {
                this.showLoadingBar = true;
            }

            if (event instanceof NavigationEnd || event instanceof NavigationError) {
                this.showLoadingBar = false;
            }

            if (event instanceof ActivationEnd && event.snapshot.data['title']) {
                this.title = event.snapshot.data['title'];
            }
        });
    }
}
