import { HotkeyMapper } from './hotkey.mapper';


export class Hotkey {

    public key: string;
    public event: string;
    public description: string;

    constructor(key: string, description: string) {
        this.key = key.split('.').map(k => HotkeyMapper.systemMap[k] || k).join('.');
        this.description = description;
        this.event = `keydown.${this.key}`;
    }
}
