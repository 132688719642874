import { OnDestroy, OnInit, Directive } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UntypedFormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { IHaveChanges } from '@core/data';
import { markFormAsTouched } from '@domain/static';
import { ObserverComponent } from '@domain/base/observer.component';


@Directive()
export abstract class PageFormComponent extends ObserverComponent implements IHaveChanges, OnInit, OnDestroy {

    public pageForm: UntypedFormGroup;

    protected backAfterSave: boolean = true;

    constructor(public translate: TranslateService, public title: Title) {
        super();
    }

    public ngOnInit(): void {
        this.subscribeOnChangeLang();
    }

    public haveChanges(): boolean | Observable<boolean> {
        return this.pageForm.dirty;
    }

    public onConfirmSave(): Observable<boolean> {
        if (this.pageForm.invalid) {
            markFormAsTouched(this.pageForm);
            return of(false);
        }

        return this.save().pipe(mapTo(true));
    }

    public onSave(): void {
        if (this.pageForm.invalid) {
            markFormAsTouched(this.pageForm);
            return;
        }

        this.save().subscribe(() => { this.backAfterSave && this.onBack(); });
    }

    public onBack(): void {
        // This is intentional
    }

    protected abstract save(): Observable<any>;

    protected abstract getPageTitles(): string[];

    protected setPageTitle(): void {
        const titles = ['use2go', ...this.getPageTitles()].filter(x => !!x);
        this.title.setTitle(titles.join(' - '));
    }

    protected onLangChange() {
        this.setPageTitle();
    }

    protected subscribeOnChangeLang(): void {
        this.onLangChange();

        const subscription = this.translate.onLangChange
            .subscribe(() => this.onLangChange());

        this.subscriptions.push(subscription);
    }
}
