import { ObjectAction } from '@domain/roles';
import { BaseEntry } from '../base/base-entry.model';
import { PlaylistProperties } from './playlist-properties.model';

export class ResourceStatus extends BaseEntry<ResourceAction> {
    public type: string;
}

export class ResourceAction extends BaseEntry<ResourceAction> {
    public properties: { [id: string]: any };
    public type: string;
}

export class CompatibleFile extends BaseEntry<CompatibleFile> {
    public compatIds: string[];
    public mediaType: string;
}

/**
 * 'compose' is no longer used
 */
export enum ResourceType {
    folder = 'folder',
    compose = 'compose',
    file = 'file',
    compose2 = 'compose2',
    kiosk = 'kiosk',
    ticker = 'ticker',
    web = 'web',
    stream = 'stream',
    youtube = 'youtube'
}

export enum ResourceSubtype {
    terms = 'terms',
    hotspot = 'hotspot',
    template = 'template',
    survey = 'survey',
    slide = 'slide',
    image = 'image',
    video = 'video',
    pdf = 'pdf',
    audio = 'audio',
    music2biz = 'music2biz',
    web = 'web',
    rss = 'rss',
    local = 'local',
    external = 'external',
}

export class Resource {
    public actions: Record<string, ResourceAction>;
    public compat: CompatibleFile;
    public content: any;
    public customerId: string;
    public duration: number;
    public expireAfter: Date;
    public fileId: string;
    public folderId: string;
    public id: string;
    public lastModifiedDate: Date;
    public mediaType: string;
    public name: string;
    public playlistProperties: PlaylistProperties;
    public previewId: string;
    public properties: Record<string, any>;
    public relates: string[];
    public size: number;
    public subtype: string;
    public tags: string[];
    public type: string;
    public version: number;
    public status: ResourceStatus;
    public objectActions: ObjectAction[];

    public expired = false;


    constructor(fields?: Partial<Resource>) {
        if (fields) {
            Object.assign(this, fields);
            this.expired = this._expired();
        }
    }

    public static from(resource: Partial<Resource>): Resource {
        return new Resource(resource);
    }

    public static canAssign(resource: Resource): boolean {
        return resource.subtype && [ResourceSubtype.terms, ResourceSubtype.hotspot].includes(resource.subtype as ResourceSubtype);
    }

    public static canReplace(resource: Resource): boolean {
        return resource.type && [ResourceType.file].includes(resource.type as ResourceType);
    }

    public static canEdit(resource: Resource): boolean {
        return resource.type &&
            [ResourceType.compose, ResourceType.compose2, ResourceType.web, ResourceType.stream, ResourceType.ticker, ResourceType.kiosk].includes(resource.type as ResourceType);
    }

    public static canFill(resource: Resource): boolean {
        return resource.subtype === ResourceSubtype.template;
    }

    public static canDownload(resource: Resource): boolean {
        return !!resource?.fileId;
    }

    private _expired(): boolean {
        if (!this.expireAfter) {
            return false;
        }

        const today = new Date();
        const todayISOString = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()))
            .toISOString();
        return new Date(todayISOString) > new Date(this.expireAfter);
    }

}
