import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiUrls } from '@config/api-urls';
import { ApiService } from '@core/data/services';
import { AuthService } from '@core/auth';
import { ChartRequest, ChartPeopleCountEntry, ChartPeopleOccupancyEntry, ChartOnlineData } from '@domain/dashboards';


export class DashboardsApi extends ApiService {

    constructor(public http: HttpClient, protected auth: AuthService) {
        super(http, auth);
    }

    public dashboardsCountOnline(options: any): Observable<ChartOnlineData> {
        const customerId = this.authService.getCustomerId();
        const params: any = { customerId, ...options };

        return this.post(ApiUrls.dashboardsCountOnline, params);
    }

    public dashboardsListFeedback(request: ChartRequest): Observable<any[]> {
        request.customerId = this.auth.getCustomerId();
        return this.post(ApiUrls.dashboardsListFeedback, request);
    }

    public dashboardsChartPeopleOccupancy(params: any): Observable<ChartPeopleOccupancyEntry[]> {
        const customerId = this.auth.getCustomerId();
        return this.post(ApiUrls.dashboardsChartPeopleOccupancy, { ...params, customerId });
    }

    public dashboardsChartPeopleCount(params: any): Observable<ChartPeopleCountEntry[]> {
        const customerId = this.auth.getCustomerId();
        return this.post(ApiUrls.dashboardsChartPeopleCount, { customerId, ...params });
    }

}
