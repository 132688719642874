export enum ActionClauseType {
    age = 'age',
    delay = 'delay',
    female = 'female',
    gender = 'gender',
    icon = 'icon',
    in = 'in',
    male = 'male',
    out = 'out',
    temp = 'temp',
    total = 'total',
}
