import { BaseEntry } from '../base/base-entry.model';
import { CampaignPlaylistEntry } from './campaign-playlist-entry.model';
import { ObjectAction } from '../roles/object-actions.model';

export class CampaignEntry extends BaseEntry<CampaignEntry> {
    public color: string;
    public customerId: string;
    public duration: number;
    public dynamic: boolean;
    public enabled: boolean;
    public fullText: string;
    public group: string;
    public id: string;
    public lastModifiedBy: string;
    public lastModifiedById: string;
    public lastModifiedDate: Date;
    public name: string;
    public offline: boolean;
    public playlistEntry: CampaignPlaylistEntry;
    public playlist: CampaignPlaylistEntry[];
    public status: string;
    public timeBegin: number;
    public timeEnd: number;
    public type: string;
    public validForEnabling: boolean;
    public objectActions: ObjectAction[];
}
