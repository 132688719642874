import { ChangeDetectionStrategy, Component, Input, EventEmitter, Output } from '@angular/core';

export interface FilterItemModel {
    text: string;
    value: any;
}

@Component({
    selector: 'page-selection-filter',
    templateUrl: './page-selection-filter.component.html',
    styleUrls: ['./page-selection-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageSelectionFilterComponent {

    @Input() selectedItems: any[] = [];

    @Input() filterItems: FilterItemModel[] = [];

    @Output() selectedItemsChange = new EventEmitter<any[]>();

    get selectedFilterItems(): FilterItemModel[] {
        return (this.selectedItems || [])
            .map(i => this.filterItems.find(x => x.value === i))
            .filter(i => !!i);
    }

    public onSelectionChange(type: FilterItemModel): void {
        this.selectedItems = this.selectedItems.includes(type.value)
            ? this.selectedItems.filter((s: string) => s !== type.value)
            : [...this.selectedItems, type.value];

        this.selectedItemsChange.emit(this.selectedItems);
    }

    public onRemove(group: string): void {
        this.selectedItems = this.selectedItems.filter(g => g !== group);
        this.selectedItemsChange.emit(this.selectedItems);
    }

    public onRemoveAll(): void {
        this.selectedItems = [];
        this.selectedItemsChange.emit(this.selectedItems);
    }

    public trackByFn(index: number, type: FilterItemModel) {
        return type;
    }

}
