import { BaseEntry } from '../base/base-entry.model';
import { PermissionEntry } from './permission-entry.model';
import { TargetRef } from './target-ref.model';


export class Permission extends BaseEntry<Permission> {
    public entries: PermissionEntry[];
    public read: boolean;
    public write: boolean;
    public targets: TargetRef[];
}
