<div class="header">
    <div class="logo">
        <span class="mat-h2">Groups</span>
    </div>
</div>

<div class="content">
    <div class="nav">
        <div class="nav-item" [ngClass]="{ 'selected-item' : group === selectedGroup }" aria-label="inbox" *ngFor="let group of groups">
            <a class="nav-link" matRipple (click)="selectGroup.emit(group)">
                <mat-icon class="nav-link-icon">label</mat-icon>
                <span class="title">{{group}}</span>
            </a>
        </div>
    </div>
</div>
