import { HttpClient } from '@angular/common/http';

import { Observable, of, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiUrls } from '@config/api-urls';
import { ApiService } from '@core/data/services';
import { AuthService } from '@core/auth';
import {
    Resource, Folder, CopyResourceRequest, UpdateResourcesMetadataRequest, ResourceMetadataTemplate,
    FoldersTree, CreateFoldersTreeRequest, QuotaEntry, UndeleteResourcesRequest, ResourcesQueryRequest, ResourcesQuery, ResourceFilter
} from '@domain/resources';
import { DataQuery, DataFilter, DataSlice, DataSort } from '@domain/query';
import { DeleteRequest, UpdateReferenceRequest } from '@domain/models';
import { CampaignEntry } from '@domain/campaigns';
import { unique } from '@domain/utils';

export class ResourcesApi extends ApiService {

    constructor(public http: HttpClient, protected authService: AuthService) {
        super(http, authService);
    }

    /**
     * Read resource by id
     * @param id Resource id
     */
    public resourcesRead(id: string): Observable<Resource> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesRead, { id, customerId });
    }

    /**
     * Get resources quota information
     */
    public resourcesQuota(): Observable<QuotaEntry> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesQuota, { customerId });
    }

    /**
     * Create a new Resource entry
     * @param params Resource to create
     */
    public resourcesCreate(params: Resource): Observable<Resource> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesCreate, { customerId, ...params })
            .pipe(map((resource: Partial<Resource>) => Resource.from(resource)));
    }

    /**
     * Update a resource entry
     * @param params Resource to update
     */
    public resourcesUpdate(params: Resource): Observable<Resource> {
        return this.post(ApiUrls.resourcesUpdate, params)
            .pipe(map((resource: Partial<Resource>) => Resource.from(resource)));
    }

    public resourcesReadTree(withDeleted: boolean = false): Observable<Folder[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesReadTree, { customerId, withDeleted }).pipe(
            map(folders => folders.map((folder: Partial<Folder>) => new Folder(folder))),
        );
    }


    public resourcesReadFoldersTree(): Observable<FoldersTree[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesReadFoldersTree, { customerId })
            .pipe(
                map(folders => folders.map((folder: Partial<FoldersTree>) => new FoldersTree(folder))),
            );
    }

    public resourcesCreateFolder(params: Folder): Observable<Folder> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesCreateFolder, { customerId, ...params })
            .pipe(map((folder: Partial<Folder>) => new Folder(folder)));
    }

    public resourcesCreateFolders(request: CreateFoldersTreeRequest): Observable<FoldersTree[]> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesCreateFolders, request);
    }

    public resourcesUpdateFolder(params: Folder): Observable<Folder> {
        return this.post(ApiUrls.resourcesUpdateFolder, params)
            .pipe(map((folder: Partial<Folder>) => new Folder(folder)));
    }

    public resourcesClone(params: CopyResourceRequest): Observable<Resource[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesClone, { ...params, customerId })
            .pipe(
                map((resources: Partial<Resource>[]) => resources.map(resource => Resource.from(resource)))
            );
    }

    public resourcesTags(): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesTags, { customerId });
    }

    public resourcesUpdateMetadata(request: UpdateResourcesMetadataRequest): Observable<Resource[]> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesUpdateMetadata, request).pipe(
            switchMap(resources => this.resourcesFindByIds((resources || []).map(x => x.id))),
            map(resources => resources.map((resource: any) => new Resource({ ...resource })))
        );
    }

    public resourcesFindTemplates(): Observable<ResourceMetadataTemplate[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesFindTemplates, { customerId });
    }

    public resourcesCreateTemplate(template: ResourceMetadataTemplate): Observable<ResourceMetadataTemplate> {
        template.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesCreateTemplate, template);
    }

    public resourcesReadTemplate(id: string): Observable<ResourceMetadataTemplate> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesReadTemplate, { customerId, id });
    }

    public resourcesUpdateTemplate(template: ResourceMetadataTemplate): Observable<ResourceMetadataTemplate> {
        template.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesUpdateTemplate, template);
    }

    public resourcesDeleteTemplate(id: string): Observable<string> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesDeleteTemplate, { customerId, id });
    }

    public resourcesMoveFolders(request: CopyResourceRequest): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesMoveFolders, { customerId, ...request });
    }

    /**
     * Delete of resources and folders by ids 
     * @param resourcesIds Resources ids
     * @param foldersIds Folders ids
     */
    public delete(resourcesIds: string[], foldersIds: string[] = []): Observable<any> {
        const customerId = this.authService.getCustomerId();
        const requests = [
            {
                method: ApiUrls.resourcesDelete,
                params: { customerId, ids: resourcesIds || [] },
            },
            {
                method: ApiUrls.resourcesDeleteFolders,
                params: { customerId, ids: foldersIds || [] },
            },
        ];

        return this.postBatch(requests);
    }

    /**
     * Undelete of resources and folders by ids 
     * @param resourcesIds Resources ids
     * @param foldersIds Folders ids
     */
    public resourcesUndelete(request: Partial<UndeleteResourcesRequest>): Observable<any> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesUndelete, { ...request, customerId });
    }

    /**
     * Move folders and resource into new folder
     * @param folderId Target folder id
     * @param resourcesIds Resources ids
     * @param foldersIds Folders ids
     */
    public move(folderId: string, resourcesIds: string[], foldersIds: string[] = []): Observable<any> {
        const customerId = this.authService.getCustomerId();
        const requests = [
            {
                method: ApiUrls.resourcesMove,
                params: { customerId, folderId, resourceIds: resourcesIds || [] },
            },
            {
                method: ApiUrls.resourcesMoveFolders,
                params: { customerId, folderId, resourceIds: foldersIds || [] },
            },
        ];

        return this.postBatch(requests);
    }

    /**
     * Find resources by ids
     * @param ids Resources ids
     */
    public resourcesFindByIds(ids: string[]): Observable<Resource[]> {
        const customerId = this.authService.getCustomerId();
        const params = {
            customerId,
            query: {
                filters: [
                    {
                        field: 'id',
                        filter: { type: 'id', in: ids },
                    },
                ],
            },
            global: true,
        };

        return (ids && ids.length ? this.post(ApiUrls.resourcesFind, params) : of([]))
            .pipe(
                map(items => items.map((x: Resource) => new Resource({ ...x })))
            );
    }

    /**
     * Find resources of subtype 'survey'
     */
    public resourcesFindSurveys(): Observable<Resource[]> {
        const params = {
            customerId: this.authService.getCustomerId(),
            global: true,
            query: {
                filters: [
                    {
                        field: 'subtype',
                        filter: {
                            type: 'string',
                            contains: 'SURVEY',
                        },
                    },
                ],
            },
        };

        return this.post(ApiUrls.resourcesFind, params);
    }

    public resourcesFindBy(request: ResourcesQuery): Observable<Resource[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesFind, new ResourcesQueryRequest({ ...request.toRequest(), customerId }));
    }

    public resourcesFind(request: Partial<ResourcesQueryRequest>): Observable<Resource[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesFind, new ResourcesQueryRequest({ ...request, customerId }));
    }

    /**
     * Find resources of subtype 'terms'
     */
    public resourcesFindTerms(): Observable<Resource[]> {
        const customerId = this.authService.getCustomerId();

        return this.post(ApiUrls.resourcesFind, {
            customerId,
            global: true,
            query: {
                filters: [
                    {
                        field: 'subtype',
                        filter: {
                            type: 'string',
                            contains: 'terms',
                        },
                    },
                ],
            },
        });
    }

    /**
     * Find resources of subtype 'hotspot'
     */
    public resourcesFindHotspots(): Observable<Resource[]> {
        const customerId = this.authService.getCustomerId();

        return this.post(ApiUrls.resourcesFind, {
            customerId,
            global: true,
            query: {
                filters: [
                    {
                        field: 'subtype',
                        filter: {
                            type: 'string',
                            contains: 'hotspot',
                        },
                    },
                ],
            },
        });
    }

    /**
     * Find resources of subtype 'local'
     */
    public resourcesFindTickers(): Observable<Resource[]> {
        const customerId = this.authService.getCustomerId();

        return this.post(ApiUrls.resourcesFind, {
            customerId,
            global: true,
            query: {
                filters: [
                    {
                        field: 'subtype',
                        filter: {
                            type: 'string',
                            contains: 'local',
                        },
                    },
                ],
            },
        });
    }

    /**
     * Find folders by ids
     * @param ids List of folder ids
     */
    public foldersFindByIds(ids: string[]): Observable<Folder[]> {
        const customerId = this.authService.getCustomerId();
        return ids && ids.length ?
            this.post(ApiUrls.resourcesReadTree, { customerId })
                .pipe(
                    map((folders: Folder[]) => {
                        return (folders || []).filter(folder => ids.includes(folder.id))
                            .map((folder: Partial<Folder>) => new Folder(folder));
                    })
                ) :
            of([]);
    }

    /**
     * Find folders by filter
     * @param params Resource filter model
     */
    public resourcesFindByFilter(params: ResourceFilter): Observable<Resource[]> {
        const customerId = this.authService.getCustomerId();
        const { folderId } = params;

        const query = new DataQuery({
            slice: new DataSlice({ skip: params.skip, limit: params.limit }),
            filters: [],
            include: [],
        });

        if (params.search) {
            query.filters.push(new DataFilter({ field: 'name', filter: { type: 'string', contains: params.search } }));
        }

        if (params.field) {
            query.sort = new DataSort({ field: params.field, desc: params.desc });
        }

        if ((params.items && params.items.length) || (params.initial && params.initial.length)) {
            const uniquePredicate = (value, index, self) => self.indexOf(value) === index;

            const items = params.items.length ? params.items : params.initial;

            query.filters.push(new DataFilter({ field: 'subtype', filter: { type: 'string', in: items.map(x => x.subtype).filter(uniquePredicate) } }));
            query.filters.push(new DataFilter({ field: 'type', filter: { type: 'string', in: [] } }));
        }

        if (params.exclude?.length) {
            const uniquePredicate = (key, items) => Array.from(new Set(items.reduce((res, item) => [...res, ...item[key]], [])));

            params.exclude.some(i => !!i.type) &&
                query.filters.push(
                    new DataFilter({
                        filter: {
                            type: 'logic',
                            not: { field: 'type', filter: { type: 'string', in: uniquePredicate('type', params.exclude) } },
                        },
                    }),
                );

            params.exclude.some(i => !!i.subtype) &&
                query.filters.push(
                    new DataFilter({
                        filter: {
                            type: 'logic',
                            not: {
                                field: 'subtype',
                                filter: {
                                    type: 'string',
                                    in: uniquePredicate('subtype', params.exclude),
                                },
                            },
                        },
                    }),
                );
        }

        if (params.include?.length) {
            const uniquePredicate = (key, items) => Array.from(new Set(items.reduce((res, item) => [...res, ...item[key]], [])));
            query.filters.push(new DataFilter({ field: 'type', filter: { type: 'string', in: uniquePredicate('type', params.include) } }));
        }

        if (params.tags?.length) {
            query.filters.push(new DataFilter({ field: 'tags', filter: { type: 'array', all: params.tags } }));
        }

        return this.post(ApiUrls.resourcesFind, { customerId, query, folderId, global: !!params.search })
            .pipe(
                map(items => items.map((x: any) => new Resource({ ...x })))
            );
    }

    /**
     * Find deleted resources
     */
    public resourcesFindDeleted(query: DataQuery): Observable<Resource[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesFind, { customerId, mode: 'deleted', global: true, query });
    }

    /**
     * Find deleted resources by ids
     * @param ids Resources ids
     */
    public resourcesFindDeletedByIds(ids: string[]): Observable<Resource[]> {
        return this.resourcesFindDeleted(this.queryByIds(ids));
    }

    /**
     * Delete resources permanently
     * @param ids Resources ids
     * @returns Ids of deleted resources
     */
    public resourcesPurge(ids: string[]): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesPurge, new DeleteRequest({ ids, customerId }));
    }

    /**
     * Delete all customer resources permanently
     * @returns List of purged resources
     */
    public resourcesPurgeAll(): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesPurgeAll, { customerId });
    }

    /**
     * Gets resource depends by resourceId
     * @param resourceId
     */
    public resourcesDependencies(resourceId: string): Observable<{ resources: Resource[], campaigns: CampaignEntry[] }> {

        const customerId = this.authService.getCustomerId();

        const idsRequests = [
            { method: ApiUrls.resourcesResourcesDependencies, params: new DeleteRequest({ ids: [resourceId], customerId }) },
            { method: ApiUrls.campaignsResourcesDependencies, params: new DeleteRequest({ ids: [resourceId], customerId }) },
        ];

        return this.postBatch(idsRequests)
            .pipe(
                map((responses: Record<string, string[]>[]) => ({ resourceIds: responses[0][resourceId], campaignIds: responses[1][resourceId] })),
                switchMap(result => {

                    const objectsRquests = [
                        result.resourceIds?.length ? this.post(ApiUrls.resourcesFind, { query: this.queryByIds(result.resourceIds), global: true, customerId }) : of([]),
                        result.campaignIds?.length ? this.post(ApiUrls.campaignsFind, { query: this.queryByIds(result.campaignIds), customerId }) : of([]),
                    ];
    
                    return forkJoin(...objectsRquests)
                        .pipe(
                            map(responses => ({ resources: responses[0] || [], campaigns: responses[1] || [] }))
                        );
                })
            );
    }

    /**
     * Gets resource depends by resourceId
     * @param resourceId
     */
    public resourcesDependenciesByIds(ids: string[]): Observable<Record<string, { campaigns: CampaignEntry[], resources: Resource[] }>> {

        const customerId = this.authService.getCustomerId();

        const idsRequests = [
            { method: ApiUrls.resourcesResourcesDependencies, params: new DeleteRequest({ ids, customerId }) },
            { method: ApiUrls.campaignsResourcesDependencies, params: new DeleteRequest({ ids, customerId }) },
        ];

        return this.postBatch(idsRequests)
            .pipe(
                map((responses: Record<string, string[]>[]) => ({ resourceIdsMap: responses[0], campaignIdsMap: responses[1] })),
                switchMap((dependencies: { resourceIdsMap: Record<string, string[]>, campaignIdsMap: Record<string, string[]> }) => {

                    const campaignIds = Object.values(dependencies.campaignIdsMap).reduce((accumulator, array) => [...accumulator, ...array], []);
                    const resourceIds = Object.values(dependencies.resourceIdsMap).reduce((accumulator, array) => [...accumulator, ...array], []);

                    const objectsRequests = [
                        resourceIds.length ? this.post(ApiUrls.resourcesFind, { query: this.queryByIds(resourceIds), global: true, customerId }) : of([]),
                        campaignIds.length ? this.post(ApiUrls.campaignsFind, { query: this.queryByIds(campaignIds), customerId }) : of([]),
                    ];

                    return forkJoin(...objectsRequests)
                        .pipe(
                            map(([resources, campaigns]) => {
                                return ids.reduce((accumulator, id) => {
                                    accumulator[id] = {
                                        resources: resources.filter((r: Resource) => (dependencies.resourceIdsMap[id] || []).includes(r.id)),
                                        campaigns: campaigns.filter((c: CampaignEntry) => (dependencies.campaignIdsMap[id] || []).includes(c.id)),
                                    };
                                    return accumulator;
                                }, {});
                            })
                        );
                })
            );
    }

    /**
     * Updates resource depends by resourceId
     * @param resourceId
     */
    public resourcesUpdateDependencies(campaignRequest: UpdateReferenceRequest, resourceRequest: UpdateReferenceRequest): Observable<any> {
        const customerId = this.authService.getCustomerId();

        campaignRequest.customerId = customerId;
        resourceRequest.customerId = customerId;
        const requests = [];

        campaignRequest.ids?.length && requests.push({ method: ApiUrls.campaignsUpdatePlaylistReplace, params: campaignRequest });
        resourceRequest.ids?.length && requests.push({ method: ApiUrls.resourcesUpdateReference, params: resourceRequest });

        return this.postBatch(requests);
    }

    public resourcesUpdateReference(request: UpdateReferenceRequest): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesUpdateReference, { customerId, ...request });
    }

    public resourcesReadList(resourceIds: string[]): Observable<{ resourceId: string, mode: 'available' | 'unavailable' | 'deleted', resource: Resource }[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.resourcesReadList, { customerId, resourceIds });
    }

    private queryByIds(ids: string[]): DataQuery {
        return new DataQuery({
            filters: [
                new DataFilter({ field: 'id', filter: { type: 'id', in: unique(ids, x => x) } })
            ]
        });
    }
}
