import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { Select, Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { AuthService } from '@core/auth';
import { DataState, ApiService, DataActions, SystemNotifierService } from '@core/data';
import { environment } from '@environments/environment';
import { ObserverComponent } from '@domain/base';
import { User, PasswordUpdateRequest } from '@domain/users';
import { RoleRef, ADMIN_ROLE_NAME } from '@domain/roles';
import { IUploaderConfig } from '@modules/image-uploader/models';
import { MyProfileFormService } from '@my-profile/services';


enum Language {
    English = 'en',
    German = 'de'
}

@Component({
    selector: 'profile-info-panel',
    templateUrl: './profile-info-panel.component.html',
    styleUrls: ['./profile-info-panel.component.scss'],
})
export class ProfileInfoPanelComponent extends ObserverComponent implements OnInit {

    @Select(DataState.profile) profile$: Observable<User>;

    @Input() customerId: string;

    @Input() groups: string[];

    @Input() roles: RoleRef[];

    @Output() back: EventEmitter<any> = new EventEmitter();

    public profileForm: UntypedFormGroup;

    public passwordForm: UntypedFormGroup;

    public profile: User;

    public currentUser = this._authService.getCurrentUser();

    public accessToken: string = this._authService.getAuthToken();

    public isAdmin: boolean;

    public Language = Language;

    public selectedLanguage: Language;

    readonly uploaderConfig: IUploaderConfig = {
        readUrl: imageId =>
            environment.uploadAvatarUrl +
            '/' +
            this.customerId +
            '/' +
            imageId +
            '?access_token=' +
            this.accessToken,
        postAsFile: (file: File) => this._apiService.uploadAvatar(file),
    };

    constructor(
        private _formService: MyProfileFormService,
        private _apiService: ApiService,
        private _systemNotifierService: SystemNotifierService,
        private _authService: AuthService,
        private _store: Store,
        private _cdr: ChangeDetectorRef,
        private _translate: TranslateService
    ) {
        super();
        this.onChangeLanguage(this._translate.currentLang === Language.German ? Language.German : Language.English);
    }

    public ngOnInit(): void {
        this.isAdmin = this.currentUser.role && this.currentUser.role.name === ADMIN_ROLE_NAME;
        this.createGroups();
        this.subscribeOnChangeProfile();
    }

    public onSave(): void {
        if (this.profileForm.invalid) {
            Object.values(this.profileForm.controls).forEach((control: UntypedFormControl) => control.markAsTouched());
            return;
        }

        const model = { ...this.profile, ...this.profileForm.value };

        this._store.dispatch(new DataActions.UpdateProfile(model)).subscribe(() => {
            this.back.emit();

            this.profileForm.markAsPristine();
            this.profileForm.markAsUntouched();
            this.showSuccessMessage();
        });
    }

    public onChangePassword(): void {
        if (this.passwordForm.invalid) {
            Object.values(this.passwordForm.controls).forEach((control: UntypedFormControl) => control.markAsTouched());
            return;
        }

        const request = new PasswordUpdateRequest({ 
            ...this.passwordForm.value, 
            userId: this.currentUser.id,
            customerId: this.currentUser.customerId
        });
        this._store.dispatch(new DataActions.ChangePassword(request)).subscribe(() => {
            this.passwordForm.reset();
        });
    }

    public onChangeLanguage(langId: Language): void {
        this.selectedLanguage = langId;
        localStorage.setItem('lang', this.selectedLanguage);
        this._translate.use(this.selectedLanguage);
    }

    private createGroups(): void {
        this.passwordForm = this._formService.createPasswordForm(new PasswordUpdateRequest());
        this.profileForm = this._formService.createProfileForm(new User());
    }

    private subscribeOnChangeProfile(): void {
        const subscription = this.profile$.subscribe(profile => {
            this.profile = profile;
            this.profileForm = this._formService.createProfileForm(profile);
            if (!this.isAdmin) {
                this._formService.disableCompanyControls(this.profileForm);
            }

            this._cdr.markForCheck();
        });

        this.subscriptions.push(subscription);
    }

    private showSuccessMessage(): void {
        const message = this._translate.instant('MESSAGES.SUCCESS');
        this._systemNotifierService.success(message);
    }
}
