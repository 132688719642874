import { Pipe, PipeTransform } from '@angular/core';

import { groupBy } from '@domain/utils';


@Pipe({
    name: 'groupBy',
    pure: true,
})
export class GroupByPipe implements PipeTransform {

    public transform(items: any[], property: string): Map<any, any[]> {
        return groupBy(items, property);
    }
}
