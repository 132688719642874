import { Breakpoint, breakpoints } from './breakpoints.config';
import { ColumnConfig } from './column.config';

export type ColumnsConfigKeys = 'PlayersColumns' | 'WapsColumns' | 'LocationsColumns' | 'CampaignsColumns' | 'SensorsColumns' | 'ActionsColumns';

export class TableConfig {

    public static columns(key: ColumnsConfigKeys): { breakpoint: Breakpoint, name: string }[] {
        return ([breakpoints.Web, breakpoints.Tablet, breakpoints.Handset] as Breakpoint[])
            .map(breakpoint => ({ breakpoint, name: TableConfig.columnsKey(key, breakpoint) }));
    }

    public static columnsKey(key: ColumnsConfigKeys, breakpoint: Breakpoint): string {
        return `${breakpoint}${key}`;
    }

    public static mergeColumns(defaultColumns: ColumnConfig[], currentColumns: ColumnConfig[]): ColumnConfig[] {
        if (!currentColumns) {
            return defaultColumns;
        }

        return TableConfig.merge(defaultColumns, currentColumns || []);
    }

    private static merge(origin: ColumnConfig[], current: ColumnConfig[]): ColumnConfig[] {

        current = current || [];

        const count = origin.length;

        return origin.map((column, i) => {

            const index = current.findIndex(x => x.name === column.name);

            if (index < 0) {
                return ({ index: count + i, column: new ColumnConfig({ ...column }) });
            }

            return ({
                index,
                column: new ColumnConfig({ ...column, width: current[index].width, visible: current[index].visible })
            });
        })
            .sort((a, b) => (a.index > b.index) ? 1 : -1)
            .map(x => x.column);
    }
}
