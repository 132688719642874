import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    ElementRef,
    ViewChild,
    ChangeDetectionStrategy,
    OnChanges,
    SimpleChanges,
    HostBinding,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ObserverComponent } from '@domain/base';

@Component({
    selector: 'page-search',
    templateUrl: './page-search.component.html',
    styleUrls: ['./page-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSearchComponent extends ObserverComponent implements OnInit, OnChanges {

    @Input() isSearchOpened: boolean;

    @Input() debounce: number = 400;

    @Input() searchTerm: string;

    @Input() clearSearch: boolean = false;

    @HostBinding('class')  @Input() theme: 'white' | 'dark' = 'dark';

    @Output() search = new EventEmitter<string>();

    @ViewChild('search') searchElement: ElementRef;

    public searchField: UntypedFormControl = new UntypedFormControl();

    public ngOnInit(): void {
        this.searchField.setValue(this.searchTerm);
        this.isSearchOpened = this.isSearchOpened || !!this.searchTerm;

        this.subscriptions.push(
            this.searchField.valueChanges
                .pipe(debounceTime(this.debounce), distinctUntilChanged())
                .subscribe(term => {
                    this.search.emit(term);
                }),
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('clearSearch' in changes) {
            this.clearSearch && this.onClear();
        }
    }

    public onClear(): void {
        this.searchField.setValue(null);
        this.isSearchOpened = false;
    }

    public onFocusout(): void {
        if (!this.searchField.value) {
            this.isSearchOpened = false;
        }
    }

    public onOpen(): void {
        this.isSearchOpened = true;
        this.setFocus();
    }

    private setFocus(): void {
        setTimeout(() => {
            this.searchElement.nativeElement.focus();
        });
    }
}
