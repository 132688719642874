import { BaseEntry } from '@domain/base';

export enum Severity {
    TRACE = 'TRACE',
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR'
}

export class ErrorEntry extends BaseEntry<ErrorEntry> {
    public severity: Severity;
    public source: string;
    public payload: ErrorPayload;
}

export class ErrorPayload extends BaseEntry<ErrorPayload> {
    public message: string;
    public code: number;
    public name: string;
    public stack: string;
    public location: string;
    public status: number;
    public userAgent: string;
}
