import { Input, Directive } from '@angular/core';

import * as moment from 'moment';

@Directive()
export abstract class BaseChartComponent<T = any> {

    private _data: T;
    public get data(): T {
        return this._data;
    }
    @Input()
    public set data(value: T) {
        this._data = value;
    }

    public xAxis = true;

    public yAxis = true;

    public showGridLines = true;

    public showLegend = true;

    public legend = true;

    public animations = false;

    public colorScheme = {
        name: 'vivid',
        selectable: true,
        group: 'Ordinal',
        domain: ['#feb640', '#73d131', '#0eb9c6', '#7e5ebf', '#ff369c', '#4348ff'],
    };

    public xAxisDateTickFormatting(value: Date): string {
        if (!value) {
            return value as any;
        }

        const hours = value.getHours();
        return moment(value, 'YYYYMMDD').format(hours ? 'DD.MM - HH:mm' : 'DD.MM');
    }

    public axisCellNumberFormat(value: number) {
        return value % 1 === 0 ? value.toLocaleString() : '';
    }

}
