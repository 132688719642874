import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';


import { NotificationType, NotificationMessage } from '../../shared';


@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {

    public readonly messages: string[] = ['MESSAGES.SUCCESS', 'MESSAGES.ERROR', 'MESSAGES.INFO', 'MESSAGES.WARNING'];

    public readonly NotificationType = NotificationType;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: NotificationMessage,
        public snackBarRef: MatSnackBarRef<NotificationComponent>,
    ) { }
}
