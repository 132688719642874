declare type Selector<T = any> = (value?: T) => any;

/**
 * Appends a target items into source with removing duplicates in source.
 * @param items The items which should be appended into source.
 * @param compareBy The key for comparing items.
 */
export function mergeUnique<T>(items: T[], compareBy: Selector<T>) {

    return (existing: Readonly<T[]>): T[] => {

        if (!items || !items.length) {
            return existing as T[];
        }

        const keys = (items || []).map(compareBy);
        const filteredItems = existing.filter(item => !keys.includes(compareBy(item)));

        return filteredItems.concat(items);
    };

}
