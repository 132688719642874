import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CampaignSchedule, CampaignSchedule2Entry } from '@domain/campaigns';
import { notEmptyArrayRequired } from '@domain/validators';

export interface CampaignScheduleForm {
    id: FormControl<string>;
    name: FormControl<string>;
    notes: FormControl<string>;
    version: FormControl<number>;
    schedules2: FormArray<FormControl<CampaignSchedule2Entry>>;
}

export class SchedulesCreator {

    constructor(private _fb: FormBuilder) { }

    public campaignScheduleFormGroup(schedule: CampaignSchedule): FormGroup<CampaignScheduleForm> {
        return this._fb.group<CampaignScheduleForm>({
            id: this._fb.control<string>(schedule.id),
            name: this._fb.control<string>(schedule.name, [Validators.required]),
            notes: this._fb.control<string>(schedule.notes),
            version: this._fb.control<number>(schedule.version),
            schedules2: this._fb.array((schedule.schedules2 || []).map(entry => this._fb.control<CampaignSchedule2Entry>(entry)), [notEmptyArrayRequired]),
        });
    }
}
