import { ObjectAction } from '../roles/object-actions.model';
import { BaseEntry } from '../base/base-entry.model';
import { CampaignPlaylistEntry } from './campaign-playlist-entry.model';
import { CampaignSchedule2Entry } from './campaign-schedule2-entry.model';
import { CampaignTargetEntry } from './campaign-target-entry.model';
import { CampaignTargetType } from './campaign-target-type.enum';


export class Campaign extends BaseEntry<Campaign> {
    public afterConnect: boolean;
    public beforeConnect: boolean;
    public color: string;
    public customerId: string;
    public enabled: boolean;
    public group: string;
    public id: string;
    public name: string;
    public notes: string;
    public offline: boolean;
    public actionOnly: boolean;
    public playlist: CampaignPlaylistEntry[];
    public schedules2: CampaignSchedule2Entry[];
    public shuffle: boolean;
    public skipAfterSeconds: number;
    public targets: CampaignTargetEntry[];
    public type: string;
    public targetingOnly: boolean;
    public version: number;
    public targetType: CampaignTargetType;
    public objectActions: ObjectAction[];
}
