import { BaseEntry } from '../base/base-entry.model';

export class KioskProperties extends BaseEntry<KioskProperties> {
    public backgroundColor: string;
    public color: string;
    public navigator: boolean;
    public position: KioskPosition;
    public size: KioskSize;
}

export enum KioskPosition {
    top = 'top',
    bottom = 'bottom',
}

export const kioskPositions = [
    KioskPosition.top,
    KioskPosition.bottom,
];


export enum KioskSize {
    small = 'small',
    normal = 'normal',
    large = 'large',
}

export const kioskSizes = [
    KioskSize.small,
    KioskSize.normal,
    KioskSize.large,
];
