import { ColumnConfig } from './column.config';
import { Breakpoint, breakpoints } from './breakpoints.config';

export const DefaultCampaignsColumnsConfig = (breakpoint: Breakpoint) => ({

    [breakpoints.Web]: [
        new ColumnConfig({ name: 'preview', width: 100, visible: true }),
        new ColumnConfig({ name: 'name', width: 160, visible: true }),
        new ColumnConfig({ name: 'group', width: 60, visible: true }),
        new ColumnConfig({ name: 'duration', width: 60, visible: true }),
        new ColumnConfig({ name: 'lastModifiedDate', width: 60, visible: true }),
        new ColumnConfig({ name: 'lastModifiedBy', width: 60, visible: true }),
        new ColumnConfig({ name: 'status', width: 60, visible: true }),
        new ColumnConfig({ name: 'enabled', width: 60, visible: true }),
    ],

    [breakpoints.Tablet]: [
        new ColumnConfig({ name: 'preview', width: 100, visible: true }),
        new ColumnConfig({ name: 'name', width: 160, visible: true }),
        new ColumnConfig({ name: 'group', width: 60, visible: true }),
        new ColumnConfig({ name: 'duration', width: 60, visible: true }),
        new ColumnConfig({ name: 'lastModifiedDate', width: 60, visible: true }),
        new ColumnConfig({ name: 'lastModifiedBy', width: 60, visible: true }),
        new ColumnConfig({ name: 'status', width: 60, visible: true }),
        new ColumnConfig({ name: 'enabled', width: 60, visible: true }),
    ],

    [breakpoints.Handset]: [
        new ColumnConfig({ name: 'preview', width: 100, visible: true }),
        new ColumnConfig({ name: 'name', width: 160, visible: true }),
        new ColumnConfig({ name: 'group', width: 60, visible: true }),
        new ColumnConfig({ name: 'duration', width: 60, visible: true }),
        new ColumnConfig({ name: 'lastModifiedDate', width: 60, visible: true }),
        new ColumnConfig({ name: 'lastModifiedBy', width: 60, visible: true }),
        new ColumnConfig({ name: 'status', width: 60, visible: true }),
        new ColumnConfig({ name: 'enabled', width: 60, visible: true }),
    ]

})[breakpoint];
