import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'formatTime' })
export class FormatTimePipe implements PipeTransform {
    transform(value: number, params?: 'start'): string {
        if (value === null || value === undefined) {
            if (params === 'start') {
                return moment()
                    .startOf('day')
                    .format('HH:mm');
            }

            return moment()
                .endOf('day')
                .format('HH:mm');
        }

        return moment(value.toString().padStart(4, '0'), 'HHmm').format('HH:mm');
    }
}

@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {
    transform(value: number, emptyValue: string = ''): string {
        if (value === null || value === undefined) {
            return emptyValue;
        }

        return moment(value, 'YYYYMMDD').format('DD.MM.YYYY');
    }
}
