import { BaseEntry } from '../base/base-entry.model';
import { Ticker } from './ticker.model';

export class Player extends BaseEntry<Player> {
    public customerId: string;
    public player: string;
    public enabled: boolean;
    public facility: string;
    public group: string;
    public id: string;
    public locationId: string;
    public name: string;
    public notes: string;
    public scheduleId: string;
    public type: string;
    public ticker: Ticker;
    public overlay: Overlay;
    public kiosk: Kiosk;
    public rebootSchedule: RebootSchedule;
    public tags: string[];
    public version: number;
    public audited: boolean;
}

export class Overlay extends BaseEntry<Overlay> {
    public resourceId: string;
}


export class Kiosk extends BaseEntry<Kiosk> {
    public resourceId: string;
    public timeout: number;
}

export class RebootSchedule extends BaseEntry<RebootSchedule> {
    public clear: boolean;
    public time: number;
    public weekdays: string[];
}

export enum PlayerProperty {
    locationId = 'locationId',
    kiosk = 'kiosk',
    group = 'group',
    tags = 'tags',
    rebootSchedule = 'rebootSchedule',
    ticker = 'ticker',
    overlay = 'overlay',
    scheduleId = 'scheduleId',
}

export enum PlayerType {
    generic = 'generic',
    audio = 'audio',
}
