<h1 mat-dialog-title [innerHtml]="data.title || ('DIALOG.CONFIRM' | translate)"></h1>

<mat-dialog-content class="mat-typography">

    <div [innerHtml]="data.text"></div>

    <section class="items" *ngIf="data.items?.length">
        <span class="item" *ngFor="let item of data.items">{{ item }}</span>    
    </section>

</mat-dialog-content>

<mat-dialog-actions>
    <ng-container *ngIf="data.withCancel">
        <button mat-flat-button color="accent" type="button"
            [mat-dialog-close]="null">{{ 'BUTTONS.CANCEL' | translate }}</button>
        <div class="spacer"></div>
    </ng-container>

    <button mat-button color="accent" type="button" [mat-dialog-close]="false">{{ 'BUTTONS.NO' | translate }}</button>
    <div class="spacer" *ngIf="!data.withCancel"></div>
    <button mat-flat-button color="accent" type="button"
        [mat-dialog-close]="true">{{ 'BUTTONS.YES' | translate }}</button>
</mat-dialog-actions>