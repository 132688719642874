import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pipeWrapper',
})
export class PipeWrapperPipe implements PipeTransform {
    public transform(value: any, pipe?: PipeTransform): any {
        if (value != null) {
            return pipe && pipe.transform ? pipe.transform(value) : value;
        } else {
            return value;
        }
    }
}
