import { ColumnConfig } from './column.config';
import { Breakpoint, breakpoints } from './breakpoints.config';

export const DefaultLocationsColumnsConfig = (breakpoint: Breakpoint) => ({

    [breakpoints.Web]: [
        new ColumnConfig({ name: 'name', width: 160, visible: true }),
        new ColumnConfig({ name: 'address', width: 120, visible: true }),
        new ColumnConfig({ name: 'timezone', width: 60, visible: true }),
        new ColumnConfig({ name: 'hotspotName', width: 60, visible: true }),
        new ColumnConfig({ name: 'playersCount', width: 60, visible: true }),
        new ColumnConfig({ name: 'wapsCount', width: 60, visible: true }),
    ],

    [breakpoints.Tablet]: [
        new ColumnConfig({ name: 'name', width: 160, visible: true }),
        new ColumnConfig({ name: 'address', width: 120, visible: true }),
        new ColumnConfig({ name: 'timezone', width: 60, visible: true }),
        new ColumnConfig({ name: 'hotspotName', width: 60, visible: true }),
        new ColumnConfig({ name: 'playersCount', width: 60, visible: false }),
        new ColumnConfig({ name: 'wapsCount', width: 60, visible: false }),
    ],

    [breakpoints.Handset]: [
        new ColumnConfig({ name: 'name', width: 160, visible: true }),
        new ColumnConfig({ name: 'address', width: 120, visible: true }),
        new ColumnConfig({ name: 'timezone', width: 60, visible: false }),
        new ColumnConfig({ name: 'hotspotName', width: 60, visible: false }),
        new ColumnConfig({ name: 'playersCount', width: 60, visible: false }),
        new ColumnConfig({ name: 'wapsCount', width: 60, visible: false }),
    ]

})[breakpoint];
