
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { SystemNotifierService } from '../services/system-notifier.service';


export interface IHaveChanges {
    haveChanges: () => Observable<boolean> | boolean;
    onConfirmSave: () => Observable<boolean>;
}

@Injectable()
export class UnsavedChangesGuard<T extends IHaveChanges>  {
    constructor(
        private translateService: TranslateService,
        private systemNotifierService: SystemNotifierService,
    ) { }

    public canDeactivate(component: T) {
        if (!component.haveChanges()) {
            return true;
        }

        return this.systemNotifierService
            .confirm({
                text: this.translateService.instant('MESSAGES.CONFIRM_SUBTITLE'),
            })
            .pipe(
                switchMap((result: boolean) => {
                    if (!result) {
                        return of(true);
                    }

                    return component.onConfirmSave();
                }),
            );
    }
}
