import { Predicate } from '@ngxs/store/operators';
import { FoldersTree, Folder } from '@domain/resources';

interface TreeNode<T extends TreeNode<T>> {
    children: TreeNode<T>[];
}

export class Tree {

    public static treeToArray<T extends TreeNode<T>>(items: TreeNode<T>[]) {
        const arr: TreeNode<T>[] = [];

        function toArray<U extends TreeNode<U>>(nodes: TreeNode<U>[]): void {
            if (!nodes) {
                return;
            }

            for (const node of nodes) {
                arr.push(node);
                toArray(node.children);
            }
        }

        toArray(items);

        return arr;
    }

    public static findNodeByPath(node: FoldersTree, path: string) {
        const paths = path.split('/').filter(x => !!x);
        const folders = paths.filter((p, index) => index < paths.length - 1);

        let currentNode: any = { ...node };

        for (const folder of folders) {
            currentNode = { ...currentNode.children.find(child => child.name === folder) };
        }

        return currentNode;
    }

    public static foldersFromTree(foldersTree: FoldersTree[], rootId: string): Folder[] {
        const folders: Folder[] = [];

        toArray(foldersTree, rootId);

        return folders;

        function toArray(nodes: FoldersTree[], folderId: string): void {
            if (!nodes) {
                return;
            }

            for (const { id, name, children } of nodes) {
                folders.push(new Folder({ id, name, folderId }));

                toArray(children, id);
            }
        }
    }

    public static hasChildren<T extends TreeNode<T>>(node: TreeNode<T>) {
        return node.children && node.children.length;
    }

    public static findNode<T extends TreeNode<T>>(nodes: TreeNode<T>[], predicate: (node: TreeNode<T>) => boolean): T {

        const stack = [...nodes];

        while (stack.length > 0) {
            const node = stack.pop();
            if (predicate(node)) {
                return node as T;
            } else if (Tree.hasChildren(node)) {
                stack.push(...node.children);
            }
        }

        return null;
    }

}

export function appendTreeNodes<T extends TreeNode<T>>(nodes: TreeNode<T>[], predicate: Predicate<TreeNode<T>>) {

    return (existing: TreeNode<T>[]): Readonly<TreeNode<T>[]> => {

        const copiedExisting = [...existing];

        copiedExisting.forEach((node, i) => {
            copiedExisting[i] = { ...node };
            if (traverseNode(copiedExisting[i], predicate, appendNodes(nodes))) {
                return;
            }
        });

        return copiedExisting;
    };
}

export function removeTreeNodes<T extends TreeNode<T>>(predicate: Predicate<T>) {

    return (existing: TreeNode<T>[]): Readonly<TreeNode<T>[]> => {

        const copiedExisting = [...existing];

        copiedExisting.forEach((node, i) => {
            copiedExisting[i] = { ...node };
            if (traverseNode(copiedExisting[i], predicate, removeNodes(predicate))) {
                return;
            }
        });

        return copiedExisting;
    };
}

export function nodeChildren<T extends TreeNode<T>>(predicate: Predicate<T>) {
    return (nodes: TreeNode<T>[]): Readonly<TreeNode<T>[]> => {
        const node = Tree.findNode(nodes, predicate);
        return node?.children || [];
    };
}

function appendNodes<T extends TreeNode<T>>(nodes: TreeNode<T>[]) {
    return (node: TreeNode<T>): void => {
        const children = node.children || [];
        node.children = [...children, ...nodes];
    };
}

function removeNodes<T extends TreeNode<T>>(predicate: Predicate<TreeNode<T>>) {
    return (node: TreeNode<T>): void => {
        node.children = (node.children || []).filter(predicate);
    };
}

function traverseNode<T extends TreeNode<T>>(node: TreeNode<T>, predicate: Predicate<TreeNode<T>>, applyFn: (node: TreeNode<T>) => void): boolean {

    if (predicate(node)) {
        applyFn(node);
        return true;
    }

    if (!Tree.hasChildren(node)) {
        return false;
    }

    for (const child of node.children) {
        return traverseNode(child, predicate, applyFn);
    }
}




