import { Pipe, PipeTransform } from '@angular/core';

import { HotkeyMapper } from './hotkey.mapper';


@Pipe({ name: 'hotkey' })
export class HotkeyPipe implements PipeTransform {
    public transform(value: string, keys: string): string {
        if (value && keys) {
            const name = this.getName(keys);
            return `${value} (${name})`;
        }

        return this.getName(value);
    }

    private getName(keys: string): string {
        return keys.split('.').map(key => HotkeyMapper.systemMap[key] || key)
            .map(key => HotkeyMapper.symbolsMap[key] || key).join('+');
    }
}
