import { Directive, EventEmitter, Output, OnInit, ElementRef, NgZone, OnDestroy, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { ObserverComponent } from '@domain/base';


@Directive({
    selector: '[resizableContent]'
})
export class ResizableContentDirective extends ObserverComponent implements OnInit, OnDestroy {

    @Input() debounceTime: number = 0;

    @Output() resizableContent: EventEmitter<number> = new EventEmitter();

    public width: number;

    private observer: ResizeObserver;

    private debounce$: Subject<any> = new Subject<any>();

    constructor(private host: ElementRef, private zone: NgZone) {
        super();
    }

    public ngOnInit(): void {
        this.subscribeOnDebounce();
        this.subscribeOnWidth();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.observer.unobserve(this.host.nativeElement);
    }

    private subscribeOnWidth(): void {
        this.observer = new ResizeObserver(entries => {
            this.zone.run(() => {
                this.width = entries[0].contentRect.width;
                this.debounce$.next(this.width);
            });
        });
        this.observer.observe(this.host.nativeElement);
    }

    private subscribeOnDebounce(): void {
        const subscription = this.debounce$
            .pipe(
                debounceTime(this.debounceTime),
                distinctUntilChanged()
            )
            .subscribe((value) => this.resizableContent.emit(value));

        this.subscriptions.push(subscription);
    }
}
