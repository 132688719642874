import { Directive, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class TranslateObserver implements OnDestroy {

    private langChangeSubscription: Subscription;

    constructor(public translate: TranslateService) {
        this.onLangChange();
        this.langChangeSubscription = this.translate.onLangChange
            .subscribe(() => this.onLangChange());
    }

    public ngOnDestroy(): void {
        this.langChangeSubscription && this.langChangeSubscription.unsubscribe();
    }

    protected abstract onLangChange();

}
