// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    hmr: false,
    ga: '',
    apiUrl: 'https://api.use2go.dev/v1',
    cdnUrl: 'https://cdn.use2go.dev/v1/file/',
    previewUrl: 'https://cdn.use2go.dev/v1/preview',
    downloadUrl: 'https://cdn.use2go.dev/v1/download',
    assetsUrl: 'https://cdn.use2go.dev/pub/',
    campaignUrl: 'https://cdn.use2go.dev/v1/campaign/',
    resourceUrl: 'https://cdn.use2go.dev/v1/resource/',
    uploadUrl: 'https://upload.use2go.dev/v1',
    uploadAvatarUrl: 'https://api.use2go.dev/v1/files',
    incidentUrl: 'https://incident.use2go.dev/v1/log',
    googleApiKey: 'AIzaSyCL8C-cVkMNLD2lh38ZVdduTftw7QyICXU',
    youtubeApiUrl: 'https://www.googleapis.com/youtube/v3/',
    facebookAppId: '418721025617024',
    auth: {
        authority: 'https://auth.use2go.dev/oauth/authorize',
        logout: 'https://auth.use2go.dev/logout',
        client_id: 'webapp',
        redirect_uri: 'http://localhost/auth-callback',
        post_logout_redirect_uri: 'http://localhost/',
        response_type: 'token',
        scope: 'api',
        filterProtocolClaims: true,
        loadUserInfo: true,
    },

    userStorageKey: 'currentUserId',
    currentUser: 'currentUser',
};
