<header>
    <div (click)="onGoToDashboard()" class="logo">
        <img class="logo-icon" src="assets/images/logos/main-logo-v2-dark.png" alt="main logo">
    </div>

    <button mat-icon-button (click)="toggleMenu.emit()">
        <mat-icon>menu</mat-icon>
    </button>
</header>

<mat-accordion displayMode="flat">
    <mat-nav-list class="hidden-scrollbar">
        <ng-container *ngFor="let menu of navigation; index as i;"
            [ngTemplateOutlet]="menu.children ? childMenu : parentMenu" [ngTemplateOutletContext]="{ menu : menu }">
        </ng-container>
    </mat-nav-list>

    <ng-template #parentMenu let-menu="menu">
        <mat-list-item matRipple [routerLink]="menu.url" routerLinkActive="active">
            <mat-icon class="navigation-icon" matListItemIcon>{{menu.icon}}</mat-icon>
            <a matListItemTitle class="navigation-title">{{ menu.translate | translate }}</a>
        </mat-list-item>
    </ng-template>
    
    <ng-template #childMenu let-menu="menu">
        <mat-expansion-panel [class.mat-elevation-z0]="true" #panel>
            <mat-expansion-panel-header (click)="onHeaderClick(panel)">
                <mat-icon class="navigation-icon">{{ menu.icon }}</mat-icon>
                <a class="navigation-title">{{ menu.translate | translate }}</a>
            </mat-expansion-panel-header>
    
            <ng-container *ngIf="!collapsed">
                <mat-nav-list>
                    <mat-list-item matRipple [routerLink]="submenu.url" *ngFor="let submenu of menu.children" routerLinkActive="active">
                        <a matListItemTitle class="navigation-title">{{ submenu.translate | translate  }}</a>
                    </mat-list-item>
                </mat-nav-list>
            </ng-container>
        </mat-expansion-panel>
    </ng-template>
</mat-accordion>

<footer *ngIf="version" class="ellipsis">{{ version }}</footer>
