declare type Selector<T = any> = (value?: T) => any;

/**
 * Updates the list of items in the source list by the provided key.
 * @param items The items with should be updated in the  source list.
 * @param compareBy The key for comparing items. 
 */
export function updateItems<T>(items: T[], compareBy: (value?: T) => any) {

    return (existing: Readonly<T[]>): T[] => {

        if (!items || !items.length) {
            return existing as T[];
        }

        if (Array.isArray(existing)) {
            const existingWithReplaced = existing.slice();

            items.forEach(item => {

                const index = existingWithReplaced.findIndex(f => compareBy(f) === compareBy(item));
                if (index > -1) {
                    existingWithReplaced[index] = item;
                }

            });

            return existingWithReplaced;
        }

        return items;
    };

}
