import { Injectable } from '@angular/core';

@Injectable()
export class FileLoaderService {
    loadFile(fileSrc: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const image = new Image();

            image.onload = () => {
                resolve(fileSrc);
            };

            image.onerror = err => {
                reject(err);
            };

            image.setAttribute('src', fileSrc);
        });
    }
}
