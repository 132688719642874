export enum MatchOperator {
    eq = 'eq',
    every = 'every',
    from = 'from',
    ge = 'ge',
    gt = 'gt',
    le = 'le',
    lt = 'lt',
    to = 'to'
}
