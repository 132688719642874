import { Injectable } from '@angular/core';
import { RouterStateSnapshot, Params } from '@angular/router';

import { RouterStateSerializer } from '@ngxs/router-plugin';


interface RouterStateParams {
    url: string;
    params: Params;
    queryParams: Params;
}

@Injectable()
export class AppRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
    serialize(routerState: RouterStateSnapshot): RouterStateParams {
        const { url, root: { queryParams } } = routerState;

        let { root: route } = routerState;
        while (route.firstChild) {
            route = route.firstChild;
        }

        const { params } = route;

        return { url, params, queryParams };
    }
}
