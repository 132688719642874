export const ApiUrls = {
    actionsCount: 'actions_count',
    actionsFind: 'actions_find',
    actionsCreate: 'actions_create',
    actionsRead: 'actions_read',
    actionsDelete: 'actions_delete',
    actionsUpdate: 'actions_update',
    actionsGroups: 'actions_groups',
    actionsEnable: 'actions_enable',

    campaignsGroups: 'campaigns_groups',
    campaignsGroupRename: 'campaigns_group_rename',
    campaignsFind: 'campaigns_find',
    campaignsUpdateName: 'campaigns_update_name',
    campaignsUpdateGroup: 'campaigns_update_group',
    campaignsDelete: 'campaigns_delete',
    campaignsEnable: 'campaigns_enable',
    campaignsCreate: 'campaigns_create',
    campaignsRead: 'campaigns_read',
    campaignsUpdate: 'campaigns_update',
    campaignsScheduleAdd: 'campaigns_schedule_add',
    campaignsScheduleRemove: 'campaigns_schedule_remove',
    campaignsScheduleUpdate: 'campaigns_schedule_update',
    campaignsSchedulesCount: 'campaigns_schedules_count',
    campaignsSchedulesFind: 'campaigns_schedules_find',
    campaignsSchedulesCreate: 'campaigns_schedules_create',
    campaignsSchedulesRead: 'campaigns_schedules_read',
    campaignsSchedulesUpdate: 'campaigns_schedules_update',
    campaignsSchedulesDelete: 'campaigns_schedules_delete',
    campaignsCalendar2: 'campaigns_calendar2',
    campaignsForPlayer: 'campaigns_for_player',
    campaignsUpdateSchedule: 'campaigns_update_schedule',
    campaignsBuildPlaylist: 'campaigns_build_playlist',
    campaignsFindByReference: 'campaigns_find_by_reference',
    campaignsUpdatePlaylistAdd: 'campaigns_update_playlist_add',
    campaignsUpdatePlaylistRemove: 'campaigns_update_playlist_remove',
    campaignsUpdateTargetsAdd: 'campaigns_update_targets_add',
    campaignsUpdateTargetsRemove: 'campaigns_update_targets_remove',
    campaignsResourcesDependencies: 'campaigns_resources_dependencies',
    campaignsUpdatePlaylistReplace: 'campaigns_update_playlist_replace',
    metaTargetsTree: 'meta_targets_tree',

    customersRead: 'customers_read',
    customersTree: 'customers_tree',
    customersCreate: 'customers_create',
    customersUpdate: 'customers_update',
    customersDelete: 'customers_delete',
    customersMe: 'customers_me',
    customersTier: 'customers_tier',
    customersUpdateTier: 'customers_update_tier',
    customersChildren: 'customers_children',
    customersParent: 'customers_parent',
    customersGetConfigs: 'customers_get_configs',
    customersPutConfigs: 'customers_put_configs',

    dashboardsChartFeedback: 'dashboards_chart_feedback',
    dashboardsListFeedback: 'dashboards_list_feedback',
    dashboardsCountFeedback: 'dashboards_count_feedback',
    dashboardsCountSession: 'dashboards_count_session',
    dashboardsChartSession: 'dashboards_chart_session',
    dashboardsCountOnline: 'dashboards_count_online',
    dashboardsCountVisitor: 'dashboards_count_visitor',
    dashboardsCountDwelltime: 'dashboards_count_dwelltime',
    dashboardsCountCampaign: 'dashboards_count_campaign',
    dashboardsCountResource: 'dashboards_count_resource',
    dashboardsCountDemographics: 'dashboards_count_demographics',
    dashboardsCountLogin: 'dashboards_count_login',
    dashboardsChartPeopleCount: 'dashboards_chart_people_count',
    dashboardsChartPeopleOccupancy: 'dashboards_chart_people_occupancy',

    locationsFind: 'locations_find',
    locationsGroups: 'locations_groups',
    locationsTags: 'locations_tags',
    locationsUpdateHotspot: 'locations_update_hotspot',
    locationsUpdateTerms: 'locations_update_terms',
    locationsRead: 'locations_read',
    locationsUpdate: 'locations_update',
    locationsCreate: 'locations_create',
    locationsDelete: 'locations_delete',
    locationsFindCity: 'locations_find_city',

    playersReadTree: 'players_read_tree',
    playersRead: 'players_read',
    playersGroups: 'players_groups',
    playersFind: 'players_find',
    playersTags: 'players_tags',
    playersUpdateLocation: 'players_update_location',
    playersCache: 'players_cache',
    playersLog: 'players_log',
    playersSchedulesFind: 'players_schedules_find',
    playersStatus: 'players_status',
    playersDelete: 'players_delete',
    playersRebind: 'players_rebind',
    playersStatistics: 'players_statistics',
    playersReadRotation: 'players_read_rotation',
    playersReadVolume: 'players_read_volume',
    playersUpdate: 'players_update',
    playersCreate: 'players_create',
    playersControlMulti: 'players_control_multi',
    playersSchedulesDelete: 'players_schedules_delete',
    playersSchedulesUpdate: 'players_schedules_update',
    playersSchedulesCreate: 'players_schedules_create',
    playersControl: 'players_control',
    playersRotate: 'players_rotate',
    playersCapture: 'players_capture',
    playersEnable: 'players_enable',
    playersUpdateProperty: 'players_update_property',
    updatesCreateAction: 'updates_create_action',

    resourcesRead: 'resources_read',
    resourcesTags: 'resources_tags',
    resourcesReadTree: 'resources_read_tree',
    resourcesReadFoldersTree: 'resources_read_folders_tree',
    resourcesFind: 'resources_find',
    resourcesQuota: 'resources_quota',
    resourcesCreate: 'resources_create',
    resourcesCreateFolder: 'resources_create_folder',
    resourcesCreateFolders: 'resources_create_folders',
    resourcesDeleteFolders: 'resources_delete_folders',
    resourcesUndeleteFolders: 'resources_undelete_folders',
    resourcesDelete: 'resources_delete',
    resourcesUndelete: 'resources_undelete',
    resourcesPurge: 'resources_purge',
    resourcesPurgeAll: 'resources_purge_all',
    resourcesUpdateFolder: 'resources_update_folder',
    resourcesUpdate: 'resources_update',
    resourcesClone: 'resources_clone',
    resourcesMove: 'resources_move',
    resourcesMoveFolders: 'resources_move_folders',
    resourcesUpdateExpiration: 'resources_update_expiration',
    resourcesUpdateMetadata: 'resources_update_metadata',
    resourcesDeleteTemplate: 'resources_delete_template',
    resourcesUpdateTemplate: 'resources_update_template',
    resourcesReadTemplate: 'resources_read_template',
    resourcesCreateTemplate: 'resources_create_template',
    resourcesFindTemplates: 'resources_find_templates',
    resourcesResourcesDependencies: 'resources_resources_dependencies',
    resourcesUpdateReference: 'resources_update_reference',
    resourcesReadList: 'resources_read_list',

    rolesFind: 'roles_find',
    rolesDelete: 'roles_delete',
    rolesRead: 'roles_read',
    rolesCreate: 'roles_create',
    rolesUpdate: 'roles_update',

    usersUiConfig: 'users_ui_config',
    usersMe: 'users_me',
    usersFind: 'users_find',
    usersRead: 'users_read',
    usersDelete: 'users_delete',
    usersGroups: 'users_groups',
    usersUpdate: 'users_update',
    usersCreate: 'users_create',
    usersUpdatePassword: 'users_update_password',
    usersGetConfigs: 'users_get_configs',
    usersPutConfigs: 'users_put_configs',

    wapsFind: 'waps_find',
    wapsRead: 'waps_read',
    wapsUpdate: 'waps_update',
    wapsCreate: 'waps_create',
    wapsReadTree: 'waps_read_tree',
    wapsGroups: 'waps_groups',
    wapsTags: 'waps_tags',
    wapsDelete: 'waps_delete',
    wapsEnable: 'waps_enable',
    wapsUpdateLocation: 'waps_update_location',

    sensorsCount: 'sensors_count',
    sensorsFind: 'sensors_find',
    sensorsRead: 'sensors_read',
    sensorsUpdate: 'sensors_update',
    sensorsCreate: 'sensors_create',
    sensorsGroups: 'sensors_groups',
    sensorsTags: 'sensors_tags',
    sensorsDelete: 'sensors_delete',
    sensorsEnable: 'sensors_enable',
    sensorsUpdateLocation: 'sensors_update_location',
    sensorsLocationCounters: 'sensors_location_counters',

    sensorsZonesGet: 'sensors_zones_get',
    sensorsZoneCreate: 'sensors_zone_create',
    sensorsZoneUpdate: 'sensors_zone_update',
    sensorsZonesDelete: 'sensors_zones_delete',
    sensorsZonesAll: 'sensors_zones_all',
    sensorsReadTree: 'sensors_read_tree',

    widgetsRssFeedProbe: 'widgets_rss_feed_probe',
    widgetsFonts: 'widgets_fonts',
    widgetsFacebookFeedProbe: 'widgets_facebook_feed_probe',

    uploadsMediaTypes: 'uploads_media_types'
};
