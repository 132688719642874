import { FormGroup } from '@angular/forms';
import { UntypedFormBuilder, Validators, UntypedFormArray, FormControl, FormArray } from '@angular/forms';

import { Campaign as ConfigCampaign } from '@config/campaign';
import { Campaign, CampaignSchedule2Entry, CampaignTargetType, CampaignPlaylistEntry, Scheduler, CampaignTargetEntry } from '@domain/campaigns';


export interface CampaignForm {
    id: FormControl<string>;
    name: FormControl<string>;
    notes: FormControl<string>;
    group: FormControl<string>;
    color: FormControl<string>;
    version: FormControl<number>;
    customerId: FormControl<string>;
    enabled: FormControl<boolean>;
    actionOnly: FormControl<boolean>;
    targetType: FormControl<CampaignTargetType>;
    type: FormControl<string>;
    shuffle: FormControl<boolean>;
    offline: FormControl<boolean>;
    skipAfterSeconds: FormControl<number>;
    beforeConnect: FormControl<boolean>;
    afterConnect: FormControl<boolean>;
    targets: FormControl<CampaignTargetEntry[]>;
    schedules2: FormArray<FormControl<CampaignSchedule2Entry>>;
    playlist: FormArray<FormGroup<CampaignPlaylistEntryForm>>;
}

export interface CampaignPlaylistEntryForm {
    color: FormControl<string>;
    continuous: FormControl<boolean>;
    duration: FormControl<number>;
    resourceId: FormControl<string>;
    subtype: FormControl<string>;
    transition: FormControl<string>;
    type: FormControl<string>;
    properties: FormControl<any>;
}

export interface DefaultPlaylistSettingsForm {
    color: FormControl<string>;
    duration: FormControl<number>;
    transition: FormControl<string>;
}

export class CampaignsCreator {

    constructor(public fb: UntypedFormBuilder) { }

    public createCampaignFormGroup(campaign: Campaign): FormGroup<CampaignForm> {
        const targets = campaign.targets || [];
        const schedules = (campaign.schedules2 || [Scheduler.defaultScheduleEntry({ name: 'Schedule-1' })]).map(x => ({ ...x }));
        const playlist = campaign.playlist || [];

        let targetType = campaign.targetType;

        if (!targetType) {
            const hasGroup = targets.some(x => x.type === 'group');
            targetType = hasGroup ? CampaignTargetType.groups : CampaignTargetType.locations;
        }

        return this.fb.group({
            id: [campaign.id],
            version: [campaign.version],
            customerId: [campaign.customerId],
            enabled: [campaign.enabled],
            actionOnly: [campaign.actionOnly],
            name: [campaign.name, [Validators.required]],
            notes: [campaign.notes],
            group: [campaign.group],
            color: [campaign.color],
            targetType: [targetType || CampaignTargetType.locations],
            type: [campaign.type],
            shuffle: [campaign.shuffle],
            offline: [campaign.offline],
            skipAfterSeconds: [campaign.skipAfterSeconds],
            beforeConnect: [campaign.beforeConnect],
            afterConnect: [campaign.afterConnect],
            targets: [targets],
            schedules2: this.fb.array(schedules.map(entry => this.fb.control(entry))),
            playlist: this.fb.array(playlist.map(entry => this.createPlaylistEntryFormGroup(entry))),
        });
    }

    public pushPlaylistEntries(playlistFormArray: UntypedFormArray, entries: CampaignPlaylistEntry[]): void {
        (entries || []).forEach(entry => {
            const entryGroup = this.createPlaylistEntryFormGroup(entry);
            playlistFormArray.push(entryGroup);
        });
    }

    public createPlaylistEntryFormGroup(entry: CampaignPlaylistEntry): FormGroup<CampaignPlaylistEntryForm> {
        const group = this.fb.group({
            color: [entry.color],
            continuous: [entry.continuous],
            duration: [entry.duration],
            resourceId: [entry.resourceId],
            subtype: [entry.subtype],
            transition: [entry.transition],
            type: [entry.type],
            properties: [entry.properties]
        });

        if (entry.properties && (entry.properties.duration || entry.properties.duration === 0)) {
            group.controls['duration'].disable();
        }

        return group;
    }

    public createPlaylistTagsEntryFormGroup(entry: CampaignPlaylistEntry, index: number): FormGroup<CampaignPlaylistEntryForm> {
        entry = entry || new CampaignPlaylistEntry({
            type: 'foreign',
            subtype: 'tags',
            color: ConfigCampaign.playlistDefaultSettings.color,
            duration: ConfigCampaign.playlistDefaultSettings.duration,
            transition: ConfigCampaign.playlistDefaultSettings.transition,
            continuous: false,
            resourceId: null,
            properties: {
                name: `Tag-group ${index}`,
                tags: []
            }
        });

        return this.fb.group({
            color: [entry.color],
            continuous: [entry.continuous],
            duration: [entry.duration],
            resourceId: [entry.resourceId],
            subtype: [entry.subtype],
            transition: [entry.transition],
            type: [entry.type],
            properties: this.fb.group({
                name: [entry.properties.name],
                tags: [entry.properties.tags || []]
            })
        });
    }

    public createDefaultPlaylistSettingsFormGroup(): FormGroup<DefaultPlaylistSettingsForm> {
        return this.fb.group({
            color: [ConfigCampaign.playlistDefaultSettings.color],
            transition: [ConfigCampaign.playlistDefaultSettings.transition],
            duration: [ConfigCampaign.playlistDefaultSettings.duration]
        });
    }
}
