import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiUrls } from '@config/api-urls';
import { AuthService } from '@core/auth';
import { ApiService } from '@core/data/services';
import { Customer, CustomerEntry, LicenseInfo, ReadTierResponse, UpdateTierRequest } from '@domain/customers';
import { GetConfigsRequest, PutConfigsRequest } from '@domain/models';


export class CustomersApi extends ApiService {

    constructor(public http: HttpClient, protected authService: AuthService) {
        super(http, authService);
    }

    /**
     * Read caller’s immediate customer.
     */
    public customersMe(): Observable<Customer> {
        return this.post(ApiUrls.customersMe, {});
    }

    /**
     * List children customers.
     */
    public customersChildren(): Observable<CustomerEntry[]> {
        return this.post(ApiUrls.customersChildren, {});
    }

    public customersChildrenAndTier(): Observable<{ tier: ReadTierResponse, children: CustomerEntry[] }> {
        const customerId = this.authService.getCustomerId();

        const requestParams = [
            { id: 1, method: ApiUrls.customersChildren },
            { id: 2, method: ApiUrls.customersTier, params: { customerId } },
        ];

        return this.postBatch(requestParams)
            .pipe(map(([children, tier]) => ({ children, tier })));
    }

    /**
     * Get parent customer.
     */
    public customersParent(): Observable<CustomerEntry> {
        return this.post(ApiUrls.customersParent, {});
    }

    /**
     * Create new customer. Caller’s immediate customer becomes parent customer for created customer.
     * @param customer Customer object
     */
    public customersCreate(customer: Customer): Observable<Customer> {
        return this.post(ApiUrls.customersCreate, customer);
    }

    /**
     * Update customer information.
     * @param customer Customer object
     */
    public customersUpdate(customer: Customer): Observable<Customer> {
        return this.post(ApiUrls.customersUpdate, customer);
    }

    /**
     * Delete customers
     * @param ids Customer ids to remove
     */
    public customersDelete(ids: string[]): Observable<string[]> {
        const customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.customersDelete, { ids, customerId });
    }

    public customersRead(customerId: string): Observable<Customer> {
        return this.post(ApiUrls.customersRead, { customerId });
    }

    /**
     * Put customer config
     * @param params 
     */
    public customersPutConfigs(request: PutConfigsRequest): Observable<any> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.customersPutConfigs, request);
    }

    /**
     * Get customer config
     * @param params 
     */
    public customersGetConfigs(request: GetConfigsRequest): Observable<Record<string, any>> {
        request.customerId = this.authService.getCustomerId();
        return this.post(ApiUrls.customersGetConfigs, request);
    }

    public customersUpdateTier(request: UpdateTierRequest): Observable<LicenseInfo> {
        return this.post(ApiUrls.customersUpdateTier, request);
    }
}
