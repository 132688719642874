import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { EnsureModuleLoadedOnceGuard } from '@domain/base';
import { ErrorsHandler } from './errors-handler';
import { ErrorsOptions, IErrorsOptions } from './errors-options.model';
import { ErrorsService } from './errors.service';


@NgModule({
    imports: []
})
export class ErrorsModule extends EnsureModuleLoadedOnceGuard {

    static forRoot(options: IErrorsOptions = { logClientErrors: true, logServerErrors: true })
        : ModuleWithProviders<ErrorsModule> {
        return {
            ngModule: ErrorsModule,
            providers: [
                ErrorsService,
                { provide: ErrorHandler, useClass: ErrorsHandler },
                { provide: ErrorsOptions, useValue: options }
            ],
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: ErrorsModule) {
        super(parentModule);
    }
}
