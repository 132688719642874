import { Time } from '@domain/utils';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { BaseEntry } from '../base/base-entry.model';
import { CampaignSchedule2Entry } from './campaign-schedule2-entry.model';
import { CampaignSchedule } from './campaign-schedule.model';


export enum Frequency {
    None = 'NONE',
    Daily = 'DAILY',
    Weekly = 'WEEKLY',
    Monthly = 'MONTHLY',
    Yearly = 'YEARLY',
}

export enum EndType {
    count = 'count',
    until = 'until',
}

export class FrequencyEntry extends BaseEntry<FrequencyEntry> {
    public id: Frequency | null;
    public text: string;
}

export class Priority extends BaseEntry<Priority> {
    public id: number;
    public text: string;
    public additionalText: string;
}

interface IRecurrenceRule {
    FREQ: Frequency;
    INTERVAL: number;
    BYDAY: string[];
    BYMONTHDAY: number;
    BYMONTH: number;
    UNTIL: Date;
}

export class Scheduler {

    public static getPriorities(): Priority[] {
        return [
            new Priority({ id: 0, text: '0 - Do not play', additionalText: 'CAMPAIGN.SCHEDULES.WEIGHTING_1' }),
            new Priority({ id: 1, text: '1 - Low', additionalText: 'CAMPAIGN.SCHEDULES.WEIGHTING_2' }),
            new Priority({ id: 2, text: '2 - Medium', additionalText: 'CAMPAIGN.SCHEDULES.WEIGHTING_2' }),
            new Priority({ id: 3, text: '3 - High', additionalText: 'CAMPAIGN.SCHEDULES.WEIGHTING_2' }),
            new Priority({ id: 4, text: '4 - Urgent', additionalText: 'CAMPAIGN.SCHEDULES.WEIGHTING_3' })
        ];
    }

    public static getMonths(): { id: string, text: string }[] {
        return [
            { id: '1', text: 'January' },
            { id: '2', text: 'February' },
            { id: '3', text: 'March' },
            { id: '4', text: 'April' },
            { id: '5', text: 'May' },
            { id: '6', text: 'June' },
            { id: '7', text: 'July' },
            { id: '8', text: 'August' },
            { id: '9', text: 'September' },
            { id: '10', text: 'October' },
            { id: '11', text: 'November' },
            { id: '12', text: 'December' }
        ];
    }

    public static getFrequencies(): FrequencyEntry[] {
        return [
            new FrequencyEntry({ id: Frequency.None, text: 'One Time' }),
            new FrequencyEntry({ id: Frequency.Daily, text: 'Daily' }),
            new FrequencyEntry({ id: Frequency.Weekly, text: 'Weekly' }),
            new FrequencyEntry({ id: Frequency.Monthly, text: 'Monthly' }),
            new FrequencyEntry({ id: Frequency.Yearly, text: 'Yearly' }),
        ];
    }

    public static maxDaysInMonth(year: string, month: number): number {
        return moment(`${year}-${month}`, 'YYYY-M').daysInMonth();
    }

    public static fromRecurrenceRule(rule: string): Record<string, string> {
        const ruleItems = (rule || '').split(';');

        return ruleItems.reduce((result, item) => {
            const r = item.split('=');

            return {
                ...result,
                ...{ [r[0]]: r[1] },
            };
        }, {});
    }

    public static toRecurrenceRule(values: IRecurrenceRule): string {
        if (values['FREQ'] === Frequency.None) {
            return null;
        }

        const until: string = values['UNTIL'] ? `;UNTIL=${Time.dateAsNumber(values['UNTIL'])}` : '';
        const rule = `FREQ=${values['FREQ']};INTERVAL=${values['INTERVAL']}${until}`;

        switch (values['FREQ']) {
            case Frequency.Daily:
                return rule;
            case Frequency.Weekly: {
                const byDay = (values['BYDAY'] || []).join(',');
                return byDay ? `${rule};BYDAY=${byDay}` : rule;
            }
            case Frequency.Monthly:
                return `${rule};BYMONTHDAY=${values['BYMONTHDAY']}`;
            case Frequency.Yearly:
                return `${rule};BYMONTH=${values['BYMONTH']};BYMONTHDAY=${values['BYMONTHDAY']}`;
            default:
                return null;
        }
    }

    public static splitDays(days: string): string[] {
        return days ? days.split(',') : [];
    }

    public static generateId(): string {
        return uuidv4();
    }

    public static recurrenceRule(frequency: Frequency): string {
        return frequency === Frequency.None ? null : `FREQ=${frequency}`;
    }

    public static defaultScheduleEntry(values: Partial<CampaignSchedule2Entry> = {}): CampaignSchedule2Entry {
        const recurrenceRule: string = Scheduler.recurrenceRule(Frequency.Daily);
        return new CampaignSchedule2Entry({ recurrenceRule, disabled: false, allDay: true, weight: 2, ...values });
    }

    public static referenceCampaignSchedule(schedule: Partial<CampaignSchedule> = {}): CampaignSchedule2Entry {
        return new CampaignSchedule2Entry({
            scheduleId: schedule.id,
            allDay: false,
            disabled: false,
            dateBegin: null,
            dateEnd: null,
            timeBegin: null,
            timeEnd: null,
            recurrenceRule: null,
            recurrenceException: null,
        });
    }
}
