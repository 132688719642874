import { NgModule } from '@angular/core';

import { BytesPipe } from './pipes/bytes-size.pipe';
import { DaysPipe } from './pipes/days.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { EllipsisTextPipe, DisplayOnlyPipe } from './pipes/ellipsis-text.pipe';
import { FormatDatePipe, FormatTimePipe } from './pipes/format.pipe';
import { MacAddressPipe } from './pipes/mac-address.pipe';
import { SubtractDatesPipe } from './pipes/subtract-dates.pipe';
import { SecurityTrustAsUrlPipe, SecurityTrustAsResourcePipe, SecurityTrustAsHtmlPipe } from './pipes/security.pipe';
import { PipeWrapperPipe } from './pipes/pipe-wrapper.pipe';
import { PlayerOrientationPipe, PlayerTypePipe } from './pipes/players.pipe';
import { SensorZoneTypePipe, SensorZoneModePipe } from './pipes/sensors.pipe';
import { WifiStrengthPipe } from './pipes/wifi-strength.pipe';
import { CampaignTypePipe, CampaignTargetTypePipe } from './pipes/campaigns.pipe';
import { ActionSourceTypePipe, ActionClauseTypePipe, MatchOperatorPipe } from './pipes/actions.pipe';

const pipes = [
    ActionSourceTypePipe,
    ActionClauseTypePipe,
    MatchOperatorPipe,
    BytesPipe,
    DaysPipe,
    DurationPipe,
    EllipsisTextPipe,
    DisplayOnlyPipe,
    FormatDatePipe,
    FormatTimePipe,
    MacAddressPipe,
    SubtractDatesPipe,
    SecurityTrustAsUrlPipe,
    SecurityTrustAsResourcePipe,
    SecurityTrustAsHtmlPipe,
    PipeWrapperPipe,
    SensorZoneTypePipe,
    SensorZoneModePipe,
    PlayerOrientationPipe,
    PlayerTypePipe,
    CampaignTypePipe,
    CampaignTargetTypePipe,
    WifiStrengthPipe
];

@NgModule({
    declarations: [...pipes],
    exports: [...pipes],
})
export class CommonPipesModule { }
