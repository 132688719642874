import { InjectionToken } from '@angular/core';

export interface IErrorsOptions {
    logClientErrors?: boolean;
    logServerErrors?: boolean;
    logRoutingErrors?: boolean;
    source?: string;
}

export const ErrorsOptions = new InjectionToken<IErrorsOptions>('ErrorsOptions');
