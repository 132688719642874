import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

const momentConstructor = moment;

@Pipe({ name: 'subtractDates', pure: false })
export class SubtractDatesPipe implements PipeTransform {
    transform(value: moment.MomentInput): string {
        if (!value) {
            return `${0}d ${0}h ${0}m`;
        }

        let seconds = (this.getTime(momentConstructor()) - this.getTime(value)) / 1000;

        const days = Math.floor(seconds / 86400);
        seconds %= 86400;

        const hours = Math.floor(seconds / 3600);
        seconds %= 3600;

        const minutes = Math.floor(seconds / 60);

        return `${days}d ${hours}h ${minutes}m`;
    }

    private getTime(value: moment.MomentInput): number {
        if (moment.isDate(value)) {
            return value.getTime();
        } else if (moment.isMoment(value)) {
            return value.valueOf();
        } else {
            return momentConstructor(value)
                .utc()
                .valueOf();
        }
    }
}
