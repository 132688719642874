export const AccessPoint = {
    vendorList: [
        { value: 'Aruba' },
        { value: 'Aerohive' },
        { value: 'Avaya' },
        { value: 'Cisco' },
        { value: 'Cisco Miraki' },
        { value: 'Dell' },
        { value: 'D-Link' },
        { value: 'Extreme' },
        { value: 'Fortinet' },
        { value: 'Huawei' },
        { value: 'Juniper' },
        { value: 'Lancom' },
        { value: 'MikroTik' },
        { value: 'Netgear' },
        { value: 'Ruckus' },
        { value: 'Riverbed' },
        { value: 'TP-Link' },
        { value: 'Ubiquiti' },
        { value: 'WatchGuard' },
        { value: 'ZyXEL' },
    ],
    defaultType: 'Aruba',
    allValue: 'all',
};
