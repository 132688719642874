import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { TranslateObserver } from '@domain/base';

@Injectable()
export class TranslationsService extends TranslateObserver {
    public viewTypeTooltip: { list: string; tile: string };

    constructor(public translate: TranslateService) {
        super(translate);
    }

    protected onLangChange() {
        this.viewTypeTooltip = this.getViewTypeTooltip();
    }

    protected getViewTypeTooltip() {
        return {
            list: this.translate.instant('COMMON.SWITCH_TO_TILE_VIEW'),
            tile: this.translate.instant('COMMON.SWITCH_TO_LIST_VIEW'),
        };
    }
}
