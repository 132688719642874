export enum TickerSpeed {
    slow = 'slow',
    normal = 'normal',
    fast = 'fast'
}

export const tickerSpeeds = [
    TickerSpeed.slow,
    TickerSpeed.normal,
    TickerSpeed.fast
];
