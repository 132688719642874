<div class="page-actions">
    <button mat-mini-fab color="accent" class="page-actions__btn add-fab-button"
            (click)="addItem.emit()"
            [disabled]="disabledCustomButton"
            *ngIf="!showCustomButton">
        <mat-icon>add</mat-icon>
    </button>

    <div class="page-actions__content">
        <ng-content></ng-content>
    </div>
</div>
