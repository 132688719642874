import { BaseEntry } from '../base/base-entry.model';
import { SensorAddress } from './sensor-address.model';

export class Sensor extends BaseEntry<Sensor> {
    public address: SensorAddress;
    public customerId: string;
    public enabled: boolean;
    public facility: number;
    public group: string;
    public hardwareId: string;
    public id: string;
    public locationId: string;
    public name: string;
    public notes: string;
    public type: string;
    public tags: string[];
    public version: number;
}
