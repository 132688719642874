import { BaseEntry } from '../base/base-entry.model';
import { PlaylistProperties } from './playlist-properties.model';

export class ResourceMetadataTemplate extends BaseEntry<ResourceMetadataTemplate> {
    public customerId: string;
    public expireAfter: Date;
    public id: string;
    public lastModifiedDate: Date;
    public name: string;
    public playlistProperties: PlaylistProperties;
    public tags: string[];
    public updateExpireAfter: boolean;
    public updatePlaylistProperties: boolean;
    public updateTags: boolean;
    public version: number;
}
