import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';

import { TranslateModule } from '@ngx-translate/core';

import { ImageUploaderModule } from '@modules/image-uploader';

import { MyProfileFormService } from './services';
import { ProfileInfoPanelComponent } from './components';


@NgModule({
    declarations: [ProfileInfoPanelComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ImageUploaderModule,
        MatFormFieldModule,
        MatButtonModule,
        MatRadioModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        TranslateModule.forChild(),
    ],
    providers: [MyProfileFormService],
    exports: [ProfileInfoPanelComponent],
})
export class MyProfileModule { }
