import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'page-content',
    templateUrl: './page-content.component.html',
    styleUrls: ['./page-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('onChangeSideNavLeft', [
            state('close', style({ width: '30px' })),
            state('open', style({ width: '{{ leftWidth }}' }), { params: { leftWidth: '280px' } }),
            transition('close => open', animate('250ms ease-in')),
            transition('open => close', animate('250ms ease-in')),
        ]),
        trigger('onChangeSideNavRight', [
            state('close', style({ width: '30px' })),
            state('open', style({ width: '{{ rightWidth }}' }), { params: { rightWidth: '280px' } }),
            transition('close => open', animate('250ms ease-in')),
            transition('open => close', animate('250ms ease-in')),
        ]),
        trigger('onChangeMainContentLeft', [
            state('close', style({ 'margin-left': '30px' })),
            state('open', style({ 'margin-left': '{{ leftWidth }}' }), { params: { leftWidth: '280px' } }),
            transition('close => open', animate('250ms ease-in')),
            transition('open => close', animate('250ms ease-in')),
        ]),
        trigger('onChangeMainContentRight', [
            state('close', style({ 'margin-right': '30px' })),
            state('open', style({ 'margin-right': '{{ rightWidth }}' }), { params: { rightWidth: '280px' } }),
            transition('close => open', animate('250ms ease-in')),
            transition('open => close', animate('250ms ease-in')),
        ]),
        trigger('onChangeSideNavContent', [
            state('true', style({ display: 'block' })),
            state('false', style({ display: 'none' })),
            transition('* => *', animate('0ms 250ms ease-in')),
        ]),
    ],
})
export class PageContentComponent {

    @Input() toolbarTitle: string;
    @Input() showToolbar: boolean = false;
    @Output() closeToolbar = new EventEmitter<any>();


    @Input() showPageActions: boolean = false;
    @Input() showRightSidebar: boolean = false;
    @Input() showLeftSidebar: boolean = true;
    @Input() showCustomButton: boolean = false;
    @Input() disabledCustomButton: boolean = false;
    @Input() showNavigation: boolean = false;
    @Input() openedRightSidebar: boolean = true;
    @Input() openedLeftSidebar: boolean = false;
    @Input() disabledRightSidebar: boolean = false;
    @Input() leftWidth: string = '280px';
    @Input() rightWidth: string = '280px';

    @Output() addItem = new EventEmitter<null>();
    @Output() navigateTo = new EventEmitter<null>();
    @Output() toggleLeftSidebar = new EventEmitter<boolean>();
    @Output() toggleRightSidebar = new EventEmitter<boolean>();

    public visibleLeftSidebar: boolean = false;
    public visibleRightSidebar: boolean = false;

    public onToggleLeftSidebar(): void {
        this.openedLeftSidebar = !this.openedLeftSidebar;
        this.toggleLeftSidebar.emit(this.openedLeftSidebar);
    }

    public onToggleRightSidebar(): void {
        this.openedRightSidebar = !this.openedRightSidebar;
        this.toggleRightSidebar.emit(this.openedRightSidebar);
    }

    public onNavigateTo(): void {
        this.navigateTo.emit();
    }

    public onAddItem(): void {
        this.addItem.emit();
    }
}
