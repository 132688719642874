import * as moment from 'moment';
import { Moment } from 'moment';

export enum CalendarViewType {
    timelineDay = 'timelineDay',
    timelineWeek = 'timelineWeek',
    timelineWorkWeek = 'timelineWorkWeek',
    timelineMonth = 'timelineMonth',
    day = 'day',
    week = 'week',
    workWeek = 'workWeek',
    month = 'month',
    agenda = 'agenda',
}

export class Calendar {

    static getStartOfMonth(date: Date | Moment): number {
        return this.getStartOf(date, 'month');
    }

    static getEndOfMonth(date: Date | Moment): number {
        return this.getEndOf(date, 'month');
    }
    static getStartOfWeek(date: Date | Moment): number {
        return this.getStartOf(date, 'isoWeek');
    }

    static getEndOfWeek(date: Date | Moment, subtract?: number): number {
        return this.getEndOf(date, 'isoWeek', subtract);
    }

    static getStartOf(date: Date | Moment, type: any): number {
        return this.getDate(moment(date).startOf(type));
    }

    static getEndOf(date: Date | Moment, type: any, offset: number = 0): number {
        return this.getDate(
            moment(date)
                .endOf(type)
                .subtract(offset, 'days'),
        );
    }

    static getDate(date: any): number {
        return Number(moment(date).format('YYYYMMDD'));
    }

    static getDateWithOffset(date: Date, amount: number, type: any): number {
        return this.getDate(
            moment(date)
                .add(amount, type)
                .subtract(1, 'days'),
        );
    }

    static getDatesRange(viewType: CalendarViewType, date: Date) {
        let dateBegin = this.getDate(date);
        let dateEnd = this.getDate(date);

        switch (viewType) {
            case CalendarViewType.week:
            case CalendarViewType.timelineWeek:
                dateBegin = this.getStartOfWeek(date);
                dateEnd = this.getEndOfWeek(date);
                break;
            case CalendarViewType.workWeek:
            case CalendarViewType.timelineWorkWeek:
                dateBegin = this.getStartOfWeek(date);
                dateEnd = this.getEndOfWeek(date, 2);
                break;
            case CalendarViewType.timelineMonth:
            case CalendarViewType.month:
                dateBegin = this.getStartOfMonth(date);
                dateEnd = this.getEndOfMonth(date);
                break;
            case CalendarViewType.agenda:
                dateEnd = this.getDateWithOffset(date, 7, 'days');
                break;
        }

        return {
            dateBegin,
            dateEnd,
        };
    }
}