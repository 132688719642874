import { BaseEntry } from '../base/base-entry.model';
import { PlaylistProperties } from './playlist-properties.model';

export class UpdateResourcesMetadataRequest extends BaseEntry<UpdateResourcesMetadataRequest> {
    public customerId: string;
    public expireAfter: Date;
    public ids: string[];
    public playlistProperties: PlaylistProperties;
    public tags: string[];
    public updateExpireAfter: boolean;
    public updatePlaylistProperties: boolean;
    public updateTags: boolean;
}
