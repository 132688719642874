import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { EnsureModuleLoadedOnceGuard } from '@domain/base';

import { FormsCreator } from './services/forms-creator.service';


@NgModule({
    imports: [CommonModule, ReactiveFormsModule],
})
export class FormsCreatorModule extends EnsureModuleLoadedOnceGuard {

    static forRoot(): ModuleWithProviders<FormsCreatorModule> {
        return {
            ngModule: FormsCreatorModule,
            providers: [FormsCreator],
        };
    }

    constructor(
        @Optional()
        @SkipSelf()
        parentModule: FormsCreatorModule,
    ) {
        super(parentModule);
    }
}
