import { NotificationType } from './notification-type.enum';

export class NotificationMessage {

    static internalError(text: any) {
        return new NotificationMessage(NotificationType.ERROR, 'Internal error', '' + text);
    }

    static error(title: string | undefined, text: string) {
        return new NotificationMessage(NotificationType.ERROR, title, text);
    }

    static warning(title: string | undefined, text: string) {
        return new NotificationMessage(NotificationType.WARNING, title, text);
    }

    static info(title: string | undefined, text: string) {
        return new NotificationMessage(NotificationType.INFO, title, text);
    }

    static success(title: string | undefined, text: string) {
        return new NotificationMessage(NotificationType.SUCCESS, title, text);
    }

    constructor(public type: NotificationType, public title: string = null, public text: string = '') { }
}
