import { Injectable } from '@angular/core';

@Injectable()
export class FileExtensionService {
    public getExtensionByFileName(fileName: string): string {
        const fileNameInfo = /[.]/.exec(fileName) ? /[^.]+$/.exec(fileName) : null;

        if (fileNameInfo !== null && fileNameInfo.length) {
            return fileNameInfo[0];
        }

        return null;
    }

    public getExtensionByBase64(base64: string): string {
        const mime = base64.match(/data:[a-zA-Z0-9]+\/([a-zA-Z0-9-.+]+).*,.*/);

        if (mime && mime.length) {
            return mime[1];
        }

        return null;
    }
}
