import { Pipe, PipeTransform } from '@angular/core';

const NAME_MAX_LENGTH = 25;

@Pipe({ name: 'ellipsisText' })
export class EllipsisTextPipe implements PipeTransform {
    transform(value: string, maxLength: number): string {
        if (!maxLength) {
            maxLength = NAME_MAX_LENGTH;
        }
        return value && value.length > maxLength ? `${value.substring(0, maxLength - 1)} ...` : value;
    }
}

@Pipe({ name: 'displayOnly' })
export class DisplayOnlyPipe implements PipeTransform {

    public transform(values: string[], take: number = 2): string {

        values = values || [];

        let others: string = '';

        if (values.length > take) {
            const count = values.length - take;
            others = count > 1 ? `and (${count}) others` : `and other`;
        }

        return `${values.slice(0, take).join(', ')} ${others}`;
    }
}
