
import { Title } from '@angular/platform-browser';
import { OnInit, Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { TableComponent } from '../table.component';


@Component({ template: '' })
export abstract class PageTableComponent<T> extends TableComponent<T> implements OnInit {

    constructor(public translate: TranslateService, public title: Title) {
        super();
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.subscribeOnChangeLang();
    }

    public setPageTitle(): void {
        const titles = ['use2go', ...this.getPageTitles()];
        this.title.setTitle(titles.join(' - '));
    }

    protected onLangChange(): void {
        this.setPageTitle();
        this.onLangChanged();
    }

    protected onLangChanged(): void {
        // This is intentional
    }

    protected getPageTitles(): string[] {
        return [];
    }

    protected subscribeOnChangeLang(): void {
        this.onLangChange();

        const subscription = this.translate.onLangChange
            .subscribe(() => this.onLangChange());

        this.subscriptions.push(subscription);
    }
}

