export class HotkeyMapper {

    public static systemMap: Record<string, string> = {
        mod: window.navigator && window.navigator.platform.indexOf('Mac') >= 0 ? 'meta' : 'control'
    };

    public static symbolsMap: Record<string, string> = {
        meta: '\u2318',       // ⌘
        shift: '\u21E7',         // ⇧
        left: '\u2190',          // ←
        right: '\u2192',         // →
        up: '\u2191',            // ↑
        down: '\u2193',          // ↓
        'return': '\u23CE',      // ⏎
        backspace: '\u232B'      // ⌫
    };

    public static transform(keys: string): string {
        return keys.split('.').map(key => HotkeyMapper.systemMap[key] || key)
            .map(key => HotkeyMapper.symbolsMap[key] || key).join('+');
    }
}
