import { Component, ChangeDetectionStrategy } from '@angular/core';

import { UploadService } from '../upload.service';
import { UploadState } from '../shared';


@Component({
    selector: 'upload-files-manager',
    templateUrl: './upload-files-manager.component.html',
    styleUrls: ['./upload-files-manager.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadFilesManagerComponent {

    public files$ = this.upload.connect();

    public total$ = this.upload.uploadTotal;

    public expanded = true;

    constructor(public upload: UploadService) { }

    public onPlay(state: UploadState): void {
        this.upload.control({ ...state, action: 'upload' });
    }

    public onPause(state: UploadState): void {
        this.upload.control({ ...state, action: 'pause' });
    }

    public onCancel(state: UploadState): void {
        this.upload.control({ ...state, action: 'cancel' });
    }
}
