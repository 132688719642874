export enum TickerSize {
    small = 'small',
    normal = 'normal',
    large = 'large'
}

export const tickerSizes = [
    TickerSize.small,
    TickerSize.normal,
    TickerSize.large
];
