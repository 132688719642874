import { HttpClient } from '@angular/common/http';

import { Loader } from '@googlemaps/js-api-loader';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';


export class GoogleApi {

    private readonly GOOGLE_API_LINK: string = 'https://maps.googleapis.com/maps/api/timezone/json?location=';
    private readonly GOOGLE_API_TIMESTAMP: string = '&timestamp=1331766000&key=';

    private _loader = new Loader({ apiKey: environment.googleApiKey, version: 'weekly' });

    constructor(public http: HttpClient) {
        this._loader.importLibrary('maps');
    }

    public getGeolocation(lat: number, lng: number) {
        return this.http
            .get(this.GOOGLE_API_LINK + lat + ',' + lng + this.GOOGLE_API_TIMESTAMP + environment.googleApiKey)
            .pipe(map(res => res));
    }
}
