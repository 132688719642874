import { BaseEntry } from '../base/base-entry.model';

export class CampaignSchedule2Entry extends BaseEntry<CampaignSchedule2Entry> {
    public id: string;
    public weight: number;
    public name: string;
    public disabled: boolean;
    public allDay: boolean;
    public dateBegin: number;
    public timeBegin: number;
    public dateEnd: number;
    public timeEnd: number;
    public recurrenceRule: string;
    public recurrenceException: string;
    public scheduleId: string;
}
