import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationMessage } from '@core/notifications';


@Injectable()
export class CopierService {

    constructor(private notifications: NotificationsService, private translate: TranslateService) { }

    /** Copy the text value to the clipboard. */
    public copyText(text: string): boolean {
        const textarea = this.createTextareaAndSelect(text);

        const copySuccessful = document.execCommand('copy');
        this.removeTextarea(textarea);

        copySuccessful && this.notifications.show(NotificationMessage.success('Success', this.translate.instant('MESSAGES.COPIED')));

        return copySuccessful;
    }

    /**
     * Creates a hidden textarea element, sets its value from `text` property,
     * and makes a selection on it.
     */
    private createTextareaAndSelect(text: string): HTMLTextAreaElement {
        // Create a fake element to hold the contents to copy
        const textarea = document.createElement('textarea');

        // Prevent zooming on iOS
        textarea.style.fontSize = '12pt';

        // Hide the element
        textarea.classList.add('cdk-visually-hidden');

        // Move element to the same position vertically
        const yPosition = window.pageYOffset || document.documentElement.scrollTop;
        textarea.style.top = yPosition + 'px';

        textarea.setAttribute('readonly', '');
        textarea.value = text;

        document.body.appendChild(textarea);

        textarea.select();
        textarea.setSelectionRange(0, textarea.value.length);

        return textarea;
    }

    /** Remove the text area from the DOM. */
    private removeTextarea(textarea: HTMLTextAreaElement) {
        if (textarea) {
            document.body.removeChild(textarea);
        }
    }
}
