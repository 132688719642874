import { ObjectAction } from '@domain/roles';
import { BaseEntry } from '../base/base-entry.model';

export class Folder extends BaseEntry<Folder> {
    public id: string;
    public folderId: string;
    public name: string;
    public objectActions: ObjectAction[];
    public readOnly: boolean;
    public version: number;
    public deleted: boolean;
    /// View Properties
    public type = 'folder';
}
