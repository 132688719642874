import { ColumnConfig } from './column.config';
import { Breakpoint, breakpoints } from './breakpoints.config';

export const DefaultActionsColumnsConfig = (breakpoint: Breakpoint) => ({

    [breakpoints.Web]: [
        new ColumnConfig({ name: 'name', width: 100, visible: true }),
        new ColumnConfig({ name: 'group', width: 60, visible: true }),
        new ColumnConfig({ name: 'sourceNames', width: 140, visible: true }),
        new ColumnConfig({ name: 'clauseText', width: 140, visible: true }),
        new ColumnConfig({ name: 'targetNames', width: 140, visible: true }),
        new ColumnConfig({ name: 'enabled', width: 60, visible: true }),
    ],

    [breakpoints.Tablet]: [
        new ColumnConfig({ name: 'name', width: 100, visible: true }),
        new ColumnConfig({ name: 'group', width: 60, visible: false }),
        new ColumnConfig({ name: 'sourceNames', width: 140, visible: false }),
        new ColumnConfig({ name: 'clauseText', width: 140, visible: true }),
        new ColumnConfig({ name: 'targetNames', width: 140, visible: false }),
        new ColumnConfig({ name: 'enabled', width: 60, visible: true }),
    ],

    [breakpoints.Handset]: [
        new ColumnConfig({ name: 'name', width: 100, visible: true }),
        new ColumnConfig({ name: 'group', width: 60, visible: false }),
        new ColumnConfig({ name: 'sourceNames', width: 140, visible: false }),
        new ColumnConfig({ name: 'clauseText', width: 140, visible: false }),
        new ColumnConfig({ name: 'targetNames', width: 140, visible: false }),
        new ColumnConfig({ name: 'enabled', width: 60, visible: true }),
    ]

})[breakpoint];
