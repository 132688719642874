import { PasswordUpdateRequest, User } from '@domain/users';
import { Breakpoint, ColumnsConfigKeys, ColumnConfig, CustomerConfigKey, GroupsKey, TagsKey } from '../configs';
import { CampaignType, CampaignTargetType } from '@domain/campaigns';
import { BreadcrumbItem } from '@domain/models';
import { LocationEntry } from '@domain/locations';

export namespace DataActions {

    export class LoadResourceFolders {
        static readonly type = '[Data] Load resources folders';
    }

    export class LoadGroups {
        static readonly type = '[Data] Load groups';
        constructor(public readonly payload: GroupsKey) { }
    }

    export class SetGroups {
        static readonly type = '[Data] Set groups';
        constructor(public readonly groupsKey: GroupsKey, public readonly groups: string[]) { }
    }

    export class LoadTags {
        static readonly type = '[Data] Load tags';
        constructor(public readonly payload: TagsKey) { }
    }

    export class SetMobile {
        static readonly type = '[Data] Detect mobile';
        constructor(public readonly payload: boolean) { }
    }

    export class SetBreadcrumbs {
        static readonly type = '[Data] Set breadcrumbs';
        constructor(public readonly breadcrumbs: BreadcrumbItem[]) { }
    }

    export class SetBreakpoint {
        static readonly type = '[Data] Set Breakpoint';
        constructor(public readonly payload: Breakpoint) { }
    }

    export class SetPreviousUrl {
        static readonly type = '[Data] Set previous url';
        constructor(public readonly payload: string) { }
    }

    export class LoadRoles {
        static readonly type = '[Data] Load roles';
    }

    export class LoadLocations {
        static readonly type = '[Data] Load all locations';
    }

    export class SetLocations {
        static readonly type = '[Data] Set loaded  all locations';
        constructor(public readonly locations: LocationEntry[]) { }
    }

    export class LoadCustomerDetails {
        static readonly type = '[Data] Load customer details';
    }

    export class LoadUploadsMediaTypes {
        static readonly type = '[Data] Load uploads media types';
    }

    export class LoadResourcesQuota {
        static readonly type = '[Data] Load resources quota';
    }

    export class ToggleMainMenu {
        static readonly type = '[Data] Toggle main menu';
    }

    export class CollapsePrimaryMenu {
        static readonly type = '[Data] Collapse primary menu';
    }

    export class UpdateProfile {
        static readonly type = '[Data] Update profile';
        constructor(public payload: User) { }
    }

    export class ChangePassword {
        static readonly type = '[Data] Change password';
        constructor(public payload: PasswordUpdateRequest) { }
    }

    export class GetColumnsConfig {
        static readonly type = '[Data] Get user columns config';
        constructor(public payload: ColumnsConfigKeys) { }
    }

    export class SetDefaultColumnsConfig {
        static readonly type = '[Data] Set default columns config';
        constructor(public payload: ColumnsConfigKeys) { }
    }

    export class ChangeColumnsConfig {
        static readonly type = '[Data] Change user columns config';
        constructor(public payload: { key: ColumnsConfigKeys, columns: ColumnConfig[] }) { }
    }

    export class ResizeColumns {
        static readonly type = '[Data] Resize columns';
        constructor(public payload: { key: ColumnsConfigKeys, columns: { name: string, width: number }[] }) { }
    }

    export class GetCustomerConfig {
        static readonly type = '[Data] Get customer config';
        constructor(public payload: CustomerConfigKey) { }
    }

    export class SetCustomerConfig<T> {
        static readonly type = '[Data] Set customer config';
        constructor(public payload: { key: CustomerConfigKey, value: T }) { }
    }

    export class ChangeCustomer {
        static readonly type = '[Data] Change customer';
        constructor(public payload: string) { }
    }


    export class LoadTargets {
        static readonly type = '[Data] Load campaigns targets';
        constructor(public sourceType: CampaignType, public targetType: CampaignTargetType) { }
    }
}
