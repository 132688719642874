import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
    CampaignsApi, PlayersApi, ResourcesApi, WapsApi, SensorsApi,
    LocationsApi, SensorsZonesApi, DashboardsApi,
    UsersApi, RolesApi, CustomersApi, ActionsApi, UploadsApi, GoogleApi
} from '../apis';
import { AuthService } from '@core/auth';


@Injectable()
export class Network {

    public actionsApi: ActionsApi;

    public campaignsApi: CampaignsApi;

    public customersApi: CustomersApi;

    public dashboardsApi: DashboardsApi;

    public locationsApi: LocationsApi;

    public playersApi: PlayersApi;

    public resourcesApi: ResourcesApi;

    public rolesApi: RolesApi;

    public sensorsZonesApi: SensorsZonesApi;

    public sensorsApi: SensorsApi;

    public uploadsApi: UploadsApi;

    public usersApi: UsersApi;

    public wapsApi: WapsApi;

    public googleApi: GoogleApi;

    constructor(http: HttpClient, auth: AuthService) {
        this.actionsApi = new ActionsApi(http, auth);
        this.campaignsApi = new CampaignsApi(http, auth);
        this.customersApi = new CustomersApi(http, auth);
        this.dashboardsApi = new DashboardsApi(http, auth);
        this.locationsApi = new LocationsApi(http, auth);
        this.playersApi = new PlayersApi(http, auth);
        this.resourcesApi = new ResourcesApi(http, auth);
        this.rolesApi = new RolesApi(http, auth);
        this.sensorsZonesApi = new SensorsZonesApi(http, auth);
        this.sensorsApi = new SensorsApi(http, auth);
        this.uploadsApi = new UploadsApi(http, auth);
        this.wapsApi = new WapsApi(http, auth);
        this.usersApi = new UsersApi(http, auth);

        this.googleApi = new GoogleApi(http);
    }

}
