export function unique<T>(items: T[], key: (value?: T) => any) {
	return items.filter((item, pos, self) => {
		return self.findIndex(x => key(x) === key(item)) === pos;
	});
}

/**
 * Groups all items in an array of objects `T` where the value of property `K` is the same
 * @param array Items to group
 * @param key Key of `T` to group by
 */
export function groupBy<T, K extends keyof T>(array: T[], key: K): Map<T[K], T[]> {
	const map = new Map<T[K], T[]>();

	array.forEach(item => {
		const itemKey = item[key];
		if (!map.has(itemKey)) {
			map.set(itemKey, array.filter(i => i[key] === item[key]));
		}
	});

	return map;
}

export function filterBy<T>(array: T[], fieldName: string, search: string): T[] {
	if (!array) { return []; }

	if (!search) { return array; }

	search = search.toLowerCase();

	return array.filter(item => {
		if (item && item[fieldName]) {
			return item[fieldName].toLowerCase().includes(search);
		}
		return false;
	});
}
