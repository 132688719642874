import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

import { matchPassword, enterPasswords } from '@domain/validators';
import { PasswordUpdateRequest, User } from '@domain/users';


@Injectable()
export class MyProfileFormService {

    constructor(private fb: UntypedFormBuilder) { }

    public createPasswordForm(request: PasswordUpdateRequest): UntypedFormGroup {
        return this.fb.group(
            {
                oldPassword: [request.oldPassword],
                newPassword: [request.newPassword],
                newPasswordConfirm: [null],
            },
            {
                validators: [matchPassword, enterPasswords],
            },
        );
    }

    public createProfileForm(user: User): UntypedFormGroup {
        user = user || new User();
        return this.fb.group({
            id: [user.id],
            avatarId: [user.avatarId],
            customerId: [user.customerId],
            email: [user.email, Validators.required],
            firstName: [user.firstName, Validators.required],
            group: [user.group],
            lastName: [user.lastName, Validators.required],
            notes: [user.notes],
            phone: [user.phone],
            position: [user.position],
            roleId: [user.roleId],
        });
    }

    public disableCompanyControls(profileGroup: UntypedFormGroup): void {
        profileGroup.controls['position'].disable();
        profileGroup.controls['roleId'].disable();
        profileGroup.controls['group'].disable();
    }
}
