import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export function markFormAsTouched(form: UntypedFormGroup) {
    if (!form) {
        return;
    }

    Object.values(form.controls)
        .forEach((control: UntypedFormControl) => {

            if (control instanceof UntypedFormGroup) {
                Object.values((control as UntypedFormGroup).controls).forEach(x => x.markAsTouched());
            }

            control.markAsTouched();
        });
}
