import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import {
    ApiService,
    DetectBrowserService,
    GlobalizeService,
    SystemNotifierService,
    BrowserWindowService,
    WebsocketsService,
    ScreenCapturingService,
    SplashScreenService,
    TranslationsService,
    ContentManagerService,
    CopierService,
    LicenseService
} from './services';
import { EnsureModuleLoadedOnceGuard } from '@domain/base';
import { UnsavedChangesGuard } from './guards';
import { TranslationsResolver } from './resolvers';
import { DataState } from './store';


@NgModule({
    imports: [NgxsModule.forFeature([DataState])],
})
export class DataModule extends EnsureModuleLoadedOnceGuard {

    static forRoot(): ModuleWithProviders<DataModule> {
        return {
            ngModule: DataModule,
            providers: [
                ApiService,
                CopierService,
                SystemNotifierService,
                GlobalizeService,
                DetectBrowserService,
                LicenseService,
                { provide: BrowserWindowService, useValue: new BrowserWindowService() },
                TranslationsService,
                WebsocketsService,
                ScreenCapturingService,
                SplashScreenService,
                ContentManagerService,
                UnsavedChangesGuard,
                TranslationsResolver
            ],
        };
    }

    constructor(
        @Optional()
        @SkipSelf()
        parentModule: DataModule,
    ) {
        super(parentModule);
    }
}
