import {
    ChangeDetectionStrategy, Component, EventEmitter, Input, Output,
    ContentChildren, QueryList, ElementRef, Renderer2, AfterContentInit,
    HostBinding, OnInit, ViewChild, ChangeDetectorRef
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { state, style, trigger } from '@angular/animations';
import { fromEvent } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { ObserverComponent } from '@domain/base';
import { ToolbarItemDefDirective } from './toolbar-item-def.directive';
import { MenuItemDefDirective } from './menu-item-def.directive';


@Component({
    selector: 'page-toolbar',
    templateUrl: './page-toolbar.component.html',
    styleUrls: ['./page-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('slideDown', [
            state('void', style({ transform: 'translateY(-100%)' })),
            state('enter', style({ transform: 'translateY(0)' })),
            state('leave', style({ transform: 'translateY(-100%)' })),
        ])
    ]
})
export class PageToolbarComponent extends ObserverComponent implements OnInit, AfterContentInit {

    @Input() title: string;

    @Input() subTitle: string;

    @Input() @HostBinding('class') type: 'inline' | 'slide' = 'slide';

    @Output() close: EventEmitter<any> = new EventEmitter();

    @ContentChildren(ToolbarItemDefDirective, { descendants: true }) toolbarItems: QueryList<ToolbarItemDefDirective>;

    @ContentChildren(MenuItemDefDirective, { descendants: true }) menuItems: QueryList<MenuItemDefDirective>;

    @ViewChild('entryMenu') entryMenu: MatMenu;

    @ViewChild('titleEl') titleEl: ElementRef;

    @HostBinding('@slideDown') slideDown: string = 'enter';

    public showMenu: boolean = false;

    public width: number = 0;

    private _options = { gap: 8, menuWidth: 48, marginLeft: 48 };

    public constructor(private renderer: Renderer2, private _cdr: ChangeDetectorRef, private el: ElementRef) {
        super();
    }

    public ngOnInit(): void {
        this.subscribeOnClickOutside();
    }

    public ngAfterContentInit(): void {
        this.hideToolbarItems();
        this.setMargins();
        this.subscribeOnChangeToolbarItems();
    }

    public onResize(_: number): void {
        this.width = this.getPossibleWidth();
        this.showMenu = this.isMenuNeeded(this.width);

        let sum = this.showMenu ? (this._options.menuWidth + this._options.gap) : 0;

        this.toolbarItems.forEach(toolbarItem => {
            sum += (toolbarItem.width + toolbarItem.marginLeft + this._options.gap);
            toolbarItem.visible = sum < this.width;
            this.setVisibility(toolbarItem.el, toolbarItem.visible);
        });

        const hiddenToolbarItems = this.toolbarItems.filter(x => !x.visible).map(x => x.key);

        this.menuItems.forEach(menuItem => {
            menuItem.visible = hiddenToolbarItems.includes(menuItem.key);
            this.setVisibility(menuItem.el, menuItem.visible);
        });
    }

    private setMargins(): void {
        let prevItem: ToolbarItemDefDirective;

        this.toolbarItems.forEach(toolbarItem => {
            const marginLeft = prevItem && prevItem.type !== toolbarItem.type ? this._options.marginLeft : 0;
            toolbarItem.setMarginLeft(marginLeft);
            prevItem = toolbarItem;
        });
    }

    private getPossibleWidth(): number {
        let maxWidth = this.el.nativeElement.offsetWidth;

        if (this.titleEl) {
            maxWidth -= (this.titleEl.nativeElement.offsetWidth + this._options.gap);
        }

        if (this.isMenuNeeded(maxWidth)) {
            maxWidth -= (this._options.menuWidth + this._options.gap);
        }

        return maxWidth - 4;
    }

    private isMenuNeeded(width: number) {
        return this.toolbarItems.reduce((total, b) => total + b.width + b.marginLeft, 0) > width;
    }

    private setVisibility(el: ElementRef, visible: boolean): void {
        if (visible) {
            this.renderer.removeClass(el.nativeElement, 'hidden');
        } else {
            this.renderer.addClass(el.nativeElement, 'hidden');
        }
    }

    private subscribeOnClickOutside(): void {
        const subscription = fromEvent<MouseEvent>(document, 'click')
            .pipe(
                filter(event => {
                    const clickTarget = event.target as HTMLElement;
                    const menuElement = document.querySelector(`#${this.entryMenu.panelId}`);
                    return !this.el.nativeElement.contains(clickTarget) && !(menuElement && menuElement.contains(clickTarget));
                }),
                take(1)
            ).subscribe(() => this.close.emit());

        this.subscriptions.push(subscription);
    }

    private subscribeOnChangeToolbarItems(): void {
        const subscription = this.toolbarItems.changes.subscribe(() => {
            this.setMargins();
            this.width && this.onResize(this.width);
            this._cdr.markForCheck();
        });

        this.subscriptions.push(subscription);
    }

    private hideToolbarItems(): void {
        this.toolbarItems.forEach(x => this.setVisibility(x.el, false));
    }
}
