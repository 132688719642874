import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'hotkeys-cheat-sheet',
    templateUrl: './hotkeys-cheat-sheet.component.html',
    styleUrls: ['./hotkeys-cheat-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotkeysCheatSheetComponent {

    public hotkeys = Array.from(this.data);

    constructor(@Inject(MAT_DIALOG_DATA) public data) { }
}
