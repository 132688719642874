import { BaseEntry } from '../base/base-entry.model';
import { CampaignTargetEntry } from './campaign-target-entry.model';

export class Calendar2Request extends BaseEntry<Calendar2Request> {
    public customerId: string;
    public dateBegin: number;
    public dateEnd: number;
    public disabledOnly: boolean;
    public enabledOnly: boolean;
    public offlineOnly: boolean;
    public onlineOnly: boolean;
    public targets: CampaignTargetEntry[];
    public type: string;
    public weights: number[];
}
