export class Helpers {
    static toHex(value: number, padding?: number): string {
        let hex = Number(value).toString(16);
        padding = typeof padding === undefined || padding === null ? 2 : padding;

        while (hex.length < padding) {
            hex = '0' + hex;
        }

        return hex;
    }

    static toDecimal(value: string): number {
        return parseInt(value, 16);
    }

    static getMac(value: number): string {
        if (!value) {
            return null;
        }

        return new Array(6)
            .join('00')
            .match(/../g)
            .concat(Helpers.toHex(value, 10).match(/.{1,2}/g))
            .reverse()
            .slice(0, 6)
            .reverse()
            .join(':');
    }

    static isNumber(value: any): value is number {
        return typeof value === 'number';
    }

    static isNumberFinite(value: any): value is number {
        return this.isNumber(value) && isFinite(value);
    }

    static isPositive(value: number): boolean {
        return value >= 0;
    }

    static isInteger(value: number): boolean {
        return value % 1 === 0;
    }
}
