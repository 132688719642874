import { ErrorHandler, ErrorType } from './error-handler';
import { store } from './store';
import { createHash, DynamicChunk, isNumber, noop, unFunc } from './utils';
import { UploadStatus } from './upload-status.enum';
import { UploadAction, UploadControlEvent } from './upload-control-event.model';
import { UploadState } from './upload-state.model';
import { FileEntry, UploadOptions } from '.';

const actionToStatusMap: { [K in UploadAction]: UploadStatus } = {
    pause: UploadStatus.paused,
    upload: UploadStatus.queue,
    cancel: UploadStatus.cancelled,
    uploadAll: UploadStatus.queue,
    pauseAll: UploadStatus.paused,
    cancelAll: UploadStatus.cancelled
};

interface RequestParams {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD' | 'OPTIONS';
    body?: BodyInit | any | null;
    url?: string;
    headers?: Record<string, string>;
    progress?: boolean;
}

/**
 * Uploader Base Class
 */
export abstract class Uploader implements UploadState {

    set status(s: UploadStatus) {
        if (this._status === UploadStatus.cancelled || (this._status === UploadStatus.complete && s !== UploadStatus.cancelled)) {
            return;
        }

        if (s !== this._status) {
            s === UploadStatus.paused && this.abort();
            this._status = s;

            [UploadStatus.cancelled, UploadStatus.complete, UploadStatus.error].includes(s) && this.cleanup();
            s === UploadStatus.cancelled ? this.onCancel() : this.stateChange(this);
        }
    }

    get status() {
        return this._status;
    }

    get url(): string {
        return this._url || store.get(this.uploadId) || '';
    }

    set url(value: string) {
        this._url !== value && store.set(this.uploadId, value);
        this._url = value;
    }

    readonly name: string;

    readonly file: File;

    readonly size: number;

    readonly uploadId: string;

    public response: any;

    public responseStatus: number;

    public progress: number;

    public remaining: number;

    public speed: number;

    public extension: string;

    public uploaded: number;

    public uploadStartedFrom: number;

    /** Custom headers */
    public headers: Record<string, any> = {};

    /** Metadata Object */
    public metadata: Record<string, any>;

    public endpoint = '/upload';

    /** Chunk size in bytes */
    public chunkSize: number;

    /** Auth token/tokenGetter */
    public token: UploadOptions['token'];

    public path: string;

    public resourceId: string = null;

    public folderId: string = null;

    /** Retries handler */
    protected errorHandler = new ErrorHandler();

    /** Active HttpRequest */
    protected _xhr: XMLHttpRequest;

    /** byte offset within the whole file */
    protected offset = 0;

    /** Set HttpRequest responseType */
    protected responseType: XMLHttpRequestResponseType = '';

    private _url = '';

    private _status: UploadStatus;

    private startTime: number;

    private stateChange: (evt: UploadState) => void;

    constructor(readonly entry: FileEntry, readonly options: UploadOptions) {
        this.name = entry.file.name;
        this.size = entry.file.size;

        this.uploaded = 0;
        this.progress = 0;
        this.file = entry.file;

        this.metadata = { name: this.file.name, mimeType: this.file.type || 'application/octet-stream', size: this.file.size, lastModified: this.file.lastModified };

        const print = JSON.stringify({ ...this.metadata, type: this.constructor.name, endpoint: options.endpoint });
        this.uploadId = createHash(print).toString(16);

        this.stateChange = options.stateChange || noop;
        this.chunkSize = options.chunkSize || this.size;

        this.configure(options);
    }

    /**
     * Configure uploader
     */
    public configure({ metadata = {}, headers = {}, token, endpoint, action }: UploadControlEvent | any): void {
        this.endpoint = endpoint || this.endpoint;
        this.token = token || this.token;
        this.folderId = metadata.folderId || this.folderId;
        this.metadata = { ...this.metadata, ...unFunc(metadata, this.file) };
        this.headers = { ...this.headers, ...unFunc(headers, this.file) };
        action && (this.status = actionToStatusMap[action]);
    }

    /**
     * Starts uploading
     */
    public async upload(): Promise<void> {
        this.status = UploadStatus.uploading;

        try {
            await this.getToken();

            this.offset = undefined;
            this.startTime = new Date().getTime();
            this.url = this.url || (await this.getFileUrl());

            this.errorHandler.reset();
            this.start();
        } catch {
            if (this.errorHandler.kind(this.responseStatus) !== ErrorType.FatalError) {
                this.status = UploadStatus.retry;
                await this.errorHandler.wait();
                this.status = UploadStatus.queue;
            } else {
                this.status = UploadStatus.error;
            }
        }
    }

    /**
     * Starts chunk upload
     */
    public async start() {
        while (this.status === UploadStatus.uploading || this.status === UploadStatus.retry) {
            if (this.offset !== this.size) {
                try {
                    const offset = isNumber(this.offset) ? await this.sendFileContent() : await this.getOffset();

                    if (offset === this.offset) {
                        throw new Error('Content upload failed');
                    }

                    this.errorHandler.reset();
                    this.offset = offset;
                } catch {
                    const errType = this.errorHandler.kind(this.responseStatus);
                    if (this.responseStatus === 413) {
                        DynamicChunk.maxSize = this.chunkSize /= 2;
                    } else if (errType === ErrorType.FatalError) {
                        this.status = UploadStatus.error;
                    } else if (errType === ErrorType.Restart) {
                        this.url = '';
                        this.status = UploadStatus.queue;
                    } else if (errType === ErrorType.Auth) {
                        await this.getToken();
                    } else {
                        this.status = UploadStatus.retry;
                        await this.errorHandler.wait();
                        this.offset = this.responseStatus >= 400 ? undefined : this.offset;
                        this.status = UploadStatus.uploading;
                    }
                }
            } else {
                this.progress = 100;
                this.remaining = 0;
                this.status = UploadStatus.complete;
            }
        }
    }

    /**
     * Performs http requests
     */
    public request({ method = 'GET', body = null, url, headers = {}, progress }: RequestParams): Promise<ProgressEvent> {
        return new Promise((resolve, reject) => {
            const xhr = (this._xhr = new XMLHttpRequest());
            xhr.open(method, url || this.url, true);
            if (body instanceof Blob || (body && progress)) {
                xhr.upload.onprogress = this.onProgress();
            }
            this.responseStatus = 0;
            this.response = undefined;
            this.responseType && (xhr.responseType = this.responseType);
            this.options.withCredentials && (xhr.withCredentials = true);
            const _headers = { ...this.headers, ...headers };
            Object.keys(_headers).forEach(key => xhr.setRequestHeader(key, _headers[key]));
            xhr.onload = (evt: ProgressEvent) => {
                this.responseStatus = xhr.status;
                this.resourceId = this.getValueFromResponse('resource-id');
                this.response = this.responseStatus !== 204 ? this.getResponseBody(xhr) : '';
                this.responseStatus >= 400 ? reject(evt) : resolve(evt);
            };
            xhr.onerror = reject;
            xhr.send(body);
        });
    }

    /**
     * Get file URI
     */
    protected abstract getFileUrl(): Promise<string>;

    /**
     * Send file content and return an offset for the next request
     */
    protected abstract sendFileContent(): Promise<number | undefined>;

    /**
     * Get an offset for the next request
     */
    protected abstract getOffset(): Promise<number | undefined>;

    protected setAuth(token: string) {
        this.headers.Authorization = `Bearer ${token}`;
    }

    protected abort(): void {
        this.offset = undefined;
        this.uploadStartedFrom = undefined;
        this._xhr && this._xhr.abort();
    }

    protected onCancel(): void {
        this.abort();
        const stateChange = () => this.stateChange(this);
        if (this.url) {
            this.request({ method: 'DELETE' }).then(stateChange, stateChange);
        } else {
            stateChange();
        }
    }

    /**
     * Gets the value from the response
     */
    protected getValueFromResponse(key: string): string | null {
        return this._xhr.getResponseHeader(key);
    }

    /**
     * Set auth token
     */
    protected async getToken(): Promise<any> {
        const token = await Promise.resolve(unFunc(this.token || '', this.responseStatus));
        return token && this.setAuth(token);
    }

    protected getChunk() {
        this.chunkSize = isNumber(this.options.chunkSize) ? this.chunkSize : DynamicChunk.size;
        const start = this.offset || 0;
        const end = Math.min(start + this.chunkSize, this.size);
        const body = this.file.slice(this.offset, end);
        return { start, end, body };
    }

    private cleanup = () => store.delete(this.uploadId);

    private getResponseBody(xhr: XMLHttpRequest): any {
        let body = 'response' in (xhr as any) ? xhr.response : xhr.responseText;
        if (body && this.responseType === 'json' && typeof body === 'string') {
            try {
                body = JSON.parse(body);
            } catch { }
        }
        return body;
    }

    private onProgress(): (evt: ProgressEvent) => void {
        let throttle = 0;
        return ({ loaded }: ProgressEvent) => {
            const now = new Date().getTime();
            this.uploaded = this.offset + loaded;
            this.uploadStartedFrom = this.uploadStartedFrom || this.uploaded;
            const elapsedTime = (now - this.startTime) / 1000;
            this.speed = Math.round((this.uploaded - this.uploadStartedFrom) / elapsedTime);

            DynamicChunk.scale(this.speed);

            if (!throttle) {
                throttle = window.setTimeout(() => (throttle = 0), 500);
                this.progress = +((this.uploaded / this.size) * 100).toFixed(2);
                this.remaining = Math.ceil((this.size - this.uploaded) / this.speed);
                this.stateChange(this);
            }
        };
    }
}
