<ng-container *ngIf="isSearchOpened">
    <mat-form-field class="sm-pad-form-field" appearance="outline">
        <input matInput
                placeholder="{{ isSearchOpened ? ('CONTENT.SEARCH' | translate): '' }}"
                [formControl]="searchField"
                #search
                class="search-string"
                name="search"
                type="text"
                (focusout)="onFocusout()">
    </mat-form-field>

    <button mat-icon-button type="button" matTooltip="Clear search" aria-label="Clear search" (click)="onClear()">
        <mat-icon>close</mat-icon>
    </button>
</ng-container>

<button type="button" mat-icon-button *ngIf="!isSearchOpened" matTooltip="Open search" (click)="onOpen()">
    <mat-icon>search</mat-icon>
</button>
