/**
 * Toggle boolean value to opposite
 */
export function toggle() {

    return (existing: Readonly<boolean>): boolean => {
        return !existing;
    };

}
