<div class="notification" (click)="snackBarRef.dismiss()">
    <h1 class="notification__title" *ngIf="data.type != null">
        <span class="mat-headline-6">{{ messages[data.type] | translate }}</span>
    </h1>

    <div class="notification__content">
        <div class="notification__label alert mat-green-100-bg" *ngIf="data.type === NotificationType.SUCCESS">
            <mat-icon class="notification__icon message-type-icon green-500-fg">check_circle</mat-icon>
            <p class="notification__text">{{ data.text }}</p>
        </div>

        <div class="notification__label alert mat-red-100-bg" *ngIf="data.type === NotificationType.ERROR">
            <mat-icon class="notification__icon message-type-icon red-500-fg">error</mat-icon>
            <p class="notification__text">{{ data.text }}</p>
        </div>

        <div class="notification__label alert mat-warn-100-bg" *ngIf="data.type === NotificationType.WARNING">
            <mat-icon class="notification__icon message-type-icon warn-500-fg">warning</mat-icon>
            <p class="notification__text">{{ data.text }}</p>
        </div>

        <div class="notification__label alert mat-info-100-bg" *ngIf="data.type === NotificationType.INFO">
            <mat-icon class="notification__icon message-type-icon info-500-fg">info</mat-icon>
            <p class="notification__text">{{ data.text }}</p>
        </div>
    </div>
</div>
