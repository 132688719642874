import { Component, OnInit, ViewContainerRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';

import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, pairwise, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import device from 'current-device';

import { DataActions, DataState, DetectBrowserService, SplashScreenService } from '@core/data';
import { AuthService } from '@core/auth';
import { ObserverComponent } from '@domain/base';
import { Breakpoint, breakpoints } from '@core/data/configs';
import { Customer, CustomersTree } from '@domain/customers';
import { RoleRef } from '@domain/roles';
import { User } from '@domain/users';
import { environment } from '@environments/environment';
import { BreadcrumbItem, UIConfig } from '@domain/models';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends ObserverComponent implements OnInit {

    @Select(DataState.uiConfig) uiConfig$: Observable<UIConfig>;

    @Select(DataState.customerId) customerId$: Observable<string>;

    @Select(DataState.organization) customer$: Observable<Customer>;

    @Select(DataState.customersTree) customersTree$: Observable<CustomersTree[]>;

    @Select(DataState.groups('users')) userGroups$: Observable<string[]>;

    @Select(DataState.roles) roles$: Observable<RoleRef[]>;

    @Select(DataState.profile) profile$: Observable<User>;

    @Select(DataState.breadcrumbs) breadcrumbs$: Observable<BreadcrumbItem[]>;

    public profileSidenavOpened: boolean = false;

    @ViewChild('primarySidenav') primaryMenuSidenav: MatSidenav;

    public collapsedNav: boolean = true;

    public isSmall: boolean = false;

    constructor(
        private translate: TranslateService,
        splashScreen: SplashScreenService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        public viewContainerRef: ViewContainerRef,
        private breakpointObserver: BreakpointObserver,
        private detectBrowserService: DetectBrowserService,
        private router: Router,
        private store: Store,
        private cdr: ChangeDetectorRef,
        private actions$: Actions
    ) {
        super();
        this.setTranslations();
        this.registerCustomIcons();
        this.detectBrowser();
        this.detectMobile();
        this.subscribeOnBreakpoints();

        matIconRegistry.setDefaultFontSetClass('material-icons-outlined');
        splashScreen.hideOnInitialLoad('splash-screen');
    }

    public ngOnInit() {
        this.storeActivationCode();
        this.subscribeOnChangeRoute();
        this.subscribeOnCollapsePrimaryMenu();

        this.store.dispatch([new DataActions.LoadGroups('users'), new DataActions.LoadRoles()]);

        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.authService.isLoggedIn()) {
                this.authService.startAuthentication();
            }
        });
    }

    public onChangeCustomer(customerId: string): void {
        const authArray = JSON.parse(sessionStorage.getItem(environment.userStorageKey));
        authArray['customer_id'] = customerId;
        sessionStorage.setItem(environment.userStorageKey, JSON.stringify(authArray));
        this.store.dispatch(new DataActions.ChangeCustomer(customerId));
        setTimeout(() => { location.reload(); }, 0);
    }

    public onOpenProfile(): void {
        this.profileSidenavOpened = true;
    }

    public onToggleMenu(): void {
        if (this.isSmall) {
            this.primaryMenuSidenav.toggle();
        } else {
            this.collapsedNav = !this.collapsedNav;
        }

        this.store.dispatch(new DataActions.ToggleMainMenu());
        this.cdr.markForCheck();
    }

    private subscribeOnChangeRoute(): void {
        const subscription = this.router.events
            .pipe(
                filter((e: any) => e instanceof RoutesRecognized),
                pairwise()
            ).subscribe((e: any) => {
                this.store.dispatch(new DataActions.SetPreviousUrl(e[0].urlAfterRedirects));
            });

        this.subscriptions.push(subscription);
    }

    private subscribeOnCollapsePrimaryMenu(): void {
        this.actions$.pipe(ofActionSuccessful(DataActions.CollapsePrimaryMenu)).subscribe(() => {
            if (!this.isSmall && !this.collapsedNav) {
                this.onToggleMenu();
            }
        });
    }

    private setTranslations(): void {
        this.translate.addLangs(['en', 'de']);

        const browserLang = this.translate.getBrowserLang();
        const defaultLang = browserLang.match(/en|de/) ? browserLang : 'en';

        this.translate.setDefaultLang(defaultLang);

        const lang = localStorage.getItem('lang') || defaultLang;

        this.translate.use(lang);
        localStorage.setItem('lang', lang);
    }

    private storeActivationCode(): void {
        let code: string;
        let tmp = [];
        window.location.search
            .substr(1)
            .split('&')
            .forEach((item: any) => {
                tmp = item.split('=');
                if (tmp[0] === 'code') {
                    code = decodeURIComponent(tmp[1]);
                }
            });

        if (typeof code !== 'undefined') {
            localStorage.setItem('code', code);
            this.router.navigate(['settings/tvboxes/add']);
        }
    }

    private registerCustomIcons(): void {
        this.matIconRegistry.addSvgIcon('tv_plus', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/tv_plus.svg'));
        this.matIconRegistry.addSvgIcon('shapes', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/shapes.svg'));
        this.matIconRegistry.addSvgIcon('facebook-logo', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/facebook-logo.svg'));
        this.matIconRegistry.addSvgIcon('thumb', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/thumb.svg'));
    }

    private detectBrowser(): void {
        device.noConflict();
        this.detectBrowserService.detect();
    }

    private detectMobile(): void {
        const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        this.store.dispatch(new DataActions.SetMobile(mobile));
    }

    private subscribeOnBreakpoints(): void {
        this.breakpointObserver.observe([Breakpoints.Tablet, Breakpoints.Web, Breakpoints.Handset]).pipe(
            map((state: BreakpointState) => {
                if (state.breakpoints[Breakpoints.TabletLandscape] || state.breakpoints[Breakpoints.TabletPortrait]) {
                    return breakpoints.Tablet;
                }

                if (state.breakpoints[Breakpoints.HandsetLandscape] || state.breakpoints[Breakpoints.HandsetPortrait]) {
                    return breakpoints.Handset;
                }

                return breakpoints.Web;
            }),
            distinctUntilChanged()
        )
            .subscribe((breakpoint: Breakpoint) => {
                this.store.dispatch(new DataActions.SetBreakpoint(breakpoint));
                this.isSmall = breakpoint !== breakpoints.Web;
            });
    }
}
