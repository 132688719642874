import { APP_INITIALIZER, NgModule, Injector, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { Store } from '@ngxs/store';

import { environment } from '@environments/environment';

import { DataActions } from '@core/data';
import { EnsureModuleLoadedOnceGuard } from '@domain/base';

import { AuthGuard } from './guards';
import { AuthService, SocialService } from './services';
import { AuthHeadersInterceptor } from './interceptors';


export function getAccessToken(injector: Injector): () => Promise<any> {
    return () => {
        return new Promise<any>((resolve, reject) => {
            const router = injector.get(Router);
            const authService = injector.get(AuthService);
            const store = injector.get(Store);
            const url = `${location.pathname || ''}${location.search || ''}` || '/';

            if (authService.isLoggedIn()) {
                return store.dispatch(new DataActions.LoadCustomerDetails()).subscribe(state => {
                    if (location.hash && location.hash.includes('access_token')) {
                        router.navigateByUrl(url);
                    }
                    resolve(true);
                });
            }

            const oauthResult = authService.parseAccessToken();
            if (oauthResult) {
                sessionStorage.setItem(environment.userStorageKey, JSON.stringify(oauthResult));
                return store.dispatch(new DataActions.LoadCustomerDetails()).subscribe(state => {
                    router.navigateByUrl(url);
                    resolve(true);
                });
            }

            authService.startAuthentication();
        });
    };
}

@NgModule({
    imports: [RouterModule],
})
export class AuthModule extends EnsureModuleLoadedOnceGuard {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                AuthGuard,
                AuthService,
                SocialService,
                { provide: APP_INITIALIZER, useFactory: getAccessToken, deps: [Injector], multi: true },
                { provide: HTTP_INTERCEPTORS, useClass: AuthHeadersInterceptor, multi: true },
            ],
        };
    }

    constructor(
        @Optional()
        @SkipSelf()
        parentModule: AuthModule,
    ) {
        super(parentModule);
    }
}
