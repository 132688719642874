import { distinctUntilChanged, map, pluck } from 'rxjs/operators';

import { Observable, BehaviorSubject, fromEvent, combineLatest, merge } from 'rxjs';

export enum BrowserWindowParams {
    Width = 'width',
    Height = 'height',
    OffsetX = 'scrollX',
    OffsetY = 'scrollY',
}

export class BrowserWindowService {
    private readonly params$: BehaviorSubject<{ [key: string]: number }> = new BehaviorSubject(this.calculate());
    private readonly observables: { [key: string]: Observable<number> };
    private readonly params: BrowserWindowParams[] = [
        BrowserWindowParams.Width,
        BrowserWindowParams.Height,
        BrowserWindowParams.OffsetX,
        BrowserWindowParams.OffsetY,
    ];

    constructor() {
        this.observables = this.generate();

        merge(fromEvent(window, 'resize'), fromEvent(window, 'scroll'))
            .pipe(map(() => this.calculate()))
            .subscribe(this.params$);
    }

    public get(params: BrowserWindowParams | BrowserWindowParams[]): Observable<number[]> {
        const observables = Array.isArray(params)
            ? params.map(param => this.observables[param])
            : [this.observables[params]];

        return combineLatest(...observables);
    }

    private generate() {
        return this.params.reduce(
            (result, param) => ({
                ...result,
                ...{ [param]: this.params$.pipe(pluck(param), distinctUntilChanged()) },
            }),
            {},
        );
    }

    private calculate(): { [key: string]: number } {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
            scrollX: window.scrollX,
            scrollY: window.scrollY,
        };
    }
}
