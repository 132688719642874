import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'page-empty',
    templateUrl: './page-empty.component.html',
    styleUrls: ['./page-empty.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageEmptyComponent {

    @Input() title: string;

    @Input() subTitle: string;
}
