export enum UploadStatus {
    added = 'added',
    queue = 'queue',
    uploading = 'uploading',
    complete = 'complete',
    error = 'error',
    cancelled = 'cancelled',
    paused = 'paused',
    retry = 'retry'
}
