import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HotkeyOptions, IHotkeyOptions } from './hotkey.options';
import { Hotkeys } from './hotkeys.service';
import { HotkeysCheatSheetComponent } from './hotkeys-cheat-sheet/hotkeys-cheat-sheet.component';
import { HotkeyPipe } from './hotkey.pipe';


@NgModule({
    declarations: [HotkeysCheatSheetComponent, HotkeyPipe],
    imports: [CommonModule],
    exports: [HotkeysCheatSheetComponent, HotkeyPipe]
})
export class HotkeyModule {

    static forRoot(options: IHotkeyOptions = { disableCheatSheet: true }): ModuleWithProviders<HotkeyModule> {
        return {
            ngModule: HotkeyModule,
            providers: [Hotkeys, { provide: HotkeyOptions, useValue: options }]
        };
    }
}
