import moment from 'moment';

export class Time {

    public static asString(time: number): string {
        if (!time) {
            return null;
        }

        let bufString = time.toString();
        if (bufString.length < 4) {
            const difference = 4 - time.toString().length;

            for (let index = 0; index < difference; index++) {
                bufString = '0' + bufString;
            }

            const buf = bufString.split('');
            buf.splice(2, 0, ':');

            return buf.join('');
        }

        return moment(time, 'HHmm').format('HH:mm');
    }

    public static asNumber(time: string): number {
        if (!time) {
            return null;
        }

        return Number(moment(time, 'HH:mm').format('HHmm'));
    }

    public static getTimeAsNumber(date: Date): number {
        return +moment(`${date}`).format('HHmm');
    }

    public static dateAsNumber(date: Date): number {
        return +moment(`${date}`).format('YYYYMMDD');
    }

    public static dateAsString(date: number | string): string {
        return date ? moment(date, 'YYYYMMDD').format() : null;
    }

    public static convert(duration: number, from: 'millisecond' | 'second', to: 'millisecond' | 'second'): number {
        if (!duration) {
            return duration;
        }

        switch (true) {
            case from === 'millisecond' && to === 'millisecond': return duration;
            case from === 'second' && to === 'second': return duration;
            case from === 'millisecond' && to === 'second': return duration / 1000;
            case from === 'second' && to === 'millisecond': return duration * 1000;
        }
        return duration;
    }

    public static todayDate(): number {
        return +moment().format('YYYYMMDD');
    }

}
