export const enum KeyCode {
    Down = 'Down',
    ArrowDown = 'ArrowDown',
    Up = 'Up',
    ArrowUp = 'ArrowUp',
    Left = 'Left',
    ArrowLeft = 'ArrowLeft',
    Right = 'Right',
    ArrowRight = 'ArrowRight',
}

export class Keyboard {

    public static firedOnInput(event: KeyboardEvent): boolean {
        return ['INPUT', 'TEXTAREA'].includes(event.target['tagName']);
    }

    public static isArrowsCode(event: KeyboardEvent): boolean {
        const code = event.code;
        return this.ArrowLeft(code) || this.ArrowRight(code) || this.ArrowUp(code) || this.ArrowDown(code);
    }

    public static ArrowLeft(code: string): boolean {
        return code === KeyCode.ArrowLeft || code === KeyCode.Left;
    }

    public static ArrowRight(code: string): boolean {
        return code === KeyCode.ArrowRight || code === KeyCode.Right;
    }

    public static ArrowDown(code: string): boolean {
        return code === KeyCode.Down || code === KeyCode.ArrowDown;
    }

    public static ArrowUp(code: string): boolean {
        return code === KeyCode.Up || code === KeyCode.ArrowUp;
    }
}
