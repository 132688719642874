import { ColumnConfig } from './column.config';
import { Breakpoint, breakpoints } from './breakpoints.config';

export const DefaultWapsColumnsConfig = (breakpoint: Breakpoint) => ({

    [breakpoints.Web]: [
        new ColumnConfig({ name: 'name', width: 160, visible: true }),
        new ColumnConfig({ name: 'locationName', width: 120, visible: true }),
        new ColumnConfig({ name: 'group', width: 60, visible: true }),
        new ColumnConfig({ name: 'mac', width: 60, visible: true }),
        new ColumnConfig({ name: 'type', width: 60, visible: true }),
        new ColumnConfig({ name: 'enabled', width: 60, visible: true }),
    ],

    [breakpoints.Tablet]: [
        new ColumnConfig({ name: 'name', width: 160, visible: true }),
        new ColumnConfig({ name: 'locationName', width: 120, visible: true }),
        new ColumnConfig({ name: 'group', width: 60, visible: true }),
        new ColumnConfig({ name: 'mac', width: 60, visible: false }),
        new ColumnConfig({ name: 'type', width: 60, visible: false }),
        new ColumnConfig({ name: 'enabled', width: 60, visible: true }),
    ],

    [breakpoints.Handset]: [
        new ColumnConfig({ name: 'name', width: 160, visible: true }),
        new ColumnConfig({ name: 'locationName', width: 120, visible: false }),
        new ColumnConfig({ name: 'group', width: 60, visible: false }),
        new ColumnConfig({ name: 'mac', width: 60, visible: false }),
        new ColumnConfig({ name: 'type', width: 60, visible: false }),
        new ColumnConfig({ name: 'enabled', width: 60, visible: true }),
    ]

})[breakpoint];
