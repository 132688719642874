export enum UploaderStates {
    Empty,
    Uploaded,
    Saved,
}

export const ImageExtensionsConst = {
    png: 'png',
    'svg+xml': 'svg',
};
