import { Injectable, Inject } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { HotkeysCheatSheetComponent } from './hotkeys-cheat-sheet/hotkeys-cheat-sheet.component';
import { HotkeyOptions, IHotkeyOptions } from './hotkey.options';
import { Hotkey } from './hotkey.model';
import { Keyboard } from '@domain/utils';

interface Options {
    element: any;
    description: string;
    keys: string[];
}


@Injectable()
export class Hotkeys {

    public hotkeys = new Map();

    private defaults: Partial<Options> = { element: this.document };

    constructor(private eventManager: EventManager,
        private dialog: MatDialog,
        @Inject(HotkeyOptions) private options: IHotkeyOptions,
        @Inject(DOCUMENT) private document: Document) {
        this.initCheatSheet();
    }

    public add(key: string, description: string, element: any = null): Observable<any> {
        const hotkey = new Hotkey(key, description);

        this.hotkeys.set(hotkey.event, hotkey);

        return new Observable(observer => {

            const handler = (e: KeyboardEvent) => {
                if (Keyboard.firedOnInput(e)) {
                    return;
                }

                e.preventDefault();
                observer.next(e);
            };

            const dispose = this.eventManager.addEventListener(element || this.defaults.element, hotkey.event, handler);

            return () => {
                dispose();
                this.hotkeys.delete(hotkey.event);
            };
        });
    }

    public onOpenHelp() {
        this.dialog.open(HotkeysCheatSheetComponent, { width: '500px', data: this.hotkeys });
    }

    private initCheatSheet(): void {
        if (!this.options.disableCheatSheet) {
            this.add('shift.?', this.options.cheatSheetDescription || 'Show / hide this help menu').subscribe(() => {
                this.onOpenHelp();
            });
        }
    }
}
