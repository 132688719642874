<mat-menu class="filter-menu" #filterMenu="matMenu">
    <mat-selection-list (selectionChange)="onSelectionChange($event.options[0].value)">
        <ng-container *ngFor="let item of filterItems; trackBy: trackByFn">
            <mat-list-option *ngIf="item.value; else divider"
                [checkboxPosition]="'before'"
                [selected]="selectedItems.includes(item.value)"
                [value]="item"
                (click)="$event.stopPropagation()">
                {{ item.text | translate }}
            </mat-list-option>
            <ng-template #divider>
                <mat-divider></mat-divider>
            </ng-template>
        </ng-container>
    </mat-selection-list>
</mat-menu>

<button type="button" tabindex="-1" mat-icon-button [matMenuTriggerFor]="filterMenu" class="filter-btn">
    <mat-icon>filter_list</mat-icon>
</button>

<mat-chip-listbox>
    <mat-chip-option *ngFor="let item of selectedFilterItems; trackBy: trackByFn">
        {{ item.text | translate }} <mat-icon matChipRemove (click)="onRemove(item.value)">cancel</mat-icon>
    </mat-chip-option>
</mat-chip-listbox>
