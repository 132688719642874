import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { CommonToolsModule } from '@modules/common-tools';

import { MenuItemDefDirective } from './page-toolbar/menu-item-def.directive';
import { PageActionsComponent } from './page-actions/page-actions.component';
import { PageBreadcrumbsComponent } from './page-breadcrumbs/page-breadcrumbs.component';
import { PageBuilderMaterialModule } from './page-builder-material.module';
import { PageContentComponent } from './page-content/page-content.component';
import { PageEmptyComponent } from './page-empty/page-empty.component';
import { PageFilterTriggerComponent } from './page-filter-trigger/page-filter-trigger.component';
import { PageGroupsComponent } from './page-groups/page-groups.component';
import { PageSearchComponent } from './page-search/page-search.component';
import { PageSelectionFilterComponent } from './page-selection-filter/page-selection-filter.component';
import { PageSelectionGroupsComponent } from './page-selection-groups/page-selection-groups.component';
import { PageToolbarComponent } from './page-toolbar/page-toolbar.component';
import { ToolbarItemDefDirective } from './page-toolbar/toolbar-item-def.directive';


const components = [
    MenuItemDefDirective,
    PageActionsComponent,
    PageBreadcrumbsComponent,
    PageContentComponent,
    PageEmptyComponent,
    PageFilterTriggerComponent,
    PageGroupsComponent,
    PageSearchComponent,
    PageSelectionFilterComponent,
    PageSelectionGroupsComponent,
    PageToolbarComponent,
    ToolbarItemDefDirective
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CommonToolsModule,
        ReactiveFormsModule,
        PageBuilderMaterialModule,
        TranslateModule.forChild(),
    ],
    declarations: [
        ...components,
    ],
    exports: [
        ...components,
    ]
})
export class PageBuilderModule { }
