export const Campaign = {
    transitions: [
        { value: 'none', viewValue: 'None', selected: true },
        { value: 'fade', viewValue: 'Fade' },
        { value: 'zoom', viewValue: 'Zoom' },
        { value: 'bounce', viewValue: 'Bounce' },
        { value: 'slide', viewValue: 'Slide' },
        { value: 'rotate', viewValue: 'Rotate' },
    ],
    playlistDefaultSettings: {
        color: '#000000',
        duration: 5000,
        transition: 'fade',
    },
    customColor: '#000000',
};
