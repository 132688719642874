import { BaseEntry } from '../base/base-entry.model';
import { DataSort } from './data-sort.model';
import { DataSlice } from './data-slice.model';
import { DataFilter } from './data-filter.model';

export class DataQuery extends BaseEntry<DataQuery> {
    public include: string[];
    public filters: DataFilter[];
    public anyFilter: boolean;
    public sort: DataSort;
    public slice: DataSlice;
}
