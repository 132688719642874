import { merge } from 'lodash';
import { environment as baseEnviroment } from './environment.base';

export const environment = merge({}, baseEnviroment, {
    production: false,
    hmr: false,
    auth: {
        redirect_uri: 'https://cloud.use2go.dev//auth-callback',
        post_logout_redirect_uri: 'https://cloud..use2go.dev/',
    },
});
