import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export class IconGenerator {

    constructor(public iconRegistry: MatIconRegistry, public sanitizer: DomSanitizer) { }

    public getIcon(name: string) {
        this.iconRegistry.addSvgIcon(
            name,
            this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${name}.svg`),
        );
    }

    public getIcons(names: string[]) {
        names.forEach(name => this.getIcon(name));
    }
}
