interface Point {
    x: number;
    y: number;
}

export function getRectanglePoints(point: Point, width: number, height: number, angle: number): Point[] {
    const rad = toRadians(angle);
    return [
        { x: point.x, y: point.y },
        { x: point.x + width * Math.cos(rad), y: point.y + width * Math.sin(rad) },
        { x: point.x + width * Math.cos(rad) - height * Math.sin(rad), y: point.y + width * Math.sin(rad) + height * Math.cos(rad) },
        { x: point.x - height * Math.sin(rad), y: point.y + height * Math.cos(rad) }
    ];
}

export function toRadians(deg: number): number {
    return deg * (Math.PI / 180);
}
