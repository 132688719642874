import { BaseEntry } from '../base/base-entry.model';
import { DataQuery } from '../query/data-query.model';
import { DataSlice } from '../query/data-slice.model';
import { DataFilter } from '../query/data-filter.model';
import { DataSort } from '../query/data-sort.model';

export class ResourcesQueryRequest extends BaseEntry<ResourcesQueryRequest> {
    public customerId: string;
    public folderId: string;
    public global: boolean;
    public mode: string;
    public query: DataQuery;
}


export class ResourcesQuery extends BaseEntry<ResourcesQuery> {

    public folderId: string;
    public skip: number;
    public limit: number;
    public field: string;
    public desc: boolean;
    public search: string;
    public tags: string[];
    public includeSubtypes: string[];
    public includeTypes: string[];
    public excludeTypes: string[];
    public excludeSubtypes: string[];
    public global: boolean;
    public onlyType: string;
    public onlySubtype: string;

    public toRequest(): ResourcesQueryRequest {

        const query = new DataQuery({
            slice: new DataSlice({ skip: this.skip, limit: this.limit }),
            filters: [],
            include: [],
        });

        if (this.field) {
            query.sort = new DataSort({ field: this.field, desc: this.desc });
        }

        if (this.search) {
            query.filters.push(new DataFilter({ field: 'name', filter: { type: 'string', contains: this.search } }));
        }

        if (this.tags?.length) {
            query.filters.push(new DataFilter({ field: 'tags', filter: { type: 'array', all: this.tags } }));
        }

        if (this.excludeTypes?.length) {
            query.filters.push(
                new DataFilter({
                    filter: {
                        type: 'logic',
                        not: {
                            field: 'type',
                            filter: { type: 'string', in: this.excludeTypes },
                        },
                    },
                })
            );
        }

        if (this.excludeSubtypes?.length) {
            query.filters.push(
                new DataFilter({
                    filter: {
                        type: 'logic',
                        not: {
                            field: 'subtype',
                            filter: { type: 'string', in: this.excludeSubtypes },
                        },
                    },
                }),
            );
        }

        if (this.includeSubtypes?.length) {
            query.filters.push(new DataFilter({ field: 'subtype', filter: { type: 'string', in: this.includeSubtypes } }));
        } else if (this.includeTypes?.length) {
            query.filters.push(new DataFilter({ field: 'type', filter: { type: 'string', in: this.includeTypes } }));
        }

        if (this.onlyType) {
            query.filters.push(new DataFilter({ field: 'type', filter: { type: 'string', is: this.onlyType } }));
        }

        if (this.onlySubtype) {
            query.filters.push(new DataFilter({ field: 'subtype', filter: { type: 'string', is: this.onlySubtype } }));
        }

        return new ResourcesQueryRequest({ query, folderId: this.folderId, global: this.global });
    }
}
