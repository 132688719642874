import { BaseEntry } from '../base/base-entry.model';
import { TargetRef } from './target-ref.model';


export class PermissionEntry extends BaseEntry<PermissionEntry> {
    public read: boolean;
    public recursive: boolean;
    public share: boolean;
    public write: boolean;
    public target: TargetRef;
}
