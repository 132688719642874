import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';


const initParams: any = {
    appId: environment.facebookAppId,
    xfbml: true,
    version: 'v3.3',
};

@Injectable()
export class SocialService {
    public user: any = null;
    public groups: any[] = [];
    public token: string = null;

    // constructor(private facebookService: FacebookService) {
    //     // this.facebookService.init(initParams);
    // }

    public login() {
        return new Promise(async (resolve) => {
            try {
                // const { authResponse } = await this.facebookService.login({ scope: 'groups_access_member_info' });
                const authResponse = null;
                if (authResponse) {
                    const user = await this.get('/me');
                    this.token = authResponse.accessToken;
                    this.user = user;
                    resolve(user);
                } else {
                    this.user = null;
                    resolve(null);
                }
            } catch (err) {
                this.user = null;
                resolve(null);
            }
        });
    }

    public logout(): void {
        // this.facebookService.logout().then(() => {
        //     this.user = null;
        //     this.token = null;
        //     this.groups = null;
        // });
    }

    public getGroups() {
        this.get('/me/groups?fields=administrator,name,icon').then(response => {
            this.groups = response.data.filter(item => !!item.administrator);
        });
    }

    protected get(path: string) {
        return null;// this.facebookService.api(path);
    }
}
