import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { ObserverComponent } from '@domain/base';

import { ShowNotification } from '../../store';
import { NotificationsService } from '@core/notifications/services';


@Component({
    selector: 'system-notifier',
    templateUrl: './system-notifier.component.html',
    styleUrls: ['./system-notifier.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemNotifierComponent extends ObserverComponent implements OnInit {

    constructor(private actions$: Actions, private notificationsService: NotificationsService) {
        super();
    }

    public ngOnInit(): void {
        this.subscriptions.push(this.subscribeOnShowNotification());
    }

    private subscribeOnShowNotification(): Subscription {
        return this.actions$.pipe(ofActionSuccessful(ShowNotification))
            .subscribe(({ payload }: ShowNotification) => { this.notificationsService.show(payload); });
    }
}
