import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, HostBinding } from '@angular/core';

import { ColumnConfig } from '@core/data/configs';


@Component({
    selector: 'page-filter-trigger',
    templateUrl: './page-filter-trigger.component.html',
    styleUrls: ['./page-filter-trigger.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFilterTriggerComponent {

    @HostBinding('class.page-filter-applied')
    @Input() filterApplied: boolean;

    @Input() columns: ColumnConfig[];

    @Input() columnsMap: Record<string, string>;

    @Output() apply = new EventEmitter<ColumnConfig[]>();

    @Output() setDefault = new EventEmitter<void>();

    public enabled: boolean = false;

    public onToggleOptions() {
        this.enabled = !this.enabled;
    }

    public onApply(columns: ColumnConfig[]): void {
        this.apply.emit(columns);
        this.onClose();
    }

    public onClose($event?: any): void {
        if ($event?.target?.classList?.contains('cdk-overlay-backdrop')) {
            return;
        }

        this.enabled = false;
    }

    public onSetDefault(): void {
        this.setDefault.emit();
        this.onClose();
    }
}
