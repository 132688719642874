import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { NotificationsService, NotificationMessage, ConfirmationMessage } from '@core/notifications';


@Injectable()
export class SystemNotifierService {
    constructor(private notificationsService: NotificationsService) { }

    public showMessage(message: NotificationMessage, delay?: number): void {
        this.notificationsService.show(message);
    }

    public error(text: string, title?: string): void {
        return this.showMessage(NotificationMessage.error(title, text));
    }

    public warn(text: string, title?: string): void {
        return this.showMessage(NotificationMessage.warning(title, text));
    }

    public info(text: string, title?: string): void {
        return this.showMessage(NotificationMessage.info(title, text));
    }

    public success(text: string, title?: string): void {
        return this.showMessage(NotificationMessage.success(title, text));
    }

    public confirm(params: Partial<ConfirmationMessage>): Observable<boolean> {
        return this.notificationsService.confirm(new ConfirmationMessage(params));
    }
}
