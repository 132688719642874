import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Customer } from '@domain/customers';


export interface CustomerForm {
    name: FormControl<string>;
    branch: FormControl<string>;
    country: FormControl<string>;
    address: FormControl<string>;
    city: FormControl<string>;
    zip: FormControl<string>;
    vat: FormControl<string>;
    email: FormControl<string>;
    phone: FormControl<string>;
}

export class CustomersCreator {

    constructor(private _fb: FormBuilder) { }

    public customerFormGroup(customer: Customer): FormGroup<CustomerForm> {
        return this._fb.group<CustomerForm>({
            name: this._fb.control<string>(customer.name, [Validators.required]),
            branch: this._fb.control<string>(customer.branch),
            country: this._fb.control<string>(customer.country),
            address: this._fb.control<string>(customer.address),
            city: this._fb.control<string>(customer.city),
            zip: this._fb.control<string>(customer.zip),
            vat: this._fb.control<string>(customer.vat),
            email: this._fb.control<string>(customer.email, [Validators.required]),
            phone: this._fb.control<string>(customer.phone),
        });
    }
}
