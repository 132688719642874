import { BaseEntry } from '../base/base-entry.model';
import { DataSort } from '../query/data-sort.model';
import { DataSlice } from '../query/data-slice.model';

export class CampaignsFindByReferenceRequest extends BaseEntry<CampaignsFindByReferenceRequest> {
    public customerId: string;
    public type: string;
    public referenceId: string;
    public refType: string;
    public refs: any;
    public sort: DataSort;
    public slice: DataSlice;
}
