import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';

import { FileExtensionService, FileTransformService } from '../../services';
import { ImageUploadCanvasComponent } from '../../components';
import { UploaderModes, UploaderStates, ImageFormats } from '../../enums';
import { PostFileModel, IUploaderConfig } from '../../models';

@Component({
    selector: 'image-upload',
    templateUrl: 'image-upload.component.html',
    styleUrls: ['image-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageUploadComponent),
            multi: true,
        },
    ],
})
export class ImageUploadComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() config: IUploaderConfig;

    @Input() width: number = 250;

    @Input() height: number = 250;

    @Input() provideEdit = true;

    @Input() uploaderMode = UploaderModes.Default;

    @Input() label: string;

    @Input() uploadState = UploaderStates.Empty;

    @Output() imageFormatChange = new EventEmitter();

    @Output() update = new EventEmitter<PostFileModel>();

    @Output() cancel = new EventEmitter<any>();

    @Output() save = new EventEmitter<any>();

    @HostBinding('style.width.px') hostWidth: number;

    fileInput: HTMLElement;
    @ViewChild('file')
    set file(value: ElementRef) {
        if (!value) {
            return;
        }

        this.fileInput = value.nativeElement;
    }

    @ViewChild('uploaderCanvas') private uploaderCanvas: ImageUploadCanvasComponent;

    uploaderStates = UploaderStates;

    uploaderModes = UploaderModes;

    zoom = 0;

    image = new PostFileModel();

    isSaving = false;

    onChange: (arg0: string) => void;

    onTouched: () => void;

    constructor(
        private changeRef: ChangeDetectorRef,
        private transformService: FileTransformService,
        private extensionService: FileExtensionService,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['config']) {
            const config = changes['config'].currentValue as IUploaderConfig;
            this.onChangeConfig(config);
        }
    }

    ngOnInit(): void {
        this.setContainerSize();
    }

    writeValue(imageId: string): void {
        this.image.imageId = imageId;
        this.image.path = imageId ? this.config.readUrl(imageId) : null;
        this.uploadState = imageId ? UploaderStates.Saved : UploaderStates.Empty;

        if (this.onChange) {
            this.onChange(this.image.imageId);
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    onFileChange({ target }: any): void {
        this.readFile(target);
    }

    onCancel(): void {
        this.onReset();
        this.cancel.emit();
    }

    onReset(): void {
        this.image.base64 = null;
        this.zoom = 0;
        this.writeValue(this.image.imageId);
    }

    onSave(): void {
        if (!this.uploaderCanvas.imageChanged()) {
            this.uploadState = UploaderStates.Saved;
            this.save.emit();
            return;
        }

        this.isSaving = true;
        if (this.provideEdit) {
            this.image.base64 = this.uploaderCanvas.getCroppedImage();
        }

        const rawExtension = this.extensionService.getExtensionByBase64(this.image.base64);
        this.image.fileExtension = ImageFormats[rawExtension] || rawExtension;

        this.imageFormatChange.emit(this.image.fileExtension);

        if (!this.image.base64 || !this.image.fileExtension) {
            return;
        }

        this.uploadState = UploaderStates.Saved;

        if (!this.canSend()) {
            return;
        }

        this.sendFile().then(imageId$ => {
            imageId$.subscribe(
                (imageId: string) => {
                    this.writeValue(imageId);
                    this.isSaving = false;
                    this.save.emit();
                },
                (error: any) => {
                    this.writeValue(null);
                    this.isSaving = false;
                },
            );
        });
    }

    onDelete(): void {
        this.image.base64 = null;
        this.zoom = 0;
        this.writeValue(null);
    }

    onEdit(): void {
        this.zoom = 0;
        this.uploadState = UploaderStates.Uploaded;
        this.uploaderCanvas.loadImage();
    }

    onDecreaseZoom(): void {
        if (this.zoom === 0) {
            return;
        }

        this.zoom--;
    }

    onIncreaseZoom(): void {
        if (this.zoom >= 50) {
            return;
        }

        this.zoom++;
    }

    onSliderChange(event: MatSliderChange): void {
        if (this.zoom === event.value) {
            return;
        }

        this.zoom = event.value;
    }

    private canSend(): boolean {
        return !!(this.config.postAsBase64 || this.config.postAsFile);
    }

    private async sendFile(): Promise<Observable<string>> {
        if (this.config.postAsFile) {
            const file = await this.transformService.toFile(this.image.base64, this.image.name);
            return this.config.postAsFile(file);
        }

        if (this.config.postAsBase64) {
            return this.config.postAsBase64(this.image.base64);
        }
    }

    private onChangeConfig(config: IUploaderConfig): void {
        this.config = { ...config };
    }

    private readFile({ files }: any): void {
        if (!files || !files.length) {
            return;
        }

        const file: File = files[0];
        const isImageFile = file.type.match('image.*');

        if (!isImageFile) {
            return;
        }

        this.image.name = file.name;
        this.image.type = file.type;

        const reader: FileReader = new FileReader();

        reader.onloadend = () => {
            if (typeof reader.result !== 'string') {
                return;
            }

            this.image.base64 = reader.result;
            this.uploadState = UploaderStates.Uploaded;
            this.uploaderCanvas.cleanCanvas();

            this.changeRef.detectChanges();

            this.uploaderCanvas.loadImage();
        };

        reader.readAsDataURL(file);
    }

    private setContainerSize(): void {
        this.hostWidth = this.width + 100;
    }
}
