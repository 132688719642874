import { BaseEntry } from '../base/base-entry.model';

export type PlayerCommand =
    | 'prev'
    | 'play'
    | 'pause'
    | 'stop'
    | 'next'
    | 'vol'
    | 'cache'
    | 'reboot'
    | 'dump'
    | 'pwr'
    | 'id';

export class PlayerCommandRequest extends BaseEntry<PlayerCommandRequest> {
    public id: string;
    public customerId: string;
    public command: PlayerCommand;
    public args: any;
}

export class PlayersControlMultiRequest extends BaseEntry<PlayersControlMultiRequest> {
    public ids: string[];
    public customerId: string;
    public command: PlayerCommand;
    public args: any;
}
