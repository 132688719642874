import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';


import { TranslateModule } from '@ngx-translate/core';

import { EnsureModuleLoadedOnceGuard } from '@domain/base';

import { ConfirmationComponent, NotificationComponent, SystemNotifierComponent } from './components';
import { NotificationsService } from './services';


@NgModule({
    declarations: [
        ConfirmationComponent,
        NotificationComponent,
        SystemNotifierComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
    ],
    exports: [SystemNotifierComponent]
})
export class NotificationsModule extends EnsureModuleLoadedOnceGuard {

    static forRoot(): ModuleWithProviders<NotificationsModule> {
        return {
            ngModule: NotificationsModule,
            providers: [
                NotificationsService
            ],
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: NotificationsModule) {
        super(parentModule);
    }
}
