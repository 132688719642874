import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, timer } from 'rxjs';

import { AuthService } from '@core/auth';

import { ApiService } from './api.service';
import { expand, switchMap } from 'rxjs/operators';


@Injectable()
export class ScreenCapturingService extends ApiService {

    constructor(
        public http: HttpClient,
        protected authService: AuthService
    ) {
        super(http, authService);
    }

    public playersCapture(id: string): Observable<any> {
        const customerId = this.authService.getCustomerId();
        return this.post('players_capture', { id, customerId });
    }

    public capture(playerId: string): Observable<{ pullUrl: string }> {
        return this.playersCapture(playerId)
            .pipe(
                expand(res => {
                    return timer((res.captureSeconds - 3) * 1000).pipe(
                        switchMap(() => this.playersCapture(playerId))
                    );
                })
            );
    }
}
