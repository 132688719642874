import { Input, ElementRef, Directive } from '@angular/core';

@Directive({
    selector: '[menuItemDef]'
})
export class MenuItemDefDirective {

    @Input('menuItemDef') key: string;

    @Input() visible: boolean;

    public constructor(public el: ElementRef) { }
}
