import { CommonModule } from '@angular/common';
import { NgModule, Provider, ModuleWithProviders } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { TranslateModule } from '@ngx-translate/core';

import { CommonPipesModule } from '@modules/common-pipes/common-pipes.module';

import { UploadFilesManagerComponent } from './upload-files-manager/upload-files-manager.component';
import { UploadFileStateComponent } from './upload-file-state/upload-file-state.component';
import { UploadService } from './upload.service';
import { UploadProgressBarComponent } from './upload-progress-bar/upload-progress-bar.component';
import { UploadWatcher } from './upload.watcher';


export interface FileUploaderModuleConfig {
    options?: Provider;
}

@NgModule({
    declarations: [
        UploadFilesManagerComponent,
        UploadFileStateComponent,
        UploadProgressBarComponent
    ],
    imports: [
        CommonModule,
        MatExpansionModule,
        MatProgressBarModule,
        MatListModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        CommonPipesModule,
        TranslateModule.forChild(),
    ]
})
export class FileUploaderModule {

    /**
     * Use this method in your root module to provide the UploadService
     * @param config
     */
    static forRoot(config?: FileUploaderModuleConfig): ModuleWithProviders<FileUploaderModule> {
        return {
            ngModule: FileUploaderModule,
            providers: [
                config && config.options || [],
                UploadWatcher,
                UploadService,
            ]
        };
    }

    /**
     * Use this method in your other (non root) modules to import the components, directive, pipes
     */
    static forChild(): ModuleWithProviders<FileUploaderModule> {
        return { ngModule: FileUploaderModule };
    }

    constructor(watcher: UploadWatcher) {
    }
}
