<div class="progress-data">
    <div class="row">
        <div class="file-name ellipsis">{{ fileState.name }}</div>
        <div class="file-progress">{{ fileState.progress }}%</div>
    </div>
    <mat-progress-bar color="accent" mode="determinate" [value]="fileState.progress"></mat-progress-bar>
    <div class="progress-status">{{ fileState.uploaded | bytes : 2 }} of {{ fileState.size | bytes : 2 }}</div>
</div>

<div class="upload-actions">
    <button mat-icon-button color="accent" matTooltip="Pause" aria-label="Pause uploading file" (click)="pause.emit()"
        *ngIf="fileState.status === UploadStatus.uploading">
        <mat-icon>pause_circle_outline</mat-icon>
    </button>
    <button mat-icon-button color="accent" matTooltip="Play" aria-label="Play uploading file" (click)="play.emit()"
        *ngIf="fileState.status === UploadStatus.paused || fileState.status === UploadStatus.queue">
        <mat-icon>play_circle_outline</mat-icon>
    </button>

    <button mat-icon-button color="accent" matTooltip="Cancel" aria-label="Cancel uploading file"
        (click)="cancel.emit()">
        <mat-icon>remove_circle_outline</mat-icon>
    </button>
</div>
