import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { FileLoaderService, FileExtensionService, FileTransformService } from './services';
import { ImageUploadComponent, ImageUploadCanvasComponent } from './components';

@NgModule({
    declarations: [ImageUploadComponent, ImageUploadCanvasComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatSliderModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        TranslateModule,
    ],
    exports: [ImageUploadComponent],
    providers: [
        FileLoaderService,
        FileExtensionService,
        FileTransformService,
    ],
})
export class ImageUploaderModule { }
