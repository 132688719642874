import { BaseEntry } from '../base/base-entry.model';
import { RolePermissions } from './role-permissions.model';

export const ADMIN_ROLE_NAME = 'ADMIN';

export class Role extends BaseEntry<Role> {
    public id: string;
    public customerId: string;
    public name: string;
    public notes: string;
    public version: number;
    public perm: RolePermissions;

    public static Empty(): Role {
        return new Role({
            id: null,
            name: '',
            notes: '',
            perm: {
                profiles: { read: true, write: false, targets: [], entries: null },
                vouchers: { read: true, write: false, targets: [], entries: null },
                locations: { read: true, write: false, targets: [], entries: null },
                resources: { read: true, write: false, targets: [], entries: null },
                campaigns: { read: true, write: false, targets: [], entries: null },
                sensors: { read: true, write: false, targets: [], entries: null },
                players: { read: true, write: false, targets: [], entries: null },
                waps: { read: true, write: false, targets: [], entries: null },
                children: { read: true, write: false, targets: [], entries: null },
            },
        });
    }
}
