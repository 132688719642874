<div class="uploader-container" [style.width.px]="width">
    <label *ngIf="label" class="label">{{ label }}</label>

    <div [style.width.px]="width" [style.height.px]="height" class="uploader-image-area">

        <ng-container *ngIf="uploadState === uploaderStates.Empty">
            <div class="uploader-drop-area" [class.no-border]="uploaderMode === uploaderModes.Minimized">
                <label for="file" [class.sm]="width <= 200" *ngIf="uploaderMode === uploaderModes.Default">
                    <mat-icon mat-list-icon>image</mat-icon>
                    <p [innerHtml]="'IMAGE_UPLOADER.TITLE' | translate"></p>
                </label>
                <mat-icon *ngIf="uploaderMode === uploaderModes.Minimized"
                    [style.width.px]="width"
                    [style.height.px]="height"
                    [style.fontSize.px]="width">
                    account_circle
                </mat-icon>
                <input #file type="file" accept="image/*" id="file" (change)="onFileChange($event)" />
            </div>
        </ng-container>

        <image-upload-canvas class="uploader-uploaded-image" #uploaderCanvas
            [class.with-border]="uploaderMode === uploaderModes.Minimized"
            [hidden]="uploadState !== uploaderStates.Uploaded"
            [path]="image.path"
            [base64]="image.base64"
            [width]="width"
            [height]="height"
            [zoom]="zoom"
            (decrease)="onDecreaseZoom()"
            (increase)="onIncreaseZoom()">
        </image-upload-canvas>

        <div class="uploader-cropped-image" [hidden]="uploadState !== uploaderStates.Saved">

            <div class="bg-container" [style.backgroundImage]="'url(' + image.path + ')'" *ngIf="image.path"></div>

            <div class="uploader-menu-container" [class.hover]="menuTrigger.menuOpen">
                <button
                    class="menu-btn"
                    type="button"
                    #menuTrigger="matMenuTrigger"
                    mat-icon-button
                    [disabled]="isSaving"
                    [matMenuTriggerFor]="menu">
                        <mat-icon mat-list-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu" [overlapTrigger]="false" xPosition="before" class="uploader-menu">
                    <button mat-menu-item *ngIf="provideEdit" (click)="onEdit()"><mat-icon mat-list-icon>edit</mat-icon></button>
                    <button mat-menu-item (click)="onDelete()"><mat-icon mat-list-icon>delete</mat-icon></button>
                </mat-menu>
            </div>
        </div>

        <div class="spinner-container" *ngIf="isSaving">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div class="uploader-actions" *ngIf="uploadState === uploaderStates.Uploaded">
        <ng-container *ngIf="uploaderMode === uploaderModes.Default">
            <button class="save-btn" mat-icon-button (click)="onSave()">
                <mat-icon mat-list-icon>save</mat-icon>
            </button>
            <button class="cancel-btn" mat-icon-button (click)="onCancel()">
                <mat-icon mat-list-icon>cancel</mat-icon>
            </button>
            <button mat-icon-button *ngIf="provideEdit" (click)="uploaderCanvas.rotateLeft()">
                <mat-icon mat-list-icon>rotate_left</mat-icon>
            </button>
            <button mat-icon-button *ngIf="provideEdit" (click)="uploaderCanvas.rotateRight()">
                <mat-icon mat-list-icon>rotate_right</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="uploaderMode === uploaderModes.Minimized">
            <button mat-icon-button (click)="onDelete()">
                <mat-icon class="grey" mat-list-icon>delete</mat-icon>
            </button>
        </ng-container>
    </div>
</div>

<ng-container *ngIf="provideEdit && uploadState === uploaderStates.Uploaded">
    <div class="uploader-slider" [style.width.px]="width + 60">
        <button mat-icon-button (click)="onDecreaseZoom()">
            <mat-icon class="grey" mat-list-icon>image</mat-icon>
        </button>
        <mat-slider
            min=0 
            max="50"
            step="1" #ngSlider><input matSliderThumb [value]="zoom" (input)="onSliderChange({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" (change)="onSliderChange({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" />
        </mat-slider>     
        <button mat-icon-button (click)="onIncreaseZoom()">
            <mat-icon mat-list-icon>image</mat-icon>
        </button>
    </div>
</ng-container>

<div class="uploader-actions-minimized" *ngIf="uploaderMode === uploaderModes.Minimized">
    <button class="cancel-btn" mat-button color="primary" (click)="onCancel()">Cancel</button>
    <button class="save-btn" mat-flat-button *ngIf="uploadState === uploaderStates.Uploaded" color="primary" (click)="onSave()">Save</button>
    <button class="upload" mat-flat-button *ngIf="uploadState === uploaderStates.Empty" color="primary" (click)="fileInput.click()">Upload Image</button>
</div>
