import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { forwardRef } from '@angular/core';

export function ControlValueProvider(type: any) {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => type),
        multi: true
    };
}

export function ControlValidatorsProvider(type: any) {
    return {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => type),
        multi: true
    };
}

export class BaseControlValueAccessor<T = any> implements ControlValueAccessor {

    public disabled: boolean = false;

    public value: T;

    public onChange: (value: T) => void;

    public onTouched: (_?: any) => void;

    public validate({ value }: any) {
        return null;
    }

    public writeValue(obj: T): void { this.value = obj; }

    public registerOnChange(fn: any): void { this.onChange = fn; }

    public registerOnTouched(fn: any): void { this.onTouched = fn; }

    public setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled; }
}
