import { BaseEntry } from '../base/base-entry.model';

export class PlayerStatus extends BaseEntry<PlayerStatus> {
    public build: string;
    public cpu: number;
    public diskTotal: number;
    public diskUsed: number;
    public ip: string;
    public lastStart: Date;
    public mac: string;
    public playerId: string;
    public ramTotal: string;
    public ramUsed: string;
    public system: string;
    public temperature: string;
    public wifiStrength: number;
}
