import { Selector } from './selector';


/**
 * Appends a target items which do not exist in the source list.
 * @param items
 * @param compareBy
 */
export function appendUnique<T>(items: T[], compareBy: Selector<T>) {

    return (existing: Readonly<T[]>): T[] => {

        if (!items || !items.length) {
            return existing as T[];
        }

        if (Array.isArray(existing)) {
            const existingIds = existing.map(compareBy);
            const uniq = items.filter(item => !existingIds.includes(compareBy(item)));

            return uniq.length ? existing.concat(uniq) : existing;
        }

        return items;
    };

}

/**
 * Set items or default array for the source list.
 * @param items 
 */
export function safeSet<T>(items: T[]) {

    return (existing: Readonly<T[]>): T[] => {
        return items || [];
    };

}

/**
 * Safe set or append items into the source list.  
 * @param items
 * @param compareBy
 * @param append Indicate that items should be appended into source list.
 */
export function setOrAppend<T>(items: T[], compareBy: Selector<T>, append: boolean = false) {

    return (existing: Readonly<T[]>): T[] => {
        return append ? appendUnique(items, compareBy)(existing) : safeSet(items)(existing);
    };

}
