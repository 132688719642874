import { DataQueryRequest } from '../query/data-query-request.model';
import { CampaignTargetEntry } from './campaign-target-entry.model';

export class CampaignsFindRequest extends DataQueryRequest {
    public disabledOnly: boolean;
    public enabledOnly: boolean;
    public offlineOnly: boolean;
    public onlineOnly: boolean;
    public fullPlaylist: boolean;
    public targets: CampaignTargetEntry[];
    public type: string;

    constructor(fields?: Partial<CampaignsFindRequest>) {
        super();
        if (fields) {
            Object.assign(this, fields);
        }
    }
}
