import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import 'devextreme/localization/globalize/number';
import 'devextreme/localization/globalize/date';
import 'devextreme/localization/globalize/currency';
import 'devextreme/localization/globalize/message';
import 'devextreme/localization/globalize/core';

// @ts-ignore
import deMessages from 'devextreme/localization/messages/de.json';
// @ts-ignore
import supplemental from 'devextreme-cldr-data/supplemental.json';
// @ts-ignore
import cldrDataDe from 'devextreme-cldr-data/de.json';
// @ts-ignore
import cldrDataEn from 'devextreme-cldr-data/en-GB.json';

import Globalize from 'globalize';

enum Locales {
    en = 'en-GB',
    de = 'de',
}

@Injectable()
export class GlobalizeService {
    constructor(private translateService: TranslateService) {}

    public initialize(): void {
        Globalize.load(cldrDataDe, cldrDataEn, supplemental);
        Globalize.loadMessages(deMessages);
        Globalize.locale(Locales[this.translateService.currentLang]);

        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            Globalize.locale(Locales[event.lang]);
        });
    }
}
