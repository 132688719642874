import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

type Group = { value: string, name: string };

@Component({
    selector: 'page-selection-groups',
    templateUrl: './page-selection-groups.component.html',
    styleUrls: ['./page-selection-groups.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSelectionGroupsComponent {

    @Input() title: string;

    @Input() groups: Group[] = [];

    @Input() selectedGroups: string[] = [];

    @Output() selectedGroupsChange = new EventEmitter<string[]>();

    public onSelectionChange(group: string): void {
        this.selectedGroups = this.selectedGroups.includes(group)
            ? this.selectedGroups.filter((s: string) => s !== group)
            : [...this.selectedGroups, group];

        this.selectedGroupsChange.emit(this.selectedGroups);
    }

    public onDeselectAll(): void {
        this.selectedGroups = [];
        this.selectedGroupsChange.emit(this.selectedGroups);
    }

    public trackByValue(index: number, item: { value: string, name: number }) {
        return item.value;
    }

}
